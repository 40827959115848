import {
	CombinedWorkType,
	isDerivedWorkType,
	isEditDerivableWorkType,
	isManifestationDerivableWorkType,
	isStandaloneWorkType,
	WorkType,
	WorkTypeObject,
} from '@warehouse/title-shared/core';

export function getWorkTypeObjectFromUuid(uuid: string): WorkTypeObject {
	const workType = getWorkType(uuid);
	return {
		workType,
		uuid,
		label: labelFromWorkType(workType),
	};
}

export function getWorkTypeObject(workType: WorkType): WorkTypeObject {
	return {
		workType,
		uuid: getWorkTypeUuid(workType),
		label: labelFromWorkType(workType),
	};
}

export function workTypeFromLabel(workTypeLabel: string): WorkType {
	switch (workTypeLabel) {
		case 'Movie':
			return WorkType.Movie;
		case 'Season':
			return WorkType.Season;
		case 'Series':
			return WorkType.Series;
		case 'Edit':
			return WorkType.Edit;
		case 'Compilation':
			return WorkType.Compilation;
		case 'Episode':
			return WorkType.Episode;
		case 'Supplemental':
			return WorkType.Supplemental;
		case 'Manifestation':
			return WorkType.Manifestation;
		default:
			throw new Error(`WorkType label ${workTypeLabel} not found`);
	}
}

export function labelFromWorkType(workType: WorkType): string {
	switch (workType) {
		case WorkType.Movie:
			return 'Movie';
		case WorkType.Season:
			return 'Season';
		case WorkType.Series:
			return 'Series';
		case WorkType.Edit:
			return 'Edit';
		case WorkType.Compilation:
			return 'Compilation';
		case WorkType.Episode:
			return 'Episode';
		case WorkType.Supplemental:
			return 'Supplemental';
		case WorkType.Manifestation:
			return 'Manifestation';
		default:
			throw new Error(`WorkType ${workType} not found`);
	}
}

export function backendWorkTypeToCombinedWorkType(
	backendWorkType: string,
	indexedParentAbstractionWorkType: string | undefined,
): CombinedWorkType {
	const workType = getWorkType(backendWorkType);

	if (isStandaloneWorkType(workType)) {
		return workType;
	}

	if (isDerivedWorkType(workType)) {
		if (!indexedParentAbstractionWorkType) throw new Error('Missing parentAbstractionWorkType');
		const parentAbstractionWorkType = getWorkType(indexedParentAbstractionWorkType);

		if (workType === WorkType.Manifestation) {
			if (!isManifestationDerivableWorkType(parentAbstractionWorkType))
				throw new Error(`Parent work type ${parentAbstractionWorkType} is not derivable for manifestation`);
			return { parentAbstractionWorkType, workType };
		}

		if (workType === WorkType.Edit) {
			if (!isEditDerivableWorkType(parentAbstractionWorkType))
				throw new Error(`Parent work type ${parentAbstractionWorkType} is not derivable for edit`);
			return { parentAbstractionWorkType, workType };
		}
	}

	throw new Error('Work type is not supported');
}

export function movieUuid() {
	return '3edd764e-9df1-4ed0-bfae-0d7bef5de944';
}

export function seriesUuid() {
	return '787adc57-766d-42b4-9c98-b6fec492cfee';
}

export function seasonUuid() {
	return '36ffba2d-eae2-4d90-b315-5b9a96719d06';
}

export function editUuid() {
	return '5feee519-201c-4bc0-802d-3e7f5abfa5e3';
}

export function compilationUuid() {
	return 'cbe96f41-4aa1-449f-9bc7-102fa244e2b6';
}

export function episodeUuid() {
	return 'eaca7a2b-6fdd-418f-8906-71245198d898';
}

export function supplementalUuid() {
	return '232b10be-ee8d-4325-8cf4-bd688e1e0deb';
}

export function manifestationUuid() {
	return 'f643a5ef-1bfd-436c-b5a7-339329d8e85b';
}

export function getWorkType(uuid: string): WorkType {
	switch (uuid) {
		case movieUuid():
			return WorkType.Movie;
		case seasonUuid():
			return WorkType.Season;
		case seriesUuid():
			return WorkType.Series;
		case editUuid():
			return WorkType.Edit;
		case compilationUuid():
			return WorkType.Compilation;
		case episodeUuid():
			return WorkType.Episode;
		case supplementalUuid():
			return WorkType.Supplemental;
		case manifestationUuid():
			return WorkType.Manifestation;
		default:
			throw new Error(`WorkType uuid ${uuid} not found`);
	}
}

export function getWorkTypeUuid(workType: WorkType): string {
	switch (workType) {
		case WorkType.Movie:
			return movieUuid();
		case WorkType.Season:
			return seasonUuid();
		case WorkType.Series:
			return seriesUuid();
		case WorkType.Edit:
			return editUuid();
		case WorkType.Compilation:
			return compilationUuid();
		case WorkType.Episode:
			return episodeUuid();
		case WorkType.Supplemental:
			return supplementalUuid();
		case WorkType.Manifestation:
			return manifestationUuid();
		default:
			throw new Error(`WorkType ${workType} not found`);
	}
}

export function allWorkTypes(): WorkType[] {
	return [
		WorkType.Movie,
		WorkType.Season,
		WorkType.Series,
		WorkType.Compilation,
		WorkType.Episode,
		WorkType.Supplemental,
		WorkType.Edit,
		// WorkType.Manifestation intentionally omitted as it is not currently supported
	];
}

export function getPluralizedLabelWorkType(workTypeLabel: string) {
	if (workTypeLabel.toLowerCase() === 'series') return workTypeLabel;
	return `${workTypeLabel}s`;
}

export function getBackgroundColor(workTypeName: string | undefined) {
	if (workTypeName === 'Edit') return 'blue';
	if (workTypeName === 'Manifestation') return 'red';
	return 'orange';
}
