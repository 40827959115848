import React, { useMemo } from 'react';
import { BooleanField, ReadOnlyTypography } from '@warehouse/shared/ui';
import { isInherited, OptionalNullInherited } from '@warehouse/title/core';
import useTitleAutoSave, {
	optionalNullInheritedToOptionalInherited,
} from '../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';
import TitleEditableField from '../../../../src/components/titles/TitleEditableField';
import { useUpdateValue } from '../../domain/useUpdateValue';

function getVariant(value: OptionalNullInherited<boolean> | undefined): 'default' | 'prefilled' {
	if (!value) return 'default';

	return isInherited<boolean>({ inheritedObject: optionalNullInheritedToOptionalInherited(value) }).isInherited
		? 'prefilled'
		: 'default';
}

export function TitleBooleanField({ path, label }: { label: string; path: string }) {
	const { readOnlyValue, setValue, value, commit } = useTitleAutoSave<OptionalNullInherited<boolean>>({
		path: path.split('.'),
		label,
	});

	const readOnlyDisplayValue = useMemo(() => {
		if (readOnlyValue?.displayValue === true) return 'True';
		if (readOnlyValue?.displayValue === false) return 'False';
		return '';
	}, [readOnlyValue?.displayValue]);

	const { updateValue } = useUpdateValue<boolean, boolean | null>({ value });

	return (
		<TitleEditableField
			label={label}
			path={path}
			direction="column"
			readComponent={
				<ReadOnlyTypography variant={getVariant(value)} style={{ height: 36 }}>
					{readOnlyDisplayValue}
				</ReadOnlyTypography>
			}
			editComponent={
				<BooleanField
					value={value?.displayValue ?? null}
					onChange={(v) => setValue(updateValue(v))}
					onBlur={commit}
					variant={getVariant(value)}
				/>
			}
		/>
	);
}
