import { CommonReadonly } from '@warehouse/title/core';
import { TitleCardProps } from './title-card.types';

export class TitleCardService {
	static getTitleCardPropsFromTitleCommonReadonly(
		commonReadonly: CommonReadonly,
	): Pick<TitleCardProps, 'titleUuid' | 'titleSearchDisplayNameFullHTML'> {
		return {
			titleUuid: commonReadonly.uuid,
			titleSearchDisplayNameFullHTML: commonReadonly.titleSearchDisplayNameFullHTML,
		};
	}
}
