import { apolloAdapter, queryClient } from '@warehouse/shared/infra';
import { Observable } from 'rxjs';
import { GET_LICENSORS, Licensor as GqlLicensor } from '@warehouse/graphql';
import { singletonFactory } from '@warehouse/shared/util';
import { QueryObserverResult } from '@warehouse/shared/core';
import { QueryObserver, QueryObserverOptions } from '@tanstack/react-query';
import { Licensor } from '@warehouse/title-shared/core';
import { LicensorRepository, WatchLicensorsOptions } from './licensors.repository';

export class GqlLicensorRepository implements LicensorRepository {
	static buildHash(options: WatchLicensorsOptions): string {
		return `licensors-query-${options.pollInterval ?? 'no-polling'}`;
	}

	private _apolloAdapter = apolloAdapter;

	watchLicensors(options: WatchLicensorsOptions): Observable<QueryObserverResult<Licensor[]>> {
		const { pollInterval } = options;
		return this.#watchObservableQuery({
			queryKey: [GqlLicensorRepository.buildHash(options)],
			queryFn: () => this.#fetchLicensors(),
			refetchInterval: pollInterval,
		});
	}

	#watchObservableQuery<TData>(options: QueryObserverOptions<TData>) {
		return new Observable<QueryObserverResult<TData, Error>>((observer) => {
			const queryObserver = new QueryObserver<TData>(queryClient, {
				...options,
			});

			return queryObserver.subscribe((result) => {
				observer.next(result);
			});
		});
	}

	async #fetchLicensors(): Promise<Licensor[]> {
		const response = await this._apolloAdapter.client.query<{
			getLicensors: GqlLicensor[];
		}>({
			query: GET_LICENSORS,
		});

		return response.data.getLicensors.map((gqlLicensor) => ({
			default: gqlLicensor.default ?? undefined,
			displayName: gqlLicensor.displayName,
		}));
	}
}

export const licensorRepositorySingleton = singletonFactory<LicensorRepository>({
	factory: () => new GqlLicensorRepository(),
});
