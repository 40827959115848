import {
	GridFilterModel as MuixFilterModel,
	GridLogicOperator as GridLogicOperatorFromMUIX,
} from '@mui/x-data-grid-pro';
import { GridFilterItem, GridFilterModel, GridFilterOperator, GridLogicOperator } from '@warehouse/object-browser/core';
import { LicensorColumnName, TitleColumnName, TitleIdsColumnName } from '../specific-column-names';
import { ColumnName } from '../columns';
import { components } from '../../infra/inventory.openapi';

export class FilterModelAdapter {
	static fromMuiXDataGridToApi(filterModel: MuixFilterModel): GridFilterModel {
		return {
			...filterModel,
			logicOperator:
				filterModel.logicOperator === GridLogicOperatorFromMUIX.And ? GridLogicOperator.AND : GridLogicOperator.OR,
			items: filterModel.items.map(
				(filterItem) =>
					({
						...filterItem,
						// Safe cast as the column name is a valid column name coming from the MUI X Data Grid
						field: FilterModelAdapter.columnNameToField(filterItem.field as ColumnName),
						// Safe cast as the operator is a valid operator coming from the MUI X Data Grid, which we followed to define our types
						operator: filterItem.operator as GridFilterOperator,
					}) satisfies GridFilterItem,
			),
		};
	}

	static fromApiToMuiXDataGrid(filterModel: GridFilterModel): MuixFilterModel {
		return {
			...filterModel,
			logicOperator:
				filterModel.logicOperator === GridLogicOperator.AND
					? GridLogicOperatorFromMUIX.And
					: GridLogicOperatorFromMUIX.Or,
			items: filterModel.items.map((filterItem) => ({
				...filterItem,
				field: FilterModelAdapter.fieldToColumnName(filterItem.field),
			})),
		};
	}

	static columnNameToField(columnName: ColumnName): components['schemas']['NxObjectSearchableFields'] {
		switch (columnName) {
			case LicensorColumnName:
				return 'assignedTitles.licensor.label';
			case TitleColumnName:
				return 'assignedTitles.titleSearchDisplayNameFull';
			case TitleIdsColumnName:
				return 'assignedTitles.titleUuid';
			default:
				return columnName;
		}
	}

	static fieldToColumnName(field: components['schemas']['NxObjectSearchableFields']): ColumnName {
		switch (field) {
			case 'assignedTitles.licensor.label':
				return LicensorColumnName;
			case 'assignedTitles.titleSearchDisplayNameFull':
				return TitleColumnName;
			case 'assignedTitles.titleUuid':
				return TitleIdsColumnName;
			case 'region':
			case 'folder':
			case 'deletedAt':
			case 'assignedTitles.licensor.uuid':
			case 'assignedTitles.titleSearchSortFull':
				return 'name';
			default:
				return field;
		}
	}
}
