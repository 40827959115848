import { useMemo } from 'react';
import { isDerivedFrom, isEntryOf, TitleFull as Title } from '@warehouse/title/core';
import { compilationUuid, getWorkTypeObject, getWorkTypeUuid } from '@warehouse/title/infra';
import { WorkType } from '@warehouse/title-shared/core';
import useWorkTypes from '../../../utils/hooks/titles/useWorkTypes';

export type ChildrenTabNameButtonId =
	| 'add-single'
	| 'add-multiple'
	| 'add-existing'
	| 'create-multiple-manifestations'
	| 'create-multiple-edits';

export interface ChildTabDefinition {
	childWorkTypeUuid: string;
	singularLabel: string;
	pluralLabel: string;
	relationshipType: string;
	addChildButtonOptions: ChildrenTabNameButtonId[];
}

export function useChildrenTabs(title: Title | undefined, excludedWorkTypes?: string[]): ChildTabDefinition[] {
	const { getWorkType } = useWorkTypes();
	const currentWorkType = title?.readonly?.workType;

	return useMemo(() => {
		if (!currentWorkType) return [];
		const workType = getWorkType(getWorkTypeUuid(currentWorkType));
		if (!workType) return [];
		const tabs = workType.tabsToDisplay.map((tab) => {
			const computeRelationshipType = () => {
				if (workType?.value === compilationUuid()) {
					if (tab.value === getWorkTypeObject(WorkType.Manifestation).uuid) {
						return isDerivedFrom();
					}
					return isEntryOf();
				}
				return tab.relationshipRequired;
			};

			return {
				childWorkTypeUuid: tab.value,
				singularLabel: tab.labelSingular,
				pluralLabel: tab.labelPlural,
				relationshipType: computeRelationshipType(),
				addChildButtonOptions: tab.addChildButtonOptions as ChildrenTabNameButtonId[],
			};
		});
		if (excludedWorkTypes && excludedWorkTypes.length)
			return tabs.filter(({ childWorkTypeUuid }) => !excludedWorkTypes.includes(childWorkTypeUuid));
		return tabs;
	}, [getWorkType, currentWorkType, excludedWorkTypes]);
}
