import React from 'react';
import { multiTitleSelectorSelector } from '@warehouse/title-shared/multi-titles-selector-feature/core';
import { MultiTitlesSelector, TitleSearchInput } from '@warehouse/title-shared/multi-titles-selector-feature/ui';
import { LicensorSelector } from '@warehouse/title-shared/feature-licensor-selector';
import { NxObjectFull } from '@warehouse/object-browser/core';
import { assignTitleStoreSelector, useAssignTitleStore } from '../assign-title.store';

export function AssignTitlesPrompt({ nxObjectFull, onTitlesSubmitted }: AssignTitlesPromptProps) {
	const paginationStore = useAssignTitleStore((state) => state);
	const selectableTitles = useAssignTitleStore(multiTitleSelectorSelector.selectableTitles);
	const assignTitlesIsPending = useAssignTitleStore(multiTitleSelectorSelector.isPending);
	const licensorToFilterOn = useAssignTitleStore(multiTitleSelectorSelector.licensorLabelToFilterOn);
	const { submitTitles } = useAssignTitleStore(assignTitleStoreSelector.actions);
	const isSubmitting = useAssignTitleStore(assignTitleStoreSelector.isSubmitting);
	const actions = useAssignTitleStore(assignTitleStoreSelector.actions);
	const isPromptOpen = useAssignTitleStore(assignTitleStoreSelector.isPromptOpen);

	return (
		<MultiTitlesSelector
			isOpen={isPromptOpen}
			onClose={actions.closePrompt}
			selectableTitles={selectableTitles}
			textHeader="Add Titles"
			searchAndSelectedPluralLabel="Titles"
			paginationStore={paginationStore}
			onSubmit={() => {
				submitTitles(nxObjectFull).then((newNxObjectFull) => {
					if (newNxObjectFull) onTitlesSubmitted(newNxObjectFull);
				});
			}}
			loading={assignTitlesIsPending || isSubmitting}
			searchInputsSlot={
				<>
					<LicensorSelector
						licensor={licensorToFilterOn ?? undefined}
						onLicensorChange={actions.setLicensorLabelToFilterOn}
					/>
					<TitleSearchInput searchPlaceholder="Search Titles" />
				</>
			}
		/>
	);
}

export interface AssignTitlesPromptProps {
	nxObjectFull: NxObjectFull;
	onTitlesSubmitted: (nxObjectFull: NxObjectFull) => void;
}
