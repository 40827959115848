import React from 'react';
import { Divider, Typography } from '@mui/material';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import styled, { css } from 'styled-components';
import { TitleCard } from '@warehouse/title/ui';
import { indexedTitleToCommonReadonly } from '@warehouse/title/infra';
import { IndexedTitleV2 } from '@warehouse/graphql';
import { CommonReadonly, TitleCommon } from '@warehouse/title/core';
import { UnreachableError } from '@warehouse/shared/errors';
import { TitleInfoWrapper } from './styles';
import TitleSearch from '../../../components/titles/titleSearch';
import Button from '../../../components/library/Button';
import useWorkTypes, { filterOutTypesByName } from '../../../utils/hooks/titles/useWorkTypes';
import getLabelWithRequired from '../createTitle/getLabelWithRequired';
import { TitleCardService } from '../../../../libs/title/ui/title-card/title-card.service';

const CloseButton = styled(Button)(
	({ theme }) => css`
		svg {
			color: ${theme.palette.light.backgroundAlt};
		}

		:hover {
			svg {
				color: ${theme.palette.black.main};
			}
		}
	`,
);

interface ParentTitleSelectorProps {
	workType: string | undefined;
	isRequired?: boolean;
	parentTitle: IndexedTitleV2 | undefined;
	selectedParent: TitleCommon['readonly'] | undefined;
	setParentTitle: (title: IndexedTitleV2 | undefined) => void;
	setParentType: (parentType: string | undefined) => void;
	parentType: string | undefined;
	parentTitleErrors: string;
	licensor?: string;
}

const Wrapper = styled.div<{ tight?: boolean; $staticDisplay?: boolean }>(
	({ tight, $staticDisplay }) => css`
		display: flex;
		${$staticDisplay
			? css`
					max-width: 500px;
				`
			: ''}

		${tight &&
		css`
			width: 450px;
			input {
				min-width: 200px !important;
			}

			.input-wrapper {
				min-width: 200px !important;
			}
		`}
	`,
);

function ParentTitleSelector({
	workType,
	isRequired = false,
	parentTitle,
	selectedParent,
	setParentTitle,
	setParentType,
	parentType,
	parentTitleErrors,
	licensor,
}: ParentTitleSelectorProps) {
	const { getNameByUuid, getSupportedParentTypesByUuid } = useWorkTypes();
	const workTypeName = getNameByUuid(workType || '');

	return (
		<TitleInfoWrapper>
			<Typography variant="s2Medium" color="text.primary">
				{getLabelWithRequired('Parent Title', isRequired, ':')}
			</Typography>
			<Wrapper tight={workTypeName === 'Supplemental'} $staticDisplay={!!(parentTitle || selectedParent)}>
				{!parentTitle && !selectedParent && (
					<TitleSearch
						onSelect={setParentTitle}
						selected={parentTitle || null}
						workTypes={filterOutTypesByName(['Compilation'], getSupportedParentTypesByUuid(workType || '') || [])}
						workType={parentType || ''}
						setWorkType={setParentType}
						error={parentTitleErrors.length > 0 ? parentTitleErrors : undefined}
						licensor={licensor}
					/>
				)}
				{(parentTitle || selectedParent) && (
					<TitleCard variant="colored" {...getTitleCardProps(parentTitle, selectedParent)} />
				)}
			</Wrapper>
			{parentTitle && (
				<CloseButton onClick={() => setParentTitle(undefined)} nxstyle="tertiary-light">
					<Icon path={mdiClose} size="16px" />
				</CloseButton>
			)}
			<Divider orientation="vertical" />
		</TitleInfoWrapper>
	);
}

function getTitleCardProps(parentTitle: IndexedTitleV2 | undefined, selectedParent: CommonReadonly | undefined) {
	if (parentTitle)
		return TitleCardService.getTitleCardPropsFromTitleCommonReadonly(indexedTitleToCommonReadonly(parentTitle));
	if (selectedParent) return TitleCardService.getTitleCardPropsFromTitleCommonReadonly(selectedParent);

	// Will never be thrown, because it is called conditionally if either parentTitle or selectedParent is defined
	throw new UnreachableError('Either parentTitle or selectedParent must be defined');
}

export default ParentTitleSelector;
