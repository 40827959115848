import React from 'react';
import { TitleCard } from '@warehouse/title/ui';
import { CommonReadonly, TitleLightWithSelectedParent } from '@warehouse/title/core';
import { WorkType } from '@warehouse/title-shared/core';
import { SelectAllTypography } from '../../../../src/components/library/SelectAllTypography';
import { CustomColumnDef } from '../../../../src/components/library/SimpleTable/customColumnDef';
import { EIDRtoTime } from '../../../../src/components/library/TimeInput';
import { TitleCardService } from '../title-card/title-card.service';

export function IDColumn<T extends CommonReadonly>(): CustomColumnDef<T, string> {
	return {
		header: 'ID',
		accessorKey: 'uuid',
		id: 'uuid',
		editable: false,
		renderReadOnly: (value: string) => (
			<SelectAllTypography style={{ paddingLeft: 12 }} variant="body2">
				{value}
			</SelectAllTypography>
		),
	};
}

export function TitleCardColumn<T extends TitleLightWithSelectedParent>(
	context: 'common' | 'relationship-tab-children' = 'common',
): CustomColumnDef<T, T> {
	return {
		header: 'Title',
		id: 'titleSearchDisplayNameFull',
		editable: false,
		highlightOptions: {
			highlight: true,
		},
		accessorFn: (row) => row,
		renderReadOnly: (value) => (
			<TitleCard
				{...TitleCardService.getTitleCardPropsFromTitleCommonReadonly(value)}
				selectedParent={value.selectedParent}
				context={context}
			/>
		),
	};
}

interface MinimumReleaseDateColumnRow {
	releaseDate?: string | null;
}
export function ReleaseDateColumn<T extends MinimumReleaseDateColumnRow>(): CustomColumnDef<T, string> {
	return {
		header: 'Release Date',
		accessorFn: (row) => row.releaseDate || '',
		renderReadOnly: (v) => v,
		id: 'releaseDate',
		editable: false,
	};
}

export function SequenceNumberColumn<T extends TitleLightWithSelectedParent>(): CustomColumnDef<T, string> {
	return {
		header: 'Sequence Number',
		accessorFn: (row) => row.selectedParent.sequenceInfo.number?.toString() || '',
		renderReadOnly: (v) => v,
		id: 'sequenceNumber',
		editable: false,
	};
}

export function RunLengthColumn<T extends CommonReadonly>(): CustomColumnDef<T, T> {
	return {
		header: 'Run Length',
		renderReadOnly: (v) => {
			if (v.workType !== WorkType.Compilation) return EIDRtoTime(v.runLength);
			return '';
		},
		accessorFn: (row) => row,
		id: 'runLength',
		editable: false,
	};
}
