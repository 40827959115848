import { ToggleButtons } from '@warehouse/shared/ui';
import React from 'react';
import styled, { css } from 'styled-components';
import { objectBrowserStoreSelector, useObjectBrowserStore } from '@warehouse/object-browser/domain';
import { SearchMode } from '@warehouse/object-browser/core';

export function SearchModeToggleButton() {
	const actions = useObjectBrowserStore(objectBrowserStoreSelector.actions);
	const searchMode = useObjectBrowserStore(objectBrowserStoreSelector.searchMode);

	return (
		<ToggleButtonRow>
			<ToggleButtons<SearchMode>
				size="xs"
				value={searchMode}
				onChange={(value) => actions.setSearchMode(value)}
				options={[
					{ value: SearchMode.EVERYWHERE, label: 'All Storage' },
					{ value: SearchMode.THIS_FOLDER, label: 'This Folder' },
				]}
			/>
		</ToggleButtonRow>
	);
}

const ToggleButtonRow = styled.div(
	({ theme }) => css`
		margin-top: ${theme.spacing(-1)};
		padding-bottom: ${theme.spacing(2)};
		padding-left: ${theme.spacing(2)};
	`,
);
