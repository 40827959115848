import { useMemo } from 'react';
import { TitleLightWithSelectedParent } from '@warehouse/title/core';
import { WorkType } from '@warehouse/title-shared/core';

import useWorkTypes from '../../../src/utils/hooks/titles/useWorkTypes';
import {
	IDColumn,
	ReleaseDateColumn,
	RunLengthColumn,
	SequenceNumberColumn,
	TitleCardColumn,
} from '../ui/columns/common-columns';
import { EntryClassColumn, EntryNumberColumn } from './columns/compilation-columns';
import { DistributionNumberColumn, HouseSequenceColumn } from './columns/episode-columns';
import { EditClassesColumn, EditUseColumn, MadeForRegionsColumn } from './columns/edit-columns';
import { CustomColumnDef } from '../../../src/components/library/SimpleTable/customColumnDef';
import { AlidColumn, PlatformSpecificationColumn, VersionLanguagesColumn } from './columns/manifestation-columns';

const getEntriesColumn = (): CustomColumnDef<TitleLightWithSelectedParent>[] => [
	TitleCardColumn(),
	EntryNumberColumn(),
	EntryClassColumn(),
	RunLengthColumn(),
	IDColumn(),
];

const getSeasonsColumns = (): CustomColumnDef<TitleLightWithSelectedParent>[] => [
	TitleCardColumn(),
	ReleaseDateColumn(),
	SequenceNumberColumn(),
	IDColumn(),
];

const getEpisodeColumns = (): CustomColumnDef<TitleLightWithSelectedParent>[] => [
	TitleCardColumn(),
	ReleaseDateColumn(),
	SequenceNumberColumn(),
	DistributionNumberColumn(),
	HouseSequenceColumn(),
	RunLengthColumn(),
	IDColumn(),
];

const getEditsColumns = (): CustomColumnDef<TitleLightWithSelectedParent>[] => [
	TitleCardColumn(),
	EditUseColumn(),
	EditClassesColumn(),
	MadeForRegionsColumn(),
	RunLengthColumn(),
	IDColumn(),
];

const getManifestationsColumns = (): CustomColumnDef<TitleLightWithSelectedParent>[] => [
	TitleCardColumn(),
	PlatformSpecificationColumn(),
	AlidColumn(),
	MadeForRegionsColumn(),
	VersionLanguagesColumn(),
	RunLengthColumn(),
	IDColumn(),
];

export const defaultColumns: CustomColumnDef<TitleLightWithSelectedParent>[] = [TitleCardColumn(), IDColumn()];

const DEFAULT_SEARCH_FIELDS: string[] = ['indexed.titleSearchDisplayNameFull', 'indexed.releaseDate', 'indexed.uuid'];

const ENTRIES_SEARCH_FIELDS: string[] = [
	'indexed.titleSearchDisplayNameFull',
	'indexed.releaseDate',
	'indexed.runLength',
	'indexed.uuid',
];

const EPISODES_SEARCH_FIELDS: string[] = [
	'indexed.titleSearchDisplayNameFull',
	'indexed.releaseDate',
	'indexed.sequenceInfo.number',
	'indexed.sequenceInfo.distributionNumber.distributionNumber',
	'indexed.sequenceInfo.houseSequence.houseSequence',
	'indexed.runLength',
	'indexed.uuid',
];

const SEASONS_SEARCH_FIELDS: string[] = [
	'indexed.titleSearchDisplayNameFull',
	'indexed.releaseDate',
	'indexed.sequenceInfo.number',
	'indexed.uuid',
];

const EDITS_SEARCH_FIELDS: string[] = [
	'indexed.titleSearchDisplayNameFull',
	'indexed.releaseDate',
	'indexed.editUse',
	'indexed.editClasses',
	'indexed.madeForRegions.label',
	'indexed.madeForRegionsExclude.label',
	'indexed.runLength',
	'indexed.uuid',
];

const MANIFESTATIONS_SEARCH_FIELDS: string[] = [
	'indexed.titleSearchDisplayNameFull',
	'indexed.platformSpecification.label',
	'indexed.alid',
	'indexed.madeForRegions.label',
	'indexed.madeForRegionsExclude.label',
	'indexed.runLength',
	'indexed.uuid',
];

export function useChildrenColumnsPerWorkType(
	parentWorkTypeUuid: string,
	childWorkType: WorkType,
): [CustomColumnDef<TitleLightWithSelectedParent>[], string[]] {
	const workTypes = useWorkTypes();

	return useMemo(() => {
		switch (parentWorkTypeUuid) {
			case workTypes.compilationUuid():
				switch (childWorkType) {
					case WorkType.Manifestation:
						return [getManifestationsColumns(), MANIFESTATIONS_SEARCH_FIELDS];
					default:
						return [getEntriesColumn(), ENTRIES_SEARCH_FIELDS];
				}
			case workTypes.seasonsUuid():
				switch (childWorkType) {
					case WorkType.Manifestation:
						return [getManifestationsColumns(), MANIFESTATIONS_SEARCH_FIELDS];
					default:
						return [getEpisodeColumns(), EPISODES_SEARCH_FIELDS];
				}
			case workTypes.seriesUuid():
				switch (childWorkType) {
					case WorkType.Manifestation:
						return [getManifestationsColumns(), MANIFESTATIONS_SEARCH_FIELDS];
					case WorkType.Episode:
						return [getEpisodeColumns(), EPISODES_SEARCH_FIELDS];
					default:
						return [getSeasonsColumns(), SEASONS_SEARCH_FIELDS];
				}
			case workTypes.movieUuid():
			case workTypes.episodesUuid():
				return [getEditsColumns(), EDITS_SEARCH_FIELDS];
			case workTypes.editUuid():
				switch (childWorkType) {
					case WorkType.Manifestation:
						return [getManifestationsColumns(), MANIFESTATIONS_SEARCH_FIELDS];
					default:
						return [getEditsColumns(), EDITS_SEARCH_FIELDS];
				}
			default:
				return [defaultColumns, DEFAULT_SEARCH_FIELDS];
		}
	}, [parentWorkTypeUuid, workTypes, childWorkType]);
}
