import { TitleIndexedParent, TitleIndexedSequenceInfo } from '@warehouse/graphql';
import { RelationshipTypeService, TitleLightParent, TitleLightSequenceInfo } from '@warehouse/title/core';

function titleIndexedSequenceInfoToTitleLightSequenceInfo(
	sequenceInfo?: TitleIndexedSequenceInfo,
): TitleLightSequenceInfo {
	return {
		distributionNumber: sequenceInfo?.distributionNumber?.distributionNumber,
		number: sequenceInfo?.number ?? undefined,
		houseSequence: sequenceInfo?.houseSequence?.houseSequence,
	};
}

export class TitleLightParentAdapter {
	static adapt(parent: TitleIndexedParent): TitleLightParent {
		const primary = parent.primary ?? false;

		if (!parent.relationshipType) throw new Error(`Missing relationshipType in parent ${parent}`);

		return {
			contentId: parent.parentContentId,
			primary,
			relationshipType: RelationshipTypeService.relationshipTypeStringToEnum(parent.relationshipType),
			relationshipSubtypes: parent.relationshipSubtypes?.map((subType) => subType.uuid) ?? undefined,
			sequenceInfo: titleIndexedSequenceInfoToTitleLightSequenceInfo(parent.sequenceInfo ?? undefined),
		};
	}

	static adaptArray(titleLightParents: TitleIndexedParent[] | undefined): TitleLightParent[] | undefined {
		if (!titleLightParents) return undefined;
		return titleLightParents.map((titleLightParent) => TitleLightParentAdapter.adapt(titleLightParent));
	}
}
