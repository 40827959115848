import { getWorkType } from '@warehouse/title/infra';
import {
	CommonReadonly,
	isEdit,
	isManifestation,
	TitleSearchDisplayNameFullHTML,
	isEntryOf,
} from '@warehouse/title/core';
import workTypesJson from '../../../src/assets/json-administration-profiles/workTypes.json';

export type WorkTypeObject = {
	workType: WorkType;
	uuid: string;
	label: string;
};

export enum WorkType {
	Movie = 'Movie',
	Season = 'Season',
	Series = 'Series',
	Edit = 'Edit',
	Compilation = 'Compilation',
	Episode = 'Episode',
	Supplemental = 'Supplemental',
	Manifestation = 'Manifestation',
}

export type StandaloneWorkType =
	| WorkType.Series
	| WorkType.Season
	| WorkType.Episode
	| WorkType.Movie
	| WorkType.Supplemental
	| WorkType.Compilation;

export function isStandaloneWorkType(workType: WorkType): workType is StandaloneWorkType {
	return [
		WorkType.Series,
		WorkType.Season,
		WorkType.Episode,
		WorkType.Movie,
		WorkType.Supplemental,
		WorkType.Compilation,
	].includes(workType);
}

export type WorkTypeWithRequiredParent = WorkType.Season | WorkType.Episode | WorkType.Edit | WorkType.Manifestation;
export type WorkTypeWithoutRequiredParent = Exclude<WorkType, WorkTypeWithRequiredParent>;

export function hasRequiredParent(workType: WorkType): workType is WorkTypeWithRequiredParent {
	return [WorkType.Season, WorkType.Episode, WorkType.Edit, WorkType.Manifestation].includes(workType);
}

export type EditDerivableWorkType = WorkType.Movie | WorkType.Episode | WorkType.Supplemental;
export function isEditDerivableWorkType(workType: WorkType): workType is EditDerivableWorkType {
	return [WorkType.Movie, WorkType.Episode, WorkType.Supplemental].includes(workType);
}

export type ManifestationDerivableWorkType =
	| EditDerivableWorkType
	| WorkType.Series
	| WorkType.Compilation
	| WorkType.Season;
export function isManifestationDerivableWorkType(workType: WorkType): workType is ManifestationDerivableWorkType {
	return [
		WorkType.Movie,
		WorkType.Episode,
		WorkType.Supplemental,
		WorkType.Series,
		WorkType.Compilation,
		WorkType.Season,
	].includes(workType);
}

export type DerivedWorkType = WorkType.Edit | WorkType.Manifestation;

export function isDerivedWorkType(workType: WorkType): workType is DerivedWorkType {
	return [WorkType.Edit, WorkType.Manifestation].includes(workType);
}

export type ParentAbstractionWorkTypeAndWorkType =
	| {
			parentAbstractionWorkType: EditDerivableWorkType;
			workType: WorkType.Edit;
	  }
	| {
			parentAbstractionWorkType: ManifestationDerivableWorkType;
			workType: WorkType.Manifestation;
	  };

export type CombinedWorkType = StandaloneWorkType | ParentAbstractionWorkTypeAndWorkType;

export function isParentAbstractionWorkTypeAndWorkType(
	combinedWorkType: CombinedWorkType,
): combinedWorkType is ParentAbstractionWorkTypeAndWorkType {
	return typeof combinedWorkType === 'object';
}

export function getCombinedWorktype(titleLight: CommonReadonly): CombinedWorkType {
	if (isEdit(titleLight)) {
		return {
			workType: titleLight.workType,
			parentAbstractionWorkType: titleLight.parentAbstractionWorkType,
		};
	}

	if (isManifestation(titleLight)) {
		return {
			workType: titleLight.workType,
			parentAbstractionWorkType: titleLight.parentAbstractionWorkType,
		};
	}

	return titleLight.workType;
}

export class WorkTypesService {
	public static getOptions(): { value: string; label: string }[] {
		return workTypesJson.map((workType) => ({
			value: workType.value,
			label: workType.label,
		}));
	}

	public static getAllWorkTypes(): WorkType[] {
		return Object.values(WorkType);
	}

	public static getAllowedWorkTypesByRelationshipSource(relationship: string): WorkType[] {
		if (relationship === isEntryOf()) {
			return workTypesJson.map((wt) => getWorkType(wt.value));
		}
		return workTypesJson.reduce<WorkType[]>((acc, wt) => {
			if (wt.supportedParentRelationshipTypeAsSource.includes(relationship)) {
				return [...acc, getWorkType(wt.value)];
			}
			return acc;
		}, []);
	}

	public static softGetCombinedWorktypeFromTitleSearchDisplayNameFullHTML(
		titleSearchDisplayNameFullHTML: TitleSearchDisplayNameFullHTML,
	): CombinedWorkType | undefined {
		if (!titleSearchDisplayNameFullHTML.workType) return undefined;
		const workTypeEnum = titleSearchDisplayNameFullHTML.workType;

		if (workTypeEnum === WorkType.Edit) {
			return {
				workType: WorkType.Edit,
				parentAbstractionWorkType: titleSearchDisplayNameFullHTML.parentAbstractionWorkType as EditDerivableWorkType,
			};
		}

		if (workTypeEnum === WorkType.Manifestation) {
			return {
				workType: WorkType.Manifestation,
				parentAbstractionWorkType:
					titleSearchDisplayNameFullHTML.parentAbstractionWorkType as ManifestationDerivableWorkType,
			};
		}

		return workTypeEnum;
	}
}
