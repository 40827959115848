import React, { FC } from 'react';
import { StyledDataGridPro, StyledDataGridProProps } from '@warehouse/shared/ui';
import { DataGridProProps, GridValidRowModel } from '@mui/x-data-grid-pro';

export function CustomDataGridPro<T extends GridValidRowModel>(props: DataGridProProps<T> & StyledDataGridProProps) {
	const { getRowClassName, slotProps, ...rest } = props;
	return (
		<StyledDataGridPro<FC<DataGridProProps<T>>>
			{...rest}
			slotProps={{
				loadingOverlay: {
					variant: 'skeleton',
					noRowsVariant: 'skeleton',
					...slotProps?.loadingOverlay,
				},
				...slotProps,
			}}
			getRowClassName={(params) =>
				`${getRowClassName ? getRowClassName(params) : ''} ${params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}`
			}
		/>
	);
}
