import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Portal } from '@mui/material';
import { objectBrowserStoreSelector, useObjectBrowserStore } from '@warehouse/object-browser/domain';
import { TabPanel, TabsHeader, TabsWrapper } from '@warehouse/shared/ui';
import Icon from '@mdi/react';
import { mdiArrowLeftCircleOutline, mdiArrowRightCircleOutline } from '@mdi/js';
import { GeneralTab } from './GeneralTab';
import { SideDrawerHeader, SideModal } from '../../../../src/components/library/SideDrawer';
import Button from '../../../../src/components/library/Button';

interface SelectedItemDrawerProps {
	wrapperRef?: React.RefObject<HTMLDivElement>;
}

const tabs = [
	{
		label: 'General',
		component: <GeneralTab />,
	},
];

export function SelectedItemDrawer({ wrapperRef }: SelectedItemDrawerProps) {
	const isNxObjectDrawerOpen = useObjectBrowserStore(objectBrowserStoreSelector.isNxObjectDrawerOpen);
	const actions = useObjectBrowserStore(objectBrowserStoreSelector.actions);
	const selectedNxObject = useObjectBrowserStore(objectBrowserStoreSelector.selectedNxObject);
	const selectedNxObjectFull = useObjectBrowserStore(objectBrowserStoreSelector.selectedNxObjectFull);
	const isSelectedNxObjectFirst = useObjectBrowserStore(objectBrowserStoreSelector.isSelectedNxObjectFirst);
	const isSelectedNxObjectLast = useObjectBrowserStore(objectBrowserStoreSelector.isSelectedNxObjectLast);
	const isSelectedItemPresent = useObjectBrowserStore(objectBrowserStoreSelector.isSelectedItemPresent);
	const [selectedTab, setSelectedTab] = useState(0);

	return (
		<Portal container={wrapperRef?.current || document.body}>
			<SideModal
				maxWidth={900}
				side="right"
				open={isNxObjectDrawerOpen}
				handleClose={actions.closeNxObjectDrawer}
				header={
					<SideDrawerHeader
						maxWidth="900px"
						title={selectedNxObjectFull?.nxObject?.name || selectedNxObject?.name}
						handleClose={() => actions.closeNxObjectDrawer()}
						action={
							isSelectedItemPresent ? (
								<NextPreviousWrapper>
									<Button
										nxstyle="tertiary-light"
										onClick={() => actions.selectPreviousObject()}
										disabled={isSelectedNxObjectFirst}
									>
										<Icon aria-label="Select Next Object Drawer" path={mdiArrowLeftCircleOutline} size={1} />
									</Button>
									<Button
										nxstyle="tertiary-light"
										onClick={() => actions.selectNextObject()}
										disabled={isSelectedNxObjectLast}
									>
										<Icon aria-label="Select Next Object Drawer" path={mdiArrowRightCircleOutline} size={1} />
									</Button>
								</NextPreviousWrapper>
							) : null
						}
					/>
				}
			>
				<TabsWrapper>
					<TabsHeader selectedTab={selectedTab} onChange={setSelectedTab} prefix="object-browser-drawer" tabs={tabs} />
					{tabs.map((tab, index) => (
						<TabPanel prefix="object-browser-drawer" key={index} value={selectedTab} index={index}>
							<PaddingWrapper>{tab.component}</PaddingWrapper>
						</TabPanel>
					))}
				</TabsWrapper>
			</SideModal>
		</Portal>
	);
}

const NextPreviousWrapper = styled.div(
	() => css`
		align-items: center;
		display: flex;
		justify-content: space-between;
		width: min-content;
	`,
);

const PaddingWrapper = styled.div(
	({ theme }) => `
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		overflow-y: auto;
		overflow-x: hidden;
		height: calc(100vh - 128px);
		padding: ${theme.spacing(3)};
		gap: ${theme.spacing(2)};
		width: 900px;
		box-sizing: border-box;
	`,
);
