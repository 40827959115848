import { GridColDef, GridValueGetter } from '@mui/x-data-grid-pro';
import { filesize } from 'filesize';
import { AssignmentStatus, NxObject, NxObjectType, StorageTierService } from '@warehouse/object-browser/core';
import { timeToString } from '../../../src/components/library/DatePicker/DatePicker';
import { IconCell, StorageTierCell } from './ui/fields';
import { getCustomGridSingleSelectOperators } from '../../shared/ui/table/muix.adapter';
import { NameCell } from './ui/fields/NameCell';
import { TitleCell } from './ui/fields/TitleCell';
import { LicensorColumnName, NameColumnName, TitleColumnName, TitleIdsColumnName } from './specific-column-names';
import { getLicensorCellClassName } from './ui/fields/get-licensor-cell-class-name';

const assignmentStatusWrapper: (cb: GridValueGetter<NxObject>) => GridValueGetter<NxObject> =
	(cb) => (value, row, column, apiRef) => {
		if (row.itemType === NxObjectType.Folder && row.assignmentStatus === AssignmentStatus.Unassigned) {
			return undefined;
		}
		if (row.itemType === NxObjectType.Folder && row.assignmentStatus === AssignmentStatus.Unmanaged) {
			return '*';
		}
		return cb(value, row, column, apiRef);
	};

const valueOptions = [
	{ value: NxObjectType.File, label: 'File' },
	{ value: NxObjectType.Folder, label: 'Folder' },
];

export const objectBrowserTableColumns = [
	{
		field: 'itemType',
		type: 'singleSelect',
		headerName: 'Type',
		renderHeader: () => null,
		filterOperators: getCustomGridSingleSelectOperators(),
		valueOptions,
		align: 'center',
		disableColumnMenu: true,
		disableReorder: true,
		pinnable: true,
		hideable: false,
		filterable: true,
		sortable: false,
		renderCell: IconCell,
		resizable: false,
		width: 40,
	},
	{
		field: NameColumnName,
		headerName: 'Name',
		minWidth: 325,
		flex: 1,
		hideable: false,
		renderCell: NameCell,
	},
	{
		field: 'key',
		headerName: 'Key',
		width: 300,
	},
	{
		field: 'bucket',
		headerName: 'Bucket',
		width: 300,
	},
	{
		field: LicensorColumnName,
		headerName: 'Licensor',
		width: 180,
		valueGetter: assignmentStatusWrapper((_, row) => row.assignedTitles[0]?.licensor.label),
		cellClassName: getLicensorCellClassName,
	},
	{
		field: TitleColumnName,
		headerName: 'Title',
		width: 400,
		// We need a valueGetter here because the CustomGridCell needs a value to check if it's empty and render the '--' string
		valueGetter: assignmentStatusWrapper((_, row) => row.assignedTitles[0]?.titleSearchDisplayNameFullHTML),
		renderCell: TitleCell,
	},
	{
		field: 'fileSize',
		headerName: 'Size',
		type: 'number',
		valueFormatter: (_, row) => (row.itemType === NxObjectType.File ? filesize(row.fileSize, { round: 1 }) : ''),
		width: 100,
	},
	{
		field: 'lastModifiedAt',
		headerName: 'Last Modified',
		type: 'date',
		valueFormatter: (_, row) => (row.lastModifiedAt ? timeToString(row.lastModifiedAt) : undefined),
		width: 160,
	},
	{
		field: 'storageTier',
		headerName: 'Storage Tier',
		renderCell: StorageTierCell,
		width: 170,
		type: 'singleSelect',
		valueOptions: StorageTierService.getStorageTierOptions(),
		filterOperators: getCustomGridSingleSelectOperators(),
	},
	{
		field: 'eTag',
		headerName: 'ETag',
		width: 300,
	},
	{
		field: 'online',
		headerName: 'Online',
		type: 'boolean',
		width: 140,
	},
	{
		field: 'uuid',
		headerName: 'ID',
		width: 300,
	},
	{
		field: 'uploadedByOrganization',
		headerName: 'Uploaded By Organization',
		width: 150,
	},
	{
		field: 'uploadedByUser',
		headerName: 'Uploaded By User',
		width: 150,
	},
	{
		field: 's3Uri',
		headerName: 'S3 URI',
		width: 300,
	},
	{
		field: TitleIdsColumnName,
		headerName: 'Title IDs',
		width: 300,
		valueGetter: (_, row) => row.assignedTitles.map((title) => title.titleUuid).join(', '),
	},
] as const satisfies readonly GridColDef<NxObject>[];

export type ColumnName = (typeof objectBrowserTableColumns)[number]['field'];
