import { GridSortModel } from '@mui/x-data-grid-pro';
import { TableColumnSort } from '@warehouse/shared/ui';
import { LicensorColumnName, TitleColumnName, TitleIdsColumnName } from '../specific-column-names';
import { ColumnName } from '../columns';
import { components } from '../../infra/inventory.openapi';

export class SortModelAdapter {
	static fromMuiXDataGridToApi(sortModel: GridSortModel): TableColumnSort[] {
		return sortModel.map((sortItem) => ({
			...sortItem,
			field: SortModelAdapter.columnNameToField(sortItem.field as ColumnName),
		}));
	}

	static fromApiToMuiXDataGrid(sortModel: TableColumnSort[]): GridSortModel {
		return sortModel.map((sortItem) => ({
			...sortItem,
			field: SortModelAdapter.fieldToColumnName(sortItem.field),
		}));
	}

	static columnNameToField(columnName: ColumnName): components['schemas']['NxObjectSearchableFields'] {
		switch (columnName) {
			case LicensorColumnName:
				return 'assignedTitles.licensor.label';
			case TitleColumnName:
				return 'assignedTitles.titleSearchSortFull';
			case TitleIdsColumnName:
				return 'assignedTitles.titleUuid';
			default:
				return columnName;
		}
	}

	static fieldToColumnName(field: components['schemas']['NxObjectSearchableFields']): ColumnName {
		switch (field) {
			case 'assignedTitles.licensor.label':
				return LicensorColumnName;
			case 'assignedTitles.titleSearchSortFull':
				return TitleColumnName;
			case 'assignedTitles.titleUuid':
				return TitleIdsColumnName;
			case 'region':
			case 'folder':
			case 'deletedAt':
			case 'assignedTitles.licensor.uuid':
			case 'assignedTitles.titleSearchDisplayNameFull':
				return 'name';
			default:
				return field;
		}
	}
}
