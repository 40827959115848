import { IndexedTitleV2 } from '@warehouse/graphql';
import {
	CommonReadonly,
	CommonReadonlyBase,
	CompilationReadonly,
	EditReadonly,
	EpisodeReadonly,
	ManifestationReadonly,
	MovieReadonly,
	SeasonReadonly,
	SeriesReadonly,
	SoftTitleCommon,
	SupplementalReadonly,
	TitleCommon,
	TitleFull,
	TitleLightIdLabel,
	TitleSearchDisplayNameFullHTML,
} from '@warehouse/title/core';
import { isEditDerivableWorkType, isManifestationDerivableWorkType, WorkType } from '@warehouse/title-shared/core';
import { getWorkType } from '@warehouse/title/infra';
import { Title } from '@nexspec/warehouse-shared-types';
import { TitleLightParentAdapter } from './title-light-parent.adapter';
import { TitleSearchDisplayFullHTMLAdapter } from './title-search-display-full-html.adapter';

export function titleGQLResponseToTitleFull({
	ultraMezzUuid,
	indexed,
	state,
	uuid,
	soft,
}: {
	ultraMezzUuid?: string | null;
	indexed: IndexedTitleV2;
	state: Title;
	uuid: string;
	soft?: boolean;
}): TitleFull {
	// Indexed should always be defined, the undefined in the interface is an artifact that should be removed
	if (!indexed) throw new Error('Missing indexed title');

	const commonReadonly = soft ? softIndexedTitleToTitleCommon(indexed).readonly : indexedTitleToCommonReadonly(indexed);

	return {
		uuid,
		ultraMezzUuid: ultraMezzUuid ?? undefined,
		readonly: commonReadonly,
		metadata: state.metadata,
	};
}

export function indexedTitleToTitleCommon(indexedTitle: IndexedTitleV2): TitleCommon {
	const readonly = indexedTitleToCommonReadonly(indexedTitle);

	if (!readonly.releaseYear) throw new Error(`Missing releaseYear in indexed title ${readonly.uuid}`);

	return {
		uuid: indexedTitle.uuid,
		readonly,
	};
}

export function softIndexedTitleToTitleCommon(indexedTitle: IndexedTitleV2): SoftTitleCommon {
	return {
		uuid: indexedTitle.uuid,
		readonly: indexedTitleToCommonReadonly(indexedTitle),
	};
}

export function indexedTitleToCommonReadonly(indexedTitle: IndexedTitleV2): CommonReadonly {
	if (!indexedTitle.workType?.uuid) {
		throw new Error(`Missing workType in indexed title ${indexedTitle.uuid}`);
	}

	const workType = getWorkType(indexedTitle.workType.uuid);

	switch (workType) {
		case WorkType.Movie:
			return indexedTitleToMovieReadonly(indexedTitle);
		case WorkType.Edit:
			return indexedTitleToEditReadonly(indexedTitle);
		case WorkType.Season:
			return indexedTitleToSeasonReadonly(indexedTitle);
		case WorkType.Series:
			return indexedTitleToSeriesReadonly(indexedTitle);
		case WorkType.Compilation:
			return indexedTitleToCompilationReadonly(indexedTitle);
		case WorkType.Supplemental:
			return indexedTitleToSupplementalReadonly(indexedTitle);
		case WorkType.Manifestation:
			return indexedTitleToManifestationReadonly(indexedTitle);
		case WorkType.Episode:
			return indexedTitleToEpisodeReadonly(indexedTitle);
		default:
			throw new Error(`Work type ${workType} is not supported`);
	}
}

interface MinimumIdLabel {
	uuid: string;
	label: string;
}
export function anyToTitleLightIdLabel<T extends MinimumIdLabel>(value: T): TitleLightIdLabel {
	return {
		uuid: value.uuid,
		label: value.label,
	};
}

function indexedTitleToMovieReadonly(indexedTitle: IndexedTitleV2): MovieReadonly {
	const commonTitleLightData = getCommonTitleLightData(indexedTitle);
	return {
		...commonTitleLightData,
		workType: WorkType.Movie,
		runLength: indexedTitle.runLength ?? undefined,
	};
}

function indexedTitleToEpisodeReadonly(indexedTitle: IndexedTitleV2): EpisodeReadonly {
	const commonTitleLightData = getCommonTitleLightData(indexedTitle);
	return {
		...commonTitleLightData,
		workType: WorkType.Episode,
		runLength: indexedTitle.runLength ?? undefined,
	};
}

function indexedTitleToSeasonReadonly(indexedTitle: IndexedTitleV2): SeasonReadonly {
	const commonTitleLightData = getCommonTitleLightData(indexedTitle);
	return {
		...commonTitleLightData,
		workType: WorkType.Season,
		runLength: indexedTitle.runLength ?? undefined,
		madeForRegion: indexedTitle.madeForRegions?.map(anyToTitleLightIdLabel),
	};
}

function indexedTitleToSeriesReadonly(indexedTitle: IndexedTitleV2): SeriesReadonly {
	const commonTitleLightData = getCommonTitleLightData(indexedTitle);
	return {
		...commonTitleLightData,
		workType: WorkType.Series,
		runLength: indexedTitle.runLength ?? undefined,
	};
}

function indexedTitleToCompilationReadonly(indexedTitle: IndexedTitleV2): CompilationReadonly {
	const commonTitleLightData = getCommonTitleLightData(indexedTitle);
	return {
		...commonTitleLightData,
		workType: WorkType.Compilation,
	};
}

function indexedTitleToSupplementalReadonly(indexedTitle: IndexedTitleV2): SupplementalReadonly {
	const commonTitleLightData = getCommonTitleLightData(indexedTitle);
	return {
		...commonTitleLightData,
		workType: WorkType.Supplemental,
		runLength: indexedTitle.runLength ?? undefined,
	};
}

function indexedTitleToManifestationReadonly(indexedTitle: IndexedTitleV2): ManifestationReadonly {
	const commonTitleLightData = getCommonTitleLightData(indexedTitle);

	if (!indexedTitle.parentAbstractionWorkType?.uuid) {
		throw new Error(`Missing parent abstraction workType in indexed title ${indexedTitle.uuid}`);
	}

	const parentWorkType = getWorkType(indexedTitle.parentAbstractionWorkType.uuid);

	if (!isManifestationDerivableWorkType(parentWorkType)) {
		throw new Error(`Parent work type ${parentWorkType} is not derivable for manifestations`);
	}

	return {
		...commonTitleLightData,
		workType: WorkType.Manifestation,
		parentAbstractionWorkType: parentWorkType,
		runLength: indexedTitle.runLength ?? undefined,
		alid: indexedTitle.alid?.identifier,
		platformSpecification: indexedTitle.alid?.platformSpecification
			? {
					uuid: indexedTitle.alid.platformSpecification.uuid,
					label: indexedTitle.alid.platformSpecification.label,
				}
			: undefined,
	};
}

function indexedTitleToEditReadonly(indexedTitle: IndexedTitleV2): EditReadonly {
	const commonTitleLightData = getCommonTitleLightData(indexedTitle);

	if (!indexedTitle.parentAbstractionWorkType?.uuid) {
		throw new Error(`Missing parent abstraction workType in indexed title ${indexedTitle.uuid}`);
	}

	const parentWorkType = getWorkType(indexedTitle.parentAbstractionWorkType.uuid);

	if (!isEditDerivableWorkType(parentWorkType)) {
		throw new Error(`Parent work type ${parentWorkType} is not derivable for edits`);
	}

	return {
		...commonTitleLightData,
		workType: WorkType.Edit,
		parentAbstractionWorkType: parentWorkType,
		runLength: indexedTitle.runLength ?? undefined,
		madeForRegion: indexedTitle.madeForRegions?.map(anyToTitleLightIdLabel),
	};
}

function getCommonTitleLightData(indexedTitle: IndexedTitleV2): Omit<CommonReadonlyBase, 'parentAbstractionWorkType'> {
	const titleSearchDisplayNameFullHTML = indexedTitle.titleSearchDisplayNameFullHTML ?? undefined;
	if (!titleSearchDisplayNameFullHTML)
		throw new Error(`Missing titleSearchDisplayNameFullHTML in indexed title ${indexedTitle.uuid}`);

	const { titleSearchDisplayNameFull } = indexedTitle;

	const structuredCloneWithoutParentAbstractionWorkType = structuredClone(indexedTitle);
	delete structuredCloneWithoutParentAbstractionWorkType.parentAbstractionWorkType;
	return {
		...(structuredCloneWithoutParentAbstractionWorkType as Omit<CommonReadonlyBase, 'parentAbstractionWorkType'>),

		uuid: indexedTitle.uuid,
		titleSearchDisplayNameFull: titleSearchDisplayNameFull ?? undefined,
		releaseYear: indexedTitle.releaseYear!,
		releaseDate: indexedTitle.releaseDate ?? undefined,
		titleSearchDisplayNameFullHTML: TitleSearchDisplayFullHTMLAdapter.adapt(
			indexedTitle.titleSearchDisplayNameFullHTML,
		),
		parents: TitleLightParentAdapter.adaptArray(indexedTitle.parents ?? undefined),
		licensor: {
			label: indexedTitle.licensor.label,
			uuid: indexedTitle.licensor.uuid,
		},
	};
}

export function adaptTitleSearchDisplayNameFullHTML(
	titleSearchDisplayNameFullHtml: IndexedTitleV2['titleSearchDisplayNameFullHTML'],
): TitleSearchDisplayNameFullHTML {
	return {
		...titleSearchDisplayNameFullHtml,
		editClasses: titleSearchDisplayNameFullHtml.editClasses ?? undefined,
		editUse: titleSearchDisplayNameFullHtml.editUse ?? undefined,
		identification: titleSearchDisplayNameFullHtml.identification ?? undefined,
		madeForRegions: titleSearchDisplayNameFullHtml.madeForRegions ?? undefined,
		madeForRegionsExclude: titleSearchDisplayNameFullHtml.madeForRegionsExclude ?? undefined,
		parentTitleSearchDisplayNameFullForChild:
			titleSearchDisplayNameFullHtml.parentTitleSearchDisplayNameFullForChild ?? undefined,
		parentTitleSearchSortFullForChild: titleSearchDisplayNameFullHtml.parentTitleSearchSortFullForChild ?? undefined,
		sequenceNumber: titleSearchDisplayNameFullHtml.sequenceNumber ?? undefined,
		titleFrontEnd: titleSearchDisplayNameFullHtml.titleFrontEnd ?? undefined,
		titleSortFrontEnd: titleSearchDisplayNameFullHtml.titleSortFrontEnd ?? undefined,
		useTitleSecurity: titleSearchDisplayNameFullHtml.useTitleSecurity ?? undefined,
		versionLanguages: titleSearchDisplayNameFullHtml.versionLanguages ?? undefined,
		workTypeDetails: titleSearchDisplayNameFullHtml.workTypeDetails ?? undefined,
		workType: getWorkType(titleSearchDisplayNameFullHtml.workType.uuid),
		parentAbstractionWorkType: getWorkType(titleSearchDisplayNameFullHtml.parentAbstractionWorkType.uuid),
		parentWorkType: titleSearchDisplayNameFullHtml.parentWorkType
			? getWorkType(titleSearchDisplayNameFullHtml.parentWorkType.uuid)
			: undefined,
		parents: TitleLightParentAdapter.adaptArray(titleSearchDisplayNameFullHtml.parents ?? undefined),
	};
}
