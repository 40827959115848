import React from 'react';
import styled, { css } from 'styled-components';
import { TitleCommon } from '@warehouse/title/core';
import { TitleListItem } from './TitleListItem';
import NxLoader from '../../../../../src/components/library/NxLoader';

export function TitleList({ titles, loading = false }: TitleListProps) {
	return (
		<ResultsWrapper>
			{loading ? (
				<LoaderWrapper>
					<NxLoader />
				</LoaderWrapper>
			) : (
				<Wrapper>{titles?.map((title) => <TitleListItem title={title} key={title.uuid} />)}</Wrapper>
			)}
		</ResultsWrapper>
	);
}

interface TitleListProps {
	titles: TitleCommon[];
	loading?: boolean;
}

const ResultsWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		grid-area: resultsItems;
		padding: ${theme.spacing(0, 0)};
	`,
);

const Wrapper = styled.ul`
	margin: 0;
	overflow-y: auto;
	padding: 0;
	width: 100%;
`;

const LoaderWrapper = styled.div(
	() => css`
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: center;
		width: 100%;
	`,
);
