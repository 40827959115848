import React, { useContext, useEffect, useMemo } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { isInherited, TitleFull as Title } from '@warehouse/title/core';
import Icon from '@mdi/react';
import { mdiDelete } from '@mdi/js';
import { SideModal } from '../../../../../../components/library/SideDrawer';
import DisplayNamesTables from './DisplayNamesTables';
import RolesList from './RolesLists';
import Button from '../../../../../../components/library/Button';
import { CastAndCrewContext, castRoles, getMaxBillingBlockOrder } from '../providers/castAndCrewProvider';
import getDefaultLocale from '../../../../utils/getDefaultLocale';
import deepClone from '../../../../../../utils/deepClone';

interface AddCastAndCrewDrawerProps {
	open: boolean;
	onClose?: () => void;
	wrapperRef?: React.RefObject<HTMLDivElement>;
	peopleIndex: number;
	title?: Title;
}

export default function AddCastAndCrewDrawer({
	open,
	peopleIndex,
	onClose,
	wrapperRef,
	title,
}: AddCastAndCrewDrawerProps) {
	const { data, castData, crewData, editData, removeFromArray } = useContext(CastAndCrewContext);

	const people = useMemo(() => {
		if (!open || !data || !data.displayValue) return undefined;
		if (peopleIndex === -1) return { name: { displayNames: [] }, jobs: [] };
		return data.displayValue[peopleIndex];
	}, [open, data, peopleIndex]);

	const defaultLocale = useMemo(() => getDefaultLocale(title), [title]);
	const drawerTitle = useMemo(
		() => people?.name?.displayNames?.find((displayName) => displayName.language === defaultLocale)?.displayName || '',
		[defaultLocale, people],
	);

	const onClickDelete = async () => {
		if (title) {
			removeFromArray('people', [peopleIndex.toString()]);
			if (onClose) onClose();
		}
	};

	const computeMissingBBO = () => {
		if (!data?.displayValue?.[peopleIndex]) return;
		let nextCastBBO = getMaxBillingBlockOrder(castData);
		let nextCrewBBO = getMaxBillingBlockOrder(crewData);
		const nextPeople = deepClone(data.displayValue[peopleIndex]);
		let modified = false;
		nextPeople.jobs = nextPeople.jobs.map((job) => {
			if (!job.billingBlockOrders || job.billingBlockOrders.length === 0) {
				const nextBBO = castRoles.includes(job.jobFunction?.toLowerCase()) ? (nextCastBBO += 1) : (nextCrewBBO += 1);
				if (nextBBO !== job.billingBlockOrders?.[0]?.billingBlockOrder) modified = true;

				return {
					...job,
					billingBlockOrders: [
						{
							billingBlockOrder: nextBBO,
						},
					],
				};
			}
			return job;
		});
		if (modified) editData(`people.${peopleIndex}`, nextPeople, true);
	};

	const handleClose = () => {
		if (!isInherited({ inheritedObject: data }).isInherited) computeMissingBBO();
		if (onClose) onClose();
	};

	useEffect(() => {
		if (!data?.displayValue?.[peopleIndex]) handleClose();
	}, [peopleIndex]);

	return (
		<SideModal wrapperRef={wrapperRef} side="right" open={open} handleClose={handleClose} title={drawerTitle}>
			<PaddingWrapper>
				<ContentWrapper>
					<GlobalStyle />
					<DisplayNamesTables peopleIndex={peopleIndex} editMode displayNames={people?.name.displayNames} canAddRow />
					<RolesList people={people} peopleIndex={peopleIndex} />
				</ContentWrapper>
				<DeleteButtonWrapper>
					<Button onClick={onClickDelete} nxstyle="secondary-red">
						<Icon path={mdiDelete} size="25px" />
					</Button>
				</DeleteButtonWrapper>
			</PaddingWrapper>
		</SideModal>
	);
}

export const PaddingWrapper = styled.div(
	({ theme }) => `
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		overflow-y: auto;
		overflow-x: hidden;
		height: calc(100vh - 128px);
		padding: ${theme.spacing(3, 2)};
		gap: ${theme.spacing(2)};
		width: 700px;
		box-sizing: border-box;
	`,
);

const ContentWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		gap: ${theme.spacing(2)};
	`,
);

const DeleteButtonWrapper = styled.div(
	() => css`
		display: flex;
		justify-content: end;
	`,
);

const GlobalStyle = createGlobalStyle`
	body {
		overflow: hidden;
	}
`;
