import React from 'react';
import { DropdownV2 } from '@warehouse/shared/ui';
import useLicensors from '../../../../src/utils/hooks/licensor/useLicensors';

interface LicensorSelectorProps {
	licensor?: string;
	onLicensorChange: (licensor: string) => void;
}

export function LicensorSelector({ licensor, onLicensorChange }: LicensorSelectorProps) {
	const { data, loading } = useLicensors();

	return (
		<DropdownV2
			width={200}
			ariaLabel="Select licensor"
			withSearch
			height={40}
			disabledMargin
			loading={loading}
			options={
				data?.getLicensors?.map((_licensor) => ({
					value: _licensor.displayName,
					label: _licensor.displayName,
				})) || []
			}
			value={licensor}
			onChange={onLicensorChange}
		/>
	);
}
