import React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';
import { ReadOnlyTypography } from './ReadOnlyTypography';
import { CopyButton } from './CopyButton';

interface LabelValueCommonProps {
	label: string;
	style?: React.CSSProperties;
}

interface LabelValueCopyableProps {
	label: string;
	value: string;
	isCopyable: true;
}

interface LabelValueNotCopyableProps {
	label: string;
	value: string | React.ReactNode;
	isCopyable?: false;
}

type LabelValueProps = LabelValueCommonProps & (LabelValueCopyableProps | LabelValueNotCopyableProps);

export function LabelValue(props: LabelValueProps) {
	const { isCopyable, style, label, value } = props;

	const handleCopy = (e: any) => {
		e.stopPropagation();
		if (!isCopyable) return;
		navigator.clipboard.writeText(value).catch();
	};

	return (
		<Wrapper isCopyable={isCopyable} style={style}>
			<HeaderTypography aria-label={`ValueLabel-Label-${label}`} variant="buttonMediumMedium">
				{label}
			</HeaderTypography>
			<ValueContainer>
				{isCopyable && <CopyButton onClick={handleCopy} />}
				<ReadOnlyTypography
					aria-label={`ValueLabel-Value-${label}`}
					style={{ userSelect: isCopyable ? 'all' : undefined }}
					noWrap
				>
					{value}
				</ReadOnlyTypography>
			</ValueContainer>
		</Wrapper>
	);
}

const ValueContainer = styled.div(
	() => css`
		align-items: center;
		display: inline-flex;
		position: relative;
	`,
);

const HeaderTypography = styled(Typography)(
	() => css`
		align-items: center;
		display: flex;
		gap: 8px;
		min-height: 28px !important;
		white-space: nowrap;
	`,
);

const Wrapper = styled.div<{ isCopyable?: boolean }>`
	${({ theme, isCopyable }) => css`
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		flex-grow: 0;
		justify-content: start;
		padding: 0 ${theme.spacing(1)};
		position: relative;
		.MuiTypography-root {
			margin-right: ${theme.spacing(1)};
		}

		${isCopyable &&
		css`
			&:hover .copyIcon {
				opacity: 1;
				visibility: visible;
			}
		`}
	`}
`;
