import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { TitleCard } from '@warehouse/title/ui';
import React from 'react';
import { objectBrowserStoreSelector, useObjectBrowserStore } from '@warehouse/object-browser/domain';
import { NxObjectService } from '@warehouse/object-browser/core';
import { AssignedTitle } from '../../core/models/assigned-title';
import { LicensorColumnName, TitleColumnName } from '../../feature-object-browser/specific-column-names';

function TitleCell(props: GridRenderCellParams<AssignedTitle, string>) {
	const { row } = props;
	const nxObjectFull = useObjectBrowserStore(objectBrowserStoreSelector.selectedNxObjectFull);

	return (
		<TitleCard
			isGrayAndItalicized={
				nxObjectFull?.nxObject ? NxObjectService.isGrayedAndItalicized(nxObjectFull?.nxObject) : false
			}
			titleUuid={row.titleUuid}
			titleSearchDisplayNameFullHTML={row.titleSearchDisplayNameFullHTML}
		/>
	);
}

export const assignedTitlesColumns = [
	{
		field: LicensorColumnName,
		headerName: 'Licensor',
		width: 300,
		valueGetter: (_, row) => row.licensor.label,
		disableColumnMenu: true,
		disableReorder: true,
		pinnable: false,
		hideable: false,
		filterable: false,
		sortable: false,
		resizable: false,
	},
	{
		field: TitleColumnName,
		headerName: 'Title',
		flex: 1,
		renderCell: TitleCell,
		disableColumnMenu: true,
		disableReorder: true,
		pinnable: false,
		hideable: false,
		filterable: false,
		sortable: false,
		resizable: false,
	},
] as const satisfies readonly GridColDef<AssignedTitle>[];
