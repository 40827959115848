import { Icon } from '@mdi/react';
import { mdiMagnify } from '@mdi/js';
import { alpha, debounce } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { TextInput } from '@warehouse/shared/legacy-ui';
import { getWorkType, getWorkTypeObject } from '@warehouse/title/infra';
import { WorkType } from '@warehouse/title-shared/core';
import { multiTitleSelectorSelector, useMultiTitleSelectorStore } from '../core/multi-title-selector.store';
import { TitleSearchFilterWorkType } from './title-search-filter/TitleSearchFilterWorkType';

interface TitleSearchInputProps {
	searchPlaceholder: string;
	disabled?: boolean;
}

export function TitleSearchInput({ disabled = false, searchPlaceholder }: TitleSearchInputProps) {
	const actions = useMultiTitleSelectorStore(multiTitleSelectorSelector.actions);
	const workTypesToFilterOn = useMultiTitleSelectorStore(multiTitleSelectorSelector.workTypesToFilterOn);
	const allowedWorkTypes = useMultiTitleSelectorStore(multiTitleSelectorSelector.allowedWorkTypes);
	const inputRef = useRef<HTMLInputElement | null>(null);
	const [localSearchValue, setLocalSearchValue] = useState<string>('');

	const debouncedSetSearchValue = debounce((v) => {
		actions.setSearchValue(v);
	}, 300);

	useEffect(() => {
		// once the component has been mounted and the ref has been assigned.
		if (inputRef.current) {
			// If the input element is available, call its focus method
			// to automatically focus the input field when the component mounts.
			inputRef.current.focus();
		}
	}, []);

	return (
		<SearchWrapper>
			<ErrorInputWrapper>
				<SubWrapper hoverDisabled={disabled}>
					<InputWrapper>
						<TitleSearchFilterWorkType
							disabled={disabled}
							allowedWorkTypeUuids={allowedWorkTypes.map((workType) => getWorkTypeObject(workType).uuid)}
							onWorkTypeChange={(workTypeUuid) => {
								actions.setWorkTypesToFilterOn(workTypeUuid ? [getWorkType(workTypeUuid)] : allowedWorkTypes);
							}}
							workTypeUuid={getSelectedWorkTypeUuid(workTypesToFilterOn)}
						/>
						<TextInput
							className="search-input"
							disabledMargin
							autoFocus
							disabled={disabled}
							ref={inputRef}
							style={{
								flex: 1,
								borderTopLeftRadius: 0,
								borderBottomLeftRadius: 0,
								minWidth: 200,
								backgroundColor: 'white',
								width: '100%',
							}}
							height={40}
							onChange={(event) => {
								setLocalSearchValue(event.target.value);
								debouncedSetSearchValue(event.target.value);
							}}
							startIcon={<Icon size="16px" path={mdiMagnify} />}
							placeholder={searchPlaceholder}
							value={localSearchValue}
						/>
					</InputWrapper>
				</SubWrapper>
			</ErrorInputWrapper>
		</SearchWrapper>
	);
}

/**
 * Get the selected work type uuid from the work types to filter on.
 * If we select "All", the workTypesToFilterOn will be filled with all current allowed work types, so we return null (null = All in the component)
 * If we select a specific work type, we return the uuid of that work type.
 */
function getSelectedWorkTypeUuid(workTypesToFilterOn: WorkType[] | null): string | null {
	if (!workTypesToFilterOn) return null;
	if (workTypesToFilterOn.length > 1) return null;
	return getWorkTypeObject(workTypesToFilterOn[0]).uuid;
}

const SearchWrapper = styled.div(
	() => css`
		align-items: center;
		display: flex;
		flex: 1;
		flex-direction: column;
	`,
);

const SubWrapper = styled.div<{ error?: boolean; hoverDisabled?: boolean }>(
	({ theme, error, hoverDisabled }) => css`
		border: solid 2px transparent;
		border-radius: 8px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		width: 100%;

		${!hoverDisabled &&
		css`
			:hover {
				border: solid 2px ${theme.palette.primary.main};
			}
		`}

		${error &&
		css`
			& .input-wrapper > * {
				background-color: ${alpha(theme.palette.error.main, 0.05)} !important;
				color: ${theme.palette.error.text} !important;
			}
			border: solid 1px ${theme.palette.error.main} !important;

			border-bottom-right-radius: 0;
		`}

		& .input-wrapper:hover,
      .input-wrapper:focus-within {
			background-color: ${theme.palette.light.main};
			border-color: ${theme.palette.light.backgroundAlt};

			input {
				color: ${theme.palette.text.primary};
			}

			svg {
				color: ${theme.palette.text.secondary};
			}
		}
	`,
);

const ErrorInputWrapper = styled.div(
	() => css`
		display: flex;
		flex-direction: column;
		width: 100%;
	`,
);

const InputWrapper = styled.div(
	() => css`
		display: flex;
		min-width: 150px;
		width: 100%;

		& .search-input {
			width: 100%;
		}
	`,
);
