import { IndexableObject, PersistQueryParamsConfig } from '@warehouse/shared/util';

export function remapSessionStorageKeyPrefix<STORE extends STORE_STATE, STORE_STATE extends IndexableObject>(
	config: PersistQueryParamsConfig<STORE, STORE_STATE>,
	sessionStorageKeyPrefix: string,
): PersistQueryParamsConfig<STORE, STORE_STATE> {
	return Object.keys(config).reduce(
		(acc, key) => {
			const keyConfig = config[key];
			if (!keyConfig) return acc;

			return {
				...acc,
				[key]: {
					...keyConfig,
					sessionStorageKeyPrefix: `${sessionStorageKeyPrefix}`,
				},
			};
		},
		{} as PersistQueryParamsConfig<STORE, STORE_STATE>,
	);
}
