import compact from 'lodash/compact';
import { WatchSearchOptions } from './search-titles.repository';

export class SearchTitlesHashBuilder {
	static #buildFilteringHash(options: WatchSearchOptions): string | false {
		if (!options.filtering) return false;
		return JSON.stringify(options.filtering);
	}

	static #buildSortingHash(options: WatchSearchOptions): string | false {
		if (!options.sorting) return false;
		return JSON.stringify(options.sorting);
	}

	static #buildPaginationHash(options: WatchSearchOptions): string | false {
		if (!options.page || !options.perPage) return false;
		return `${options.page}-${options.perPage}`;
	}

	static #buildWildcardSearchHash(options: WatchSearchOptions): string | false {
		if (!options.searchValue || !options.searchFields) return false;
		return `search=${options.searchValue}&keyToSearch=${options.searchFields.join(',')}`;
	}

	static buildHash(options: WatchSearchOptions): string[] {
		return compact([
			'search-titles',
			this.#buildFilteringHash(options),
			this.#buildSortingHash(options),
			this.#buildPaginationHash(options),
			this.#buildWildcardSearchHash(options),
		]);
	}
}
