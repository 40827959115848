import { Tab } from '@mui/material';
import React from 'react';
import Tabs from '../../../../src/components/library/Tabs';

interface TabsHeaderProps {
	selectedTab: number;
	onChange: (newSelectedTab: number) => void;
	tabs: { label: string }[];
	prefix: string;
}

export function TabsHeader({ prefix, tabs, selectedTab, onChange }: TabsHeaderProps) {
	return (
		<Tabs value={selectedTab} onChange={(_event, newSelectedTab) => onChange(newSelectedTab)}>
			{tabs.map((tab, index) => (
				<Tab
					style={{ maxWidth: '100%' }}
					key={index}
					label={tab.label}
					id={`${prefix}-tab-${index}`}
					aria-controls={`${prefix}-tabpanel-${index}`}
				/>
			))}
		</Tabs>
	);
}
