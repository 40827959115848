import React from 'react';
import styled, { css } from 'styled-components';
import { Button, Tag } from '@warehouse/shared/legacy-ui';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import {
	multiTitleSelectorSelector,
	useMultiTitleSelectorStore,
} from '@warehouse/title-shared/multi-titles-selector-feature/core';
import { SelectedTitleListItem } from './SelectedTitleListItem';

export function SelectedTitles({ workTypePluralLabel }: SelectedTitlesProps) {
	const actions = useMultiTitleSelectorStore(multiTitleSelectorSelector.actions);
	const selectedTitles = useMultiTitleSelectorStore(multiTitleSelectorSelector.selectedTitles);

	const onRemoveAll = () => {
		actions.clearSelectedTitles();
	};

	return (
		<Wrapper aria-label="selected-titles">
			<TopActionsWrapper>
				<Tag $uppercase={false}>{`${selectedTitles.length} ${workTypePluralLabel} Selected`}</Tag>
				<Button nxstyle="secondary-red" endIcon={<Icon path={mdiClose} size="16px" />} onClick={onRemoveAll}>
					Remove All
				</Button>
			</TopActionsWrapper>
			<ListWrapper>
				{selectedTitles?.map((title) => <SelectedTitleListItem title={title} key={title.uuid} />)}
			</ListWrapper>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	align-items: start !important;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	grid-area: selectedItems;
	padding: 0;
	width: 100%;
`;

const TopActionsWrapper = styled.div(
	({ theme }) => css`
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		padding: ${theme.spacing(1)};

		width: 100%;
	`,
);

const ListWrapper = styled.ul`
	margin: 0;
	overflow-y: auto;
	padding: 0;
	width: 100%;
`;

interface SelectedTitlesProps {
	workTypePluralLabel: string;
}
