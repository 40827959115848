import React from 'react';
import styled, { css } from 'styled-components';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
	prefix: string;
	scrollMode?: 'page' | 'content';
}

export function TabPanel(props: TabPanelProps) {
	const { children, prefix, value, index, scrollMode = 'page', ...other } = props;

	return (
		<ChildrenTabWrap
			role="tabpanel"
			hidden={value !== index}
			id={`${prefix}-tabpanel-${index}`}
			aria-labelledby={`${prefix}-tab-${index}`}
			$scrollMode={scrollMode}
			{...other}
		>
			{value === index && children}
		</ChildrenTabWrap>
	);
}

const ChildrenTabWrap = styled.div<{ $scrollMode: 'page' | 'content' }>(
	({ $scrollMode }) => css`
		flex: 1;

		${$scrollMode === 'content' &&
		css`
			overflow: auto;
		`}

		& .MuiTab-root {
			width: 100%;
		}
	`,
);
