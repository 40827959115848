import { gql } from '@apollo/client';

export const SEARCH_TITLE_ENTITIES_V2 = gql`
	query searchTitlesV2($search: SearchInput!) {
		searchTitlesV2(search: $search) {
			documents {
				...TitleIndexedFragment
			}
			page {
				currentPage
				perPage
				totalDocument
				totalPage
			}
		}
	}
`;

export const SEARCH_TITLE_TITLE_DISPLAY_UNLIMITED_V2 = gql`
	query searchTitlesV2TitleDisplayUnlimited($search: SearchInput!) {
		searchTitlesV2(search: $search) {
			documents {
				titleDisplayUnlimited
				uuid
			}
			page {
				currentPage
				perPage
				totalDocument
				totalPage
			}
		}
	}
`;
