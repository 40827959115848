import { TableColumnSort } from '@warehouse/shared/ui';
import { components } from './inventory.openapi';

type DefinedTableColumnSort = TableColumnSort & { sort: NonNullable<TableColumnSort['sort']> };
type BackendOrderBy =
	| {
			field: components['schemas']['NxObjectSearchableFields'];
			direction: 'ASC' | 'DESC';
	  }[]
	| undefined;

export class OrderByAdapter {
	static adapt(sorting: TableColumnSort[] | undefined): BackendOrderBy {
		return sorting
			?.filter((value): value is DefinedTableColumnSort => !!value.sort)
			?.map((value) => ({
				field: value.field as components['schemas']['NxObjectSearchableFields'],
				direction: value.sort === 'asc' ? 'ASC' : ('DESC' as 'ASC' | 'DESC'),
			}));
	}
}
