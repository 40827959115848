import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { NxObject, NxObjectService } from '@warehouse/object-browser/core';
import { TitleCard } from '@warehouse/title/ui';

export function TitleCell(props: GridRenderCellParams<NxObject, string>) {
	const { row, value } = props;

	if (typeof value === 'string') return <div className="no-title-card">{value}</div>;
	return (
		<TitleCard
			isGrayAndItalicized={NxObjectService.isGrayedAndItalicized(row)}
			titleUuid={row.assignedTitles[0].titleUuid}
			titleSearchDisplayNameFullHTML={row.assignedTitles[0].titleSearchDisplayNameFullHTML}
		/>
	);
}
