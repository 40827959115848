import React, { useEffect, useMemo } from 'react';
import { TitleCommon, TitleFull } from '@warehouse/title/core';
import { useAddChildrenMutation } from '@warehouse/title/domain';
import { getWorkTypeUuid } from '@warehouse/title/infra';

import {
	multiTitleSelectorSelector,
	MultiTitleSelectorStoreProvider,
	useMultiTitleSelectorStore,
} from '@warehouse/title-shared/multi-titles-selector-feature/core';
import { MultiTitlesSelector, TitleSearchInput } from '@warehouse/title-shared/multi-titles-selector-feature/ui';
import { RelationshipTypeSelectorContainer } from './ui/RelationshipTypeSelectorContainer';

export function AddRelationshipChildrenPrompt(props: AddChildrenPromptProps) {
	return (
		<MultiTitleSelectorStoreProvider>
			<AddRelationshipChildrenPromptWithStoreProvided {...props} />
		</MultiTitleSelectorStoreProvider>
	);
}

function AddRelationshipChildrenPromptWithStoreProvided({
	searchAndSelectedPluralLabel,
	open,
	onClose,
	showHiddenTitles,
	textHeader,
	title,
}: AddChildrenPromptProps) {
	const paginationStore = useMultiTitleSelectorStore((state) => state);
	const selectableTitles = useMultiTitleSelectorStore(multiTitleSelectorSelector.selectableTitles);
	const isPending = useMultiTitleSelectorStore(multiTitleSelectorSelector.isPending);
	const actions = useMultiTitleSelectorStore(multiTitleSelectorSelector.actions);
	const showHiddenTitlesFromStore = useMultiTitleSelectorStore(multiTitleSelectorSelector.showHiddenTitles);
	const licensorLabelToFilterOn = useMemo(() => title.readonly.licensor.label, [title]);
	const licensorLabelToFilterOnFromStore = useMultiTitleSelectorStore(
		multiTitleSelectorSelector.licensorLabelToFilterOn,
	);
	const relationshipTypeFromStore = useMultiTitleSelectorStore(multiTitleSelectorSelector.relationshipType);

	const { mutate, loading } = useAddChildrenMutation({
		options: {
			onCompleted: () => {
				onClose();
				actions.clearSelectedTitles();
			},
		},
	});

	if (!title || !title.readonly.workType) {
		return null;
	}

	const handleSubmit = (selectedTitles: TitleCommon[]) => {
		mutate({
			titleUuid: title.uuid,
			workTypeUuid: getWorkTypeUuid(title.readonly.workType),
			children: selectedTitles.map((_title) => ({
				uuid: _title.uuid,
				workType: _title.readonly.workType ?? undefined,
			})),
			relationshipType: relationshipTypeFromStore,
		});
	};

	useEffect(() => {
		if (showHiddenTitlesFromStore !== showHiddenTitles) actions.setShowHiddenTitles(showHiddenTitles ?? false);
	}, [actions, showHiddenTitles, showHiddenTitlesFromStore]);

	useEffect(() => {
		if (licensorLabelToFilterOn && licensorLabelToFilterOnFromStore !== licensorLabelToFilterOn)
			actions.setLicensorLabelToFilterOn(licensorLabelToFilterOn);
	}, [actions, licensorLabelToFilterOn, licensorLabelToFilterOnFromStore]);

	return (
		<MultiTitlesSelector
			isOpen={open}
			onClose={onClose}
			selectableTitles={selectableTitles}
			textHeader={textHeader}
			searchAndSelectedPluralLabel={searchAndSelectedPluralLabel}
			paginationStore={paginationStore}
			onSubmit={handleSubmit}
			isSubmitButtonDisabled={!relationshipTypeFromStore}
			loading={isPending || loading}
			searchInputsSlot={
				<>
					<RelationshipTypeSelectorContainer title={title.readonly} />
					<TitleSearchInput
						disabled={!relationshipTypeFromStore}
						searchPlaceholder={`Search ${searchAndSelectedPluralLabel}`}
					/>
				</>
			}
		/>
	);
}

export interface AddChildrenPromptProps {
	title: TitleFull;
	textHeader: string;
	showHiddenTitles?: boolean;
	open: boolean;
	searchAndSelectedPluralLabel: string;
	onClose: () => void;
}
