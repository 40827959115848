import { objectBrowserStoreSelector, useObjectBrowserStore } from '@warehouse/object-browser/domain';
import { LabelValue } from '@warehouse/shared/ui';
import { filesize } from 'filesize';
import React from 'react';
import styled, { css } from 'styled-components';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { timeToString } from '../../../../src/components/library/DatePicker/DatePicker';

const OnlineIcon = <CheckIcon aria-label="Online Icon" fontSize="small" />;
const OfflineIcon = <CloseIcon aria-label="Offline Icon" fontSize="small" />;

export function GeneralInfos() {
	const selectedNxObject = useObjectBrowserStore(objectBrowserStoreSelector.selectedNxObject);
	const selectedNxObjectFull = useObjectBrowserStore(objectBrowserStoreSelector.selectedNxObjectFull);

	const objToDisplay = selectedNxObjectFull?.nxObject ?? selectedNxObject;

	if (!objToDisplay) return null;

	return (
		<div>
			<Wrapper>
				<LabelValue isCopyable label="ID" value={objToDisplay.uuid} />
				<LabelValue label="Bucket" value={objToDisplay.bucketId} />
				<LabelValue label="Storage" value={'storageTier' in objToDisplay ? objToDisplay.storageTier.label : '--'} />
				<LabelValue label="Online" value={objToDisplay.online ? OnlineIcon : OfflineIcon} />
				<LabelValue
					label="File Size"
					value={'fileSize' in objToDisplay ? filesize(objToDisplay.fileSize, { round: 1 }) : '--'}
				/>
				<LabelValue
					label="Last Updated"
					value={
						'lastModifiedAt' in objToDisplay && objToDisplay.lastModifiedAt
							? timeToString(objToDisplay.lastModifiedAt)
							: '--'
					}
				/>
				<LabelValue
					label="Upload Organization"
					value={'uploadedByOrganization' in objToDisplay ? objToDisplay.uploadedByOrganization : '--'}
				/>
				<LabelValue label="Upload User" value={'uploadedByUser' in objToDisplay ? objToDisplay.uploadedByUser : '--'} />
			</Wrapper>
			<LabelValue style={{ marginTop: 16 }} isCopyable label="S3 URI" value={objToDisplay.s3Uri} />
		</div>
	);
}

const Wrapper = styled.div(
	() => css`
		display: grid;
		grid-row-gap: 16px;
		grid-template-columns: 34% 30% 18% 18%;
	`,
);
