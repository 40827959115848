import { TitleSearchDisplayFullHTMLAdapter } from '@warehouse/title/infra';
import { AssignedTitle } from '../core/models/assigned-title';
import { components } from './inventory.openapi';

export class AssignedTitleAdapter {
	static adapt(assignedTitle: components['schemas']['AssignedTitle']): AssignedTitle {
		return {
			titleUuid: assignedTitle.titleUuid,
			titleSearchDisplayNameFullHTML: TitleSearchDisplayFullHTMLAdapter.adapt(
				assignedTitle.titleSearchDisplayNameFullHTML,
			),
			titleSearchSortFull: assignedTitle.titleSearchSortFull,
			titleSearchDisplayNameFull: assignedTitle.titleSearchDisplayNameFull,
			licensor: assignedTitle.licensor,
		};
	}

	static adaptArray(assignedTitles: components['schemas']['AssignedTitle'][]): AssignedTitle[] {
		return assignedTitles.map((assignedTitle) => AssignedTitleAdapter.adapt(assignedTitle));
	}
}
