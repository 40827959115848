import React from 'react';
import styled, { css } from 'styled-components';
import { Checkbox, Chip, Tag, FormControlLabel } from '@warehouse/shared/legacy-ui';
import { TitleCommon } from '@warehouse/title/core';
import {
	multiTitleSelectorSelector,
	useMultiTitleSelectorStore,
} from '@warehouse/title-shared/multi-titles-selector-feature/core';

export function TitleListActionsTopBar({ titles, totalCount }: TitleListActionsTopBarProps) {
	const actions = useMultiTitleSelectorStore(multiTitleSelectorSelector.actions);
	const allTitlesInThisPageAreSelected = useMultiTitleSelectorStore(
		multiTitleSelectorSelector.areTitlesSelected(titles),
	);
	const selectedTitlesIds = useMultiTitleSelectorStore(multiTitleSelectorSelector.selectedTitles);

	const onChange = () => {
		if (allTitlesInThisPageAreSelected) actions.unselectTitles(titles);
		else actions.selectTitles(titles);
	};

	return (
		<TopActionsWrapper>
			<div>
				<FormControlLabel
					control={<Checkbox checked={allTitlesInThisPageAreSelected} />}
					label="Select All"
					onChange={onChange}
				/>
				<Chip label={`${selectedTitlesIds.length} items selected`} height={24} />
			</div>
			<Tag height={24} $uppercase={false}>{`${totalCount} Records Found `}</Tag>
		</TopActionsWrapper>
	);
}

interface TitleListActionsTopBarProps {
	titles: TitleCommon[];
	totalCount: number;
}

const TopActionsWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		box-sizing: border-box;
		display: flex;
		grid-area: resultsItems;
		justify-content: space-between;
		margin-top: ${theme.spacing(1)};
		width: 100%;
	`,
);
