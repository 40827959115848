import { TitleCard } from '@warehouse/title/ui';
import { CommonReadonly, RelationshipType } from '@warehouse/title/core';
import React from 'react';
import { Divider, Typography } from '@mui/material';
import styled from 'styled-components';
import { getWorkTypeObject } from '@warehouse/title/infra';
import { WorkType } from '@warehouse/title-shared/core';
import { RelationshipModeRelationshipTypeSelectorContainer } from './RelationshipModeRelationshipTypeSelectorContainer';
import { LicensorSelectorReadonly } from './LicensorSelectorReadonly';
import { RelationshipModeWorkTypeSelectorContainer } from './RelationshipModeWorkTypeSelectorContainer';
import { TitleInfoWrapper } from './styles';
import WorkTypeDetailsSelector from './WorkTypeDetailsSelector';
import { TitleCardService } from '../../../../libs/title/ui/title-card/title-card.service';

interface RelationshipModeHeaderProps {
	parentTitle: CommonReadonly;
	childWorkType?: WorkType;
	workTypeDetail?: string;
	setWorkTypeDetail: (workTypeDetail: string | undefined) => void;
	onSelectChildWorkType: (workType: WorkType | undefined) => void;
	relationshipType?: RelationshipType;
	onSelectRelationshipType: (relationshipType: RelationshipType) => void;
}

export function RelationshipModeHeader({
	parentTitle,
	childWorkType,
	onSelectChildWorkType,
	relationshipType,
	onSelectRelationshipType,
	workTypeDetail,
	setWorkTypeDetail,
}: RelationshipModeHeaderProps) {
	const showWorkTypeDetailsSelector =
		!!childWorkType && [WorkType.Movie, WorkType.Compilation, WorkType.Supplemental].includes(childWorkType);

	const onSelectChildWorkTypeOverride = (newWorkType: WorkType | undefined) => {
		onSelectChildWorkType(newWorkType);
		setWorkTypeDetail(undefined);
	};

	const onSelectRelationshipTypeOverride = (newRelationshipType: RelationshipType) => {
		onSelectRelationshipType(newRelationshipType);
		onSelectChildWorkTypeOverride(undefined);
	};

	return (
		<HeaderContainer>
			<RelationshipModeRelationshipTypeSelectorContainer
				parentTitle={parentTitle}
				selected={relationshipType}
				onSelect={onSelectRelationshipTypeOverride}
			/>
			<Divider orientation="vertical" />
			<TitleInfoWrapper>
				<Typography variant="s2Medium" color="text.primary">
					Parent Title:
				</Typography>
				<TitleCard {...TitleCardService.getTitleCardPropsFromTitleCommonReadonly(parentTitle)} variant="colored" />
			</TitleInfoWrapper>
			<Divider orientation="vertical" />
			<LicensorSelectorReadonly licensor={parentTitle.licensor.label} />
			<Divider orientation="vertical" />
			<RelationshipModeWorkTypeSelectorContainer
				relationshipType={relationshipType}
				onSelect={onSelectChildWorkTypeOverride}
				selected={childWorkType}
			/>
			<Divider orientation="vertical" />
			{showWorkTypeDetailsSelector && (
				<WorkTypeDetailsSelector
					workTypeName={getWorkTypeObject(childWorkType).label}
					workTypeDetails={workTypeDetail}
					setWorkTypeDetails={setWorkTypeDetail}
				/>
			)}
		</HeaderContainer>
	);
}

const HeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;
