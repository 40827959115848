import { AssignmentStatus } from '@warehouse/object-browser/core';
import { UnreachableError } from '@warehouse/shared/errors';
import { assertUnreachable } from '@warehouse/shared/util';
import { components } from './inventory.openapi';

export class AssignmentStatusAdapter {
	static adapt(status: components['schemas']['NxObjectFolder']['assignmentStatus']): AssignmentStatus {
		switch (status) {
			case 'assigned':
				return AssignmentStatus.Assigned;
			case 'unmanaged':
				return AssignmentStatus.Unmanaged;
			case 'unassigned':
				return AssignmentStatus.Unassigned;
			default:
				assertUnreachable(status, `Cannot map ${status} to the AssignmentStatus enum`);
				throw new UnreachableError(`Cannot map ${status} to the AssignmentStatus enum`);
		}
	}
}
