import { StateCreator } from 'zustand';
import { PaginatorStore } from '@warehouse/shared/ui';
import { nxObjectRepositorySingleton } from '@warehouse/object-browser/infra';
import { Subscription } from 'rxjs';
import {
	InfraResourceIdentifier,
	NxObjects,
	SearchMode,
	TableVisibilityUtils,
	WatchNxObjectsParams,
	WatchSearchResult,
} from '@warehouse/object-browser/core';
import { ObjectBrowserTableManagerStore } from '../../feature-object-browser/domain/object-browser-table-manager.store';
import { gridFilterModelToFilterQuery } from '../../../shared/ui/table/muix.adapter';

export interface WildCardSearchStore {
	searchQuery: string;
	searchMode: SearchMode;
	wildCardSearchItems: NxObjects;
	isWildCardSearchPending: boolean;
	actions: {
		setSearchQuery: (searchQuery: string) => void;
		setSearchMode: (searchMode: SearchMode) => void;
		subscribeToSearch: () => void;
		unsubscribeToSearch: () => void;
	};
}

interface FactoryOptions {
	getPaginatorStore: () => PaginatorStore;
	getTableManagerStore: () => ObjectBrowserTableManagerStore;
	getResourceIdentifier: () => InfraResourceIdentifier | undefined;
	getAssignedTitleUuid: () => string | undefined;
	onEnterSearchMode: () => void;
	onExitSearchMode: () => void;
	onWatchNxObjectsResult: () => void;
	onSearchQueryChange: (newSearchQuery: string) => void;
	onSearchModeChange: (newSearchMode: SearchMode) => void;
}

export const createWildCardSearchSliceFactory =
	({
		getPaginatorStore,
		getTableManagerStore,
		getResourceIdentifier,
		onEnterSearchMode,
		onExitSearchMode,
		onWatchNxObjectsResult,
		getAssignedTitleUuid,
		onSearchQueryChange,
		onSearchModeChange,
	}: FactoryOptions): StateCreator<WildCardSearchStore> =>
	(set, get) => {
		const repo = nxObjectRepositorySingleton.get();
		let watchWildCardSearchSubscription: Subscription | undefined;

		const handleSearchRepositoryResult = (res: WatchSearchResult) => {
			if (res.isPending) {
				set({ isWildCardSearchPending: true });
			} else if (res.error) {
				// TODO: decide upon a better error-handling strategy
				console.error(`An error has occurred: ${res.error}`);
			} else {
				set({
					isWildCardSearchPending: false,
					wildCardSearchItems: res.data.documents || [],
				});
				getPaginatorStore().actions.setTotalCount(res.data.pagination.totalDocument);
				getPaginatorStore().actions.setTotalPages(res.data.pagination.totalPage);
				onWatchNxObjectsResult();
			}
		};

		const subscribeToWildCardSearch = () => {
			if (watchWildCardSearchSubscription) watchWildCardSearchSubscription.unsubscribe();
			const paginatorState = getPaginatorStore();
			const tableManagerState = getTableManagerStore();
			const resourceIdentifier = getResourceIdentifier();
			const { searchMode } = get();

			const commonParams: Omit<WatchNxObjectsParams, 'resourceIdentifier' | 'searchMode'> = {
				filters: {
					sort: tableManagerState.sort,
					pagination: {
						page: paginatorState.page,
						perPage: paginatorState.perPage,
					},
					filter: gridFilterModelToFilterQuery(tableManagerState.filter),
					textSearch: {
						value: get().searchQuery,
						keysToSearch: TableVisibilityUtils.toKeysToSearch(tableManagerState.visibility),
					},
				},
				options: {
					pollInterval: 3000,
				},
				assignedTitleUuid: getAssignedTitleUuid(),
			};

			if (searchMode === SearchMode.THIS_FOLDER) {
				if (!resourceIdentifier) {
					console.error('Cannot search in this folder without a folder resource identifier');
					return;
				}
				watchWildCardSearchSubscription = repo
					.watchNxObjects({
						...commonParams,
						resourceIdentifier,
						searchMode: SearchMode.THIS_FOLDER,
					})
					.subscribe(handleSearchRepositoryResult);
			} else {
				watchWildCardSearchSubscription = repo
					.watchNxObjects({
						...commonParams,
						searchMode: SearchMode.EVERYWHERE,
					})
					.subscribe(handleSearchRepositoryResult);
			}
		};

		const enterSearchMode = (newSearchQuery: string) => {
			set({ searchQuery: newSearchQuery });
			subscribeToWildCardSearch();
			getPaginatorStore().internalActions.resetPagination();
			onEnterSearchMode();
		};

		const exitSearchMode = () => {
			set({
				searchQuery: '',
				wildCardSearchItems: [],
				isWildCardSearchPending: false,
				searchMode: SearchMode.THIS_FOLDER,
			});
			getPaginatorStore().internalActions.resetPagination();
			watchWildCardSearchSubscription?.unsubscribe();
			onExitSearchMode();
		};

		return {
			searchMode: SearchMode.THIS_FOLDER,
			searchQuery: '',
			isWildCardSearchPending: false,
			wildCardSearchItems: [],
			actions: {
				setSearchQuery: (searchQuery: string) => {
					const oldSearchQuery = get().searchQuery.trim();
					const newSearchQuery = searchQuery.trim();

					if (oldSearchQuery.length === 0 && newSearchQuery.length === 0) return;

					if (isEnteringSearchMode(oldSearchQuery, newSearchQuery)) {
						enterSearchMode(newSearchQuery);
					} else if (isExitingSearchMode(oldSearchQuery, newSearchQuery)) {
						exitSearchMode();
					} else {
						// We already were in the search, and we don't exit, so we just update the search query
						set({ searchQuery: newSearchQuery });
						subscribeToWildCardSearch();
						getPaginatorStore().internalActions.resetPagination();
						onSearchQueryChange(newSearchQuery);
					}
				},
				setSearchMode: (searchMode: SearchMode) => {
					set({ searchMode, wildCardSearchItems: [] });
					onSearchModeChange(searchMode);
					getPaginatorStore().internalActions.resetPagination();
					subscribeToWildCardSearch();
				},
				subscribeToSearch() {
					subscribeToWildCardSearch();
				},
				unsubscribeToSearch() {
					if (watchWildCardSearchSubscription) watchWildCardSearchSubscription.unsubscribe();
				},
			},
		};
	};

function isEnteringSearchMode(oldSearch: string, newSearch: string): boolean {
	return oldSearch.length === 0 && newSearch.length > 0;
}

function isExitingSearchMode(oldSearch: string, newSearch: string): boolean {
	return oldSearch.length > 0 && newSearch.length === 0;
}

export const wildCardSearchSelector = {
	actions: (state: WildCardSearchStore) => state.actions,
	searchQuery: (state: WildCardSearchStore) => state.searchQuery,
	searchMode: (state: WildCardSearchStore) => state.searchMode,
};
