import React, { useCallback, useEffect } from 'react';
import {
	AssignTitlesPrompt,
	AssignTitleStoreProvider,
	assignTitleStoreSelector,
	useAssignTitleStore,
} from '@warehouse/object-browser/feature-assign-titles';
import { objectBrowserStoreSelector, useObjectBrowserStore } from '@warehouse/object-browser/domain';
import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { NxObjectService } from '@warehouse/object-browser/core';
import { assignedTitlesColumns } from './columns';
import { AssignedTitle } from '../../core/models/assigned-title';
import { Footer } from './Footer';
import { CustomDataGridPro } from '../../../shared/ui/table/CustomDataGridPro';
import { UnmanagedOverlay } from './UnamangedOverlay';
import { UnassignedOverlay } from './UnassignedOverlay';
import { LicensorColumnName } from '../../feature-object-browser/specific-column-names';

export function AssignedTitles() {
	return (
		<AssignTitleStoreProvider>
			<AssignedTitlesWithStoreProvided />
		</AssignTitleStoreProvider>
	);
}

function AssignedTitlesWithStoreProvided() {
	const nxObjectFull = useObjectBrowserStore(objectBrowserStoreSelector.selectedNxObjectFull);
	const isNxObjectFullUnmanaged = useObjectBrowserStore(objectBrowserStoreSelector.isNxObjectFullUnmanaged);
	const { setNxObjectFull } = useObjectBrowserStore(objectBrowserStoreSelector.actions);
	const assignTitlesActions = useAssignTitleStore(assignTitleStoreSelector.actions);
	const selectedTitleUuidsToUnassign = useAssignTitleStore(assignTitleStoreSelector.selectedTitleUuidsToUnassign);

	const footer = useCallback(
		() => (
			<Footer onTitlesUnassigned={(newNxObjectFull) => setNxObjectFull(newNxObjectFull)} nxObjectFull={nxObjectFull} />
		),
		[setNxObjectFull, nxObjectFull],
	);

	const getCellClassName = useCallback(
		(params: GridCellParams) =>
			params.field === LicensorColumnName &&
			nxObjectFull?.nxObject &&
			NxObjectService.isGrayedAndItalicized(nxObjectFull?.nxObject)
				? 'inherited'
				: '',
		[nxObjectFull],
	);

	// When the nx object changes, we clear the data-grid selection
	useEffect(() => {
		assignTitlesActions.clearSelectedTitlesToUnassign();
	}, [assignTitlesActions, nxObjectFull?.nxObject.uuid]);

	return (
		<Wrapper>
			<Typography variant="buttonMediumMedium">Titles</Typography>
			<TableWrapper>
				<CustomDataGridPro<AssignedTitle>
					$variant="inline"
					getRowId={(row) => row.titleUuid}
					columns={assignedTitlesColumns}
					rows={nxObjectFull?.nxObject?.assignedTitles ?? []}
					onRowSelectionModelChange={(newSelection) => {
						assignTitlesActions.setSelectedTitlesToUnassign(newSelection as string[]);
					}}
					rowSelectionModel={selectedTitleUuidsToUnassign}
					checkboxSelection
					disableRowSelectionOnClick
					getCellClassName={getCellClassName}
					loading={!nxObjectFull}
					slots={{
						footer,
						noRowsOverlay: isNxObjectFullUnmanaged ? UnmanagedOverlay : UnassignedOverlay,
					}}
				/>
				{nxObjectFull && (
					<AssignTitlesPrompt
						nxObjectFull={nxObjectFull}
						onTitlesSubmitted={(newNxObjectFull) => setNxObjectFull(newNxObjectFull)}
					/>
				)}
			</TableWrapper>
		</Wrapper>
	);
}

const Wrapper = styled.div(
	({ theme }) => css`
		margin-bottom: ${theme.spacing(4)};
		margin-top: ${theme.spacing(2)};
		padding: ${theme.spacing(1)};
	`,
);

const TableWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		margin-top: ${theme.spacing(2)};
		min-height: 100px;
	`,
);
