import {
	ChildToAdd,
	RelationshipType,
	TitleAddChildrenMutation,
	TitleAddChildrenMutationOptions,
} from '@warehouse/title/core';
import { CompilationObjectEntry } from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/TitleMetadata';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { AddParentsResult } from '@warehouse/graphql';
import { useEditTitleV2 } from '../../../src/utils/hooks/titles/useEditTitle';
import useWorkTypes from '../../../src/utils/hooks/titles/useWorkTypes';

import { useAddParents } from '../../../src/utils/hooks/titles/useAddParents';
import { titleRepositorySingleton } from './title.repository.gql';
import { getWorkTypeObject } from './worktype';

export function useAddChildrenMutationImpl({
	options,
}: {
	options?: TitleAddChildrenMutationOptions;
}): ReturnType<TitleAddChildrenMutation> {
	const { compilationUuid } = useWorkTypes();
	const compilationAddChildren = useCompilationAddChildrenMutation(options);
	const titleAddChildren = useTitleAddChildrenMutation(options);

	return {
		error: compilationAddChildren.error || titleAddChildren.error,
		loading: compilationAddChildren.loading || titleAddChildren.loading,
		mutate: ({ workTypeUuid, titleUuid, children, relationshipType }) =>
			workTypeUuid === compilationUuid() && relationshipType === RelationshipType.EntryOf
				? compilationAddChildren.mutate(titleUuid, children)
				: titleAddChildren.mutate(titleUuid, children, relationshipType ?? undefined),
	};
}

function useCompilationAddChildrenMutation(options?: TitleAddChildrenMutationOptions) {
	const [getTitleLoading, setGetTitleLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const addChildrenMutation = useEditTitleV2({
		onCompleted: () => {
			enqueueSnackbar('Children added successfully', {
				variant: 'success',
			});
			options?.onCompleted?.();
		},
		onError: () => {
			enqueueSnackbar('Failed to add children', {
				variant: 'error',
			});
		},
	});
	const repo = titleRepositorySingleton.get();

	return {
		loading: addChildrenMutation.isPending || getTitleLoading,
		mutate: async (titleUuid: string, children: ChildToAdd[]) => {
			setGetTitleLoading(true);
			const result = await repo.getTitle(titleUuid);
			setGetTitleLoading(false);
			const existingCompilationEntries = result.metadata.coreMetadata.compilationObject?.compilationEntries;

			return addChildrenMutation.mutate({
				editTitleInput: {
					edits: [
						{
							path: 'coreMetadata.compilationObject.compilationEntries',
							value: JSON.stringify(
								makeCompilationEntriesUnique([
									...(existingCompilationEntries || []),
									...childrenToCompilationEntries(children),
								]),
							),
						},
					],
					type: 'explicit',
					uuid: titleUuid,
				},
			});
		},
		error: addChildrenMutation.error,
	};
}

function useTitleAddChildrenMutation(options?: TitleAddChildrenMutationOptions) {
	const { enqueueSnackbar } = useSnackbar();
	const [addParents, { loading, error }] = useAddParents({
		onSuccess: (successfulResults: AddParentsResult[]) => {
			const count = successfulResults.length;
			const childWord = count === 1 ? 'child' : 'children';
			enqueueSnackbar(`${count} ${childWord} added successfully`, {
				variant: 'success',
			});
			options?.onCompleted?.();
		},
		onError: (singleError) => {
			enqueueSnackbar(singleError, {
				variant: 'error',
			});
		},
	});

	return {
		loading,
		mutate: async (titleUuid: string, children: ChildToAdd[], relationshipType?: string) => {
			if (!relationshipType) throw new Error('RelationshipType should be provided for non-compilation parents');
			const relationships = children.map(({ uuid: childUuid }) => ({
				parentUuid: titleUuid,
				childUuid,
				relationshipType,
			}));

			return addParents({
				variables: {
					input: {
						relationships,
					},
				},
			});
		},
		error,
	};
}

function makeCompilationEntriesUnique(compilationEntries: CompilationObjectEntry[]) {
	return compilationEntries.filter(
		(compilationEntry, index, self) =>
			self.findIndex((entry) => entry.contentId === compilationEntry.contentId) === index,
	);
}

function childrenToCompilationEntries(children: ChildToAdd[]) {
	return children.map(({ uuid, workType }) => ({
		contentId: uuid,
		compilationEntryClass: workType ? getWorkTypeObject(workType).uuid : undefined,
	}));
}
