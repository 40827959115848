import React from 'react';
import { objectBrowserStoreSelector, useObjectBrowserStore } from '@warehouse/object-browser/domain';
import { Breadcrumbs } from '@warehouse/shared/ui';
import { StoreResourceIdentifier } from '@warehouse/object-browser/core';
import styled, { css } from 'styled-components';
import { BucketIcon, FolderIcon } from './icons';
import { FileIcon } from './icons/file';
import { FromTitle, ObjectBrowserLinkBuilder } from '../domain/object-browser-link-builder';

export function ObjectBrowserBreadcrumbs() {
	const breadcrumbsItems = useObjectBrowserStore(objectBrowserStoreSelector.breadcrumbsItems) || [];
	const isBreadcrumbsItemsPending = useObjectBrowserStore(objectBrowserStoreSelector.isBreadcrumbsItemsPending);
	const showBreadcrumb = useObjectBrowserStore(objectBrowserStoreSelector.showBreadcrumb);
	const status = useObjectBrowserStore(objectBrowserStoreSelector.status);
	const title = useObjectBrowserStore(objectBrowserStoreSelector.title);
	const actions = useObjectBrowserStore(objectBrowserStoreSelector.actions);

	if (!showBreadcrumb) return null;

	return (
		<BreadcrumbsWrapper>
			<Breadcrumbs
				maxItems={5}
				loading={isBreadcrumbsItemsPending}
				items={breadcrumbsItems.map((item) => ({
					to: (() => {
						if (title && !title.readonly.titleSearchDisplayNameFull)
							console.error(`titleSearchDisplayNameFull is missing in title ${title.uuid}`);
						return getItemTo(
							item.resourceIdentifier,
							title
								? {
										licensorLabel: title.readonly.licensor.label,
										// titleSearchDisplayNameFull not being available is very edge case, and in that rare case we are fine
										// filtering on empty string and showing all the titles
										titleSearch: title.readonly.titleSearchDisplayNameFull ?? '',
									}
								: undefined,
						);
					})(),
					label: item.label,
					icon: getItemIcon(item.type),
					onClick:
						status === 'title-objects-tab-ready'
							? undefined
							: () => {
									if (item.resourceIdentifier) actions.setResourceIdentifier(item.resourceIdentifier);
									// If we are on search mode, clicking on breadcrumb will clear the search query
									actions.setSearchQuery('');
								},
				}))}
				openNewTab={status === 'title-objects-tab-ready'}
			/>
		</BreadcrumbsWrapper>
	);
}

function getItemTo(resourceIdentifier: StoreResourceIdentifier | undefined, fromTitle?: FromTitle) {
	if (!resourceIdentifier) return undefined;

	const titleUuidArgs = fromTitle
		? {
				forwardSearchParams: false,
				fromTitle,
			}
		: {
				forwardSearchParams: true,
			};

	if (resourceIdentifier?.type === 'bucket') {
		return ObjectBrowserLinkBuilder.buildBucketLink({ bucketId: resourceIdentifier.bucketId, ...titleUuidArgs });
	}
	if (resourceIdentifier?.type === 'folderUuid') {
		return ObjectBrowserLinkBuilder.buildFolderLink({ folderUuid: resourceIdentifier.folderUuid, ...titleUuidArgs });
	}
	return undefined;
}

function getItemIcon(type: 'bucket' | 'folder' | 'file') {
	switch (type) {
		case 'bucket':
			return <BucketIcon />;
		case 'folder':
			return <FolderIcon />;
		case 'file':
			return <FileIcon />;
		default:
			return undefined;
	}
}

const BreadcrumbsWrapper = styled.div(
	({ theme }) => css`
		border-bottom: solid 1px ${theme.palette.light.backgroundAlt};
		padding: 8px 20px;
	`,
);
