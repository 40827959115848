import { gql } from '@apollo/client';

export const GET_LICENSORS = gql`
	query GetLicensors {
		getLicensors {
			displayName
			default
		}
	}
`;
