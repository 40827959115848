import React from 'react';
import styled, { css } from 'styled-components';
import { CombinedWorkType, isParentAbstractionWorkTypeAndWorkType, WorkType } from '@warehouse/title-shared/core';
import { alpha } from '@mui/material';
import { Color, getIconByEntityNameAndTitleType } from '../../../src/assets/icons';

interface TitleIconProps {
	combinedWorkType: CombinedWorkType;
	size?: number;
	iconSize?: number;
	variant: 'colored' | 'default';
	radius?: 'default' | 'full';
	isDimmed?: boolean;
}

export function TitleIcon({
	combinedWorkType,
	radius = 'default',
	iconSize,
	size = 48,
	variant = 'default',
	isDimmed = false,
}: TitleIconProps) {
	const icon = getIconByEntityNameAndTitleType(combinedWorkType, Color.Black);
	const bgColor = getBackgroundColorByTitleType(
		isParentAbstractionWorkTypeAndWorkType(combinedWorkType) ? combinedWorkType.workType : combinedWorkType,
	);

	return (
		<ImageWrapper
			size={size}
			iconSize={iconSize}
			radius={radius}
			bgColor={bgColor}
			noBackground={variant === 'default'}
		>
			<img style={{ opacity: isDimmed ? 0.5 : 1 }} src={icon || undefined} alt="eidrIcon" />
		</ImageWrapper>
	);
}

const ImageWrapper = styled.div<{
	noBackground: boolean;
	size: number;
	bgColor: string;
	iconSize?: number;
	radius: 'default' | 'full';
}>(
	({ noBackground, bgColor, size, radius, iconSize }) => css`
		align-items: center;

		${!noBackground &&
		css`
			background-color: ${alpha(bgColor, 0.2)};
		`};
		border-radius: ${radius === 'default' ? '8px 0 0 8px' : '8px'};
		display: flex;
		height: ${size}px;
		justify-content: center;
		min-width: ${size}px;
		width: ${size}px;

		& > img {
			height: ${iconSize ? `${iconSize}px` : `${size / 2}px`};
			width: ${iconSize ? `${iconSize}px` : `${size / 2}px`};
		}
	`,
);

function getBackgroundColorByTitleType(workType: WorkType) {
	switch (workType) {
		case WorkType.Edit:
			return '#2196F3';
		case WorkType.Manifestation:
			return '#8C271E';
		case WorkType.Movie:
		case WorkType.Series:
		case WorkType.Episode:
		case WorkType.Season:
		case WorkType.Compilation:
		default:
			return '#F27E30';
	}
}
