import React, { useMemo, useState } from 'react';
import { AspectRatio } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';
import { titlePaths, isInherited, OptionalNullInherited } from '@warehouse/title/core';
import { WorkType } from '@warehouse/title-shared/core';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { BottomActionClear, DropdownV2, ReadOnlyTypography } from '@warehouse/shared/ui';
import AspectRatioJson from '../../../../src/assets/json-administration-profiles/availableAspectRatio.json';
import TitleEditableField from '../../../../src/components/titles/TitleEditableField';
import useTitleAutoSave, {
	optionalNullInheritedToOptionalInherited,
} from '../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';
import UseMemoJsonPath from '../../../../src/views/title/hooks/useMemoJsonPath';
import { useUpdateValue } from '../../domain/useUpdateValue';

interface Option {
	value: string;
	label: string;
}

export function AspectRatioField() {
	const {
		readOnlyValue: readonlyAspectRatio,
		setValue: setAspectRatio,
		value: aspectRatio,
		commit,
	} = useTitleAutoSave<OptionalInherited<AspectRatio | null>>({
		path: UseMemoJsonPath(titlePaths.aspectRatio.split('.')),
		label: 'Aspect Ratio:',
	});

	const currentTitle = useTitleEditorStore(titleEditorStoreSelector.title);

	const [activeOptions, setActiveOptions] = useState<Option[]>(AspectRatioJson);

	const aspectRatioAspectRatio = useMemo(() => aspectRatio?.displayValue?.aspectRatio.toString(), [aspectRatio]);

	const handleNewOptionAdded = (newOption: string) => {
		setActiveOptions((prev) => [
			...prev,
			{
				value: newOption,
				label: newOption,
			},
		]);
	};
	const { updateValue } = useUpdateValue<AspectRatio | null>({ value: aspectRatio });

	const aspectRatioVariant = getVariant(aspectRatio);

	return (
		<TitleEditableField
			label="Aspect Ratio:"
			path={titlePaths.aspectRatio}
			jumpToParentButtonDisabled
			tooltipDisabled
			height={48}
			readComponent={
				<ReadOnlyTypography variant={aspectRatioVariant}>
					{computeAspectRatioDisplay(readonlyAspectRatio)}
				</ReadOnlyTypography>
			}
			editComponent={
				<div style={{ width: '100%' }}>
					<DropdownV2
						withSearch
						pickList
						searchInputType="number"
						options={activeOptions}
						onNewOptionAdded={handleNewOptionAdded}
						value={aspectRatioAspectRatio}
						onChange={(value) => {
							setAspectRatio((prev) => ({
								...prev,
								displayValue: computeNextAspectRatio(value, aspectRatio, currentTitle.readonly.workType),
							}));
						}}
						bottomActions={
							<BottomActionClear
								action={() => {
									setAspectRatio(updateValue(null));
									commit?.();
								}}
							/>
						}
						height={38}
						onBlur={commit}
						variant={aspectRatioVariant}
					/>
				</div>
			}
		/>
	);
}

// TODO: Use the updateValue hook with its transform function to handle this
function computeNextAspectRatio(
	newAspectRatioString: string | null,
	currentAspectRatio: OptionalInherited<AspectRatio | null> | undefined,
	workType: WorkType,
) {
	const parsedV = newAspectRatioString ? parseFloat(newAspectRatioString) : null;

	let aspectRatioData: AspectRatio | null;

	if (parsedV && !Number.isNaN(parsedV)) {
		if (typeof currentAspectRatio?.displayValue?.original === 'boolean') {
			aspectRatioData = {
				aspectRatio: parsedV,
				original: currentAspectRatio?.displayValue?.original,
			};
		} else {
			aspectRatioData = { aspectRatio: parsedV, original: ![WorkType.Manifestation, WorkType.Edit].includes(workType) };
		}
	} else {
		aspectRatioData = null;
	}

	return aspectRatioData;
}

function computeAspectRatioDisplay(aspectRatio: OptionalInherited<AspectRatio | null> | undefined) {
	const aspectRatioAspectRatio = aspectRatio?.displayValue?.aspectRatio;

	if (!aspectRatioAspectRatio) return '';

	if (Number.isNaN(aspectRatioAspectRatio)) return '';
	switch (aspectRatioAspectRatio) {
		case 1.33:
			return '4:3';
		case 1.78:
		case 1.77:
			return '16:9';
		default:
			return `${aspectRatioAspectRatio}:1`;
	}
}

function getVariant(value: OptionalNullInherited<unknown> | undefined): 'default' | 'prefilled' {
	if (!value) return 'default';

	return isInherited<unknown>({ inheritedObject: optionalNullInheritedToOptionalInherited(value) }).isInherited
		? 'prefilled'
		: 'default';
}
