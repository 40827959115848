import {
	AspectRatioV2,
	FrameRateV2,
	IndexedCountryOrSubdivision,
	IndexedGenre,
	IndexedMadeForRegions,
	IndexedOriginalLanguage,
	IndexedUserInfos,
	TitleIndexedPartOfCompilation,
	TitleIndexedSequenceInfo,
	WorkTypeDetail,
} from '@warehouse/graphql';
import { EditDerivableWorkType, ManifestationDerivableWorkType, WorkType } from '@warehouse/title-shared/core';
import { TitleLightParent } from './models/parent';
import { TitleMetadata } from './models/title-metadata';
import { TitleSearchDisplayNameFullHTML } from './models/title-search-display-name-full-html';

export interface TitleCommon {
	uuid: string;
	/**
	 * These are the fields that are computed and indexed by the backend.
	 * They have a different shape from metadata because they don't hold inheritance information.
	 */
	readonly: CommonReadonly;
}

export interface SoftTitleCommon {
	uuid: string;
	readonly: Exclude<CommonReadonly, 'releaseYear'> & { releaseYear?: string };
}

export interface TitleFull extends TitleCommon {
	metadata: TitleMetadata;
	ultraMezzUuid?: string;
}

export type CommonReadonly =
	| EditReadonly
	| MovieReadonly
	| SeasonReadonly
	| SeriesReadonly
	| CompilationReadonly
	| SupplementalReadonly
	| ManifestationReadonly
	| EpisodeReadonly;

export interface TitleLightIdLabel {
	uuid: string;
	label: string;
}

export interface EditReadonly extends CommonReadonlyBase {
	workType: WorkType.Edit;
	parentAbstractionWorkType: EditDerivableWorkType;
	runLength?: string;
	madeForRegion?: TitleLightIdLabel[];
}

export interface MovieReadonly extends CommonReadonlyBase {
	workType: WorkType.Movie;
	parentAbstractionWorkType?: never;
	runLength?: string;
}

export interface EpisodeReadonly extends CommonReadonlyBase {
	workType: WorkType.Episode;
	parentAbstractionWorkType?: never;
	runLength?: string;
}

export interface SeasonReadonly extends CommonReadonlyBase {
	workType: WorkType.Season;
	parentAbstractionWorkType?: never;
	runLength?: string;
	madeForRegion?: TitleLightIdLabel[];
}

export interface SeriesReadonly extends CommonReadonlyBase {
	workType: WorkType.Series;
	parentAbstractionWorkType?: never;
	runLength?: string;
}

export interface CompilationReadonly extends CommonReadonlyBase {
	workType: WorkType.Compilation;
	parentAbstractionWorkType?: never;
}

export interface SupplementalReadonly extends CommonReadonlyBase {
	workType: WorkType.Supplemental;
	parentAbstractionWorkType?: never;
	runLength?: string;
}

export interface ManifestationReadonly extends CommonReadonlyBase {
	workType: WorkType.Manifestation;
	parentAbstractionWorkType: ManifestationDerivableWorkType;
	runLength?: string;
	madeForRegion?: TitleLightIdLabel[];
	platformSpecification?: ReadonlyPlatformSpecification;
	alid?: string;
}

export interface ReadonlyPlatformSpecification {
	uuid: string;
	label: string;
}

export interface ReadonlyVersionLanguage {
	uuid: string;
	label: string;
}

export interface CommonReadonlyBase {
	/**
	 * @deprecated use TitleCommon.uuid instead (parent of this object)
	 */
	uuid: string;
	releaseYear: string;
	releaseDate?: string;
	parents?: TitleLightParent[];
	licensor: {
		label: string;
		uuid: string;
	};
	titleSearchDisplayNameFullHTML: TitleSearchDisplayNameFullHTML;
	titleSearchDisplayNameFull?: string;
	versionLanguages?: ReadonlyVersionLanguage[] | null;

	// Copy-pasted from IndexedTitleV2
	aspectRatio?: AspectRatioV2 | null;
	countriesOfCreation?: IndexedCountryOrSubdivision[] | null;
	countriesOfProduction?: IndexedCountryOrSubdivision[] | null;
	createdAt: string;
	createdBy: IndexedUserInfos;
	distributionNumber?: string | null;
	editClasses?: string[] | null;
	editUse?: string | null;
	editedAt: string;
	editedBy: IndexedUserInfos;
	eidrId?: string | null;
	frameRate?: FrameRateV2 | null;
	genres?: IndexedGenre[] | null;
	houseSequence?: string | null;
	madeForRegions?: IndexedMadeForRegions[] | null;
	madeForRegionsExclude?: IndexedMadeForRegions[] | null;
	originalLanguages?: IndexedOriginalLanguage[] | null;
	originalTitle?: string | null;
	partOfCompilations?: TitleIndexedPartOfCompilation[] | null;
	pictureColorType?: string | null;
	ratings?: string[] | null;
	runLength?: string | null;
	sequenceInfo?: TitleIndexedSequenceInfo | null;
	sequenceNumber?: number | null;
	summary190?: string | null;
	summary400?: string | null;
	summary4000?: string | null;
	threeD?: boolean | null;
	titleAbbreviated?: string | null;
	titleDisplayUnlimited?: string | null;
	titleSearchSortFull?: string | null;
	titleSecurity?: string | null;
	titleSort?: string | null;
	titleStatus: string;
	workTypeDetails?: WorkTypeDetail[] | null;
}

export function isEdit(readOnly: CommonReadonly): readOnly is EditReadonly {
	return readOnly.workType === WorkType.Edit;
}

export function isManifestation(readOnly: CommonReadonly): readOnly is ManifestationReadonly {
	return readOnly.workType === WorkType.Manifestation;
}

export function isDerived(readOnly: CommonReadonly): readOnly is EditReadonly | ManifestationReadonly {
	return isEdit(readOnly) || isManifestation(readOnly);
}
