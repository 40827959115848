import { RelationshipType } from '@warehouse/title/core';
import { WorkType } from '@warehouse/title-shared/core';
import { getRelationshipType, getRelationshipTypeObject, getWorkType, getWorkTypeObject } from '@warehouse/title/infra';
import workTypeRelationships from '../../../src/assets/json-administration-profiles/worktypeRelationships.json';
import workTypeRelationshipsChildren from '../../../src/assets/json-administration-profiles/worktypeRelationshipsChildren.json';
import workTypes from '../../../src/assets/json-administration-profiles/workTypes.json';

export function getChildrenWorkTypesForParentWorkType(parentWorkType: WorkType): WorkType[] {
	const parentWorkTypeId = getWorkTypeObject(parentWorkType).uuid;

	const supportedChildrenWorkTypeIds = workTypeRelationships[parentWorkTypeId as keyof typeof workTypeRelationships];

	return supportedChildrenWorkTypeIds.map((workTypeId) => getWorkType(workTypeId));
}

export function getRelationshipTypeForParentAndChildWorkTypes({
	parentWorkType,
	childWorkType,
}: {
	parentWorkType: WorkType;
	childWorkType: WorkType;
}): RelationshipType[] {
	const parentWorkTypeId = getWorkTypeObject(parentWorkType).uuid;
	const childWorkTypeId = getWorkTypeObject(childWorkType).uuid;

	const parentWorkTypeJsonObject = workTypes.find((workType) => workType.value === parentWorkTypeId)!;
	const childWorkTypeJsonObject = workTypes.find((workType) => workType.value === childWorkTypeId)!;

	const supportedTargetRelationships = parentWorkTypeJsonObject.supportedParentRelationshipTypeAsTarget;
	const supportedSourceRelationships = childWorkTypeJsonObject.supportedParentRelationshipTypeAsSource;

	const overlappingRelationships = supportedTargetRelationships.filter((relationshipTypeId) =>
		supportedSourceRelationships.includes(relationshipTypeId),
	);

	return overlappingRelationships.map((relationshipTypeId) => getRelationshipType(relationshipTypeId));
}

export function getChildrenWorkTypesForRelationshipType(relationshipType: RelationshipType): WorkType[] {
	const relationshipTypeId = getRelationshipTypeObject(relationshipType).relationshipType;

	const supportedChildrenWorkTypeIds =
		workTypeRelationshipsChildren[relationshipTypeId as keyof typeof workTypeRelationshipsChildren];

	return supportedChildrenWorkTypeIds.map((workTypeId) => getWorkType(workTypeId));
}

export function getRelationshipTypesForParentWorkType(parentWorkType: WorkType): RelationshipType[] {
	const parentWorkTypeId = getWorkTypeObject(parentWorkType).uuid;

	const parentWorkTypeJsonObject = workTypes.find((workType) => workType.value === parentWorkTypeId)!;

	const supportedTargetRelationships = parentWorkTypeJsonObject.supportedParentRelationshipTypeAsTarget;

	return supportedTargetRelationships.map((relationshipTypeId) => getRelationshipType(relationshipTypeId));
}

export function getDefaultWorkTypeForRelationshipTypeSoft(relationshipType: RelationshipType): WorkType | null {
	if (relationshipType === RelationshipType.EntryOf) {
		return WorkType.Movie;
	}
	const tabs = workTypes.map((workType) => workType.tabsToDisplay).flat();
	const required = tabs.find((tab) => tab.relationshipRequired === relationshipType);
	if (!required) {
		return null;
	}
	return getWorkType(required.value);
}

export function getDefaultWorkTypeForRelationshipType(relationshipType: RelationshipType): WorkType {
	const defaultWorkType = getDefaultWorkTypeForRelationshipTypeSoft(relationshipType);
	if (!defaultWorkType) throw new Error(`No default work type for relationship type ${relationshipType}`);

	return defaultWorkType;
}
