import React, { ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { alpha } from '@mui/material';

// HOOKS
import { FeatureId, isFeatureEnabledForCurrentTenant } from '@warehouse/shared/config';
import {
	TitleEditorStoreEffects,
	TitleEditorStoreProvider,
	titleEditorStoreSelector,
	usePoster,
	useTitle,
	useTitleEditorStore,
} from '@warehouse/title/domain';
import { CenteredNxLoader, TabPanel, TabsHeader, TabsWrapper } from '@warehouse/shared/ui';
import { EditPreventer } from '@warehouse/title/feature-edit-preventer-popup';
import compact from 'lodash/compact';
import { WorkType } from '@warehouse/title-shared/core';
import useWorkTypes from '../../utils/hooks/titles/useWorkTypes';
import { useStateQuery } from '../../utils/hooks/useStateQuery';

// LIBRARY
import TitleOverviewLoader from '../../components/titles/TitleOverview/TitleOverview';
import { useURLFragment } from '../../utils/hooks/useURLFragment';
import { useHasEpisodeChildren } from './hooks/useHasEpisodeChildren';
import { ProductMetadataTab } from './tab/productMetadata';
import TitleSettings from './TitleSettings/TitleSettings';
import { Relationships } from './tab/relationships';
import ActivityTab from './tab/activity';
import ArtworkTab from './tab/artwork';
import TracksTab from './tab/tracks/TracksTab';
import OrdersTab from './tab/orders/OrdersTab';
import UnsavedChangesContextProvider, { UnsavedChangesContext } from './contexts/UnsavedChangesContext';
import { ChildrenTab } from './tab/children-tab';
import { useChildrenTabs } from './hooks/useChildrenTabs';
import { useRequiredParam } from '../../../libs/shared/util/use-required-params';
import TitleAutoSaveQueueContextProvider from './contexts/TitleAutoSaveQueueContext';
import TitleAutoSaveContextProvider from './contexts/TitleAutoSaveContext';
import { useHasEditChildren } from './hooks/useHasEditChildren';
import { useHasManifestationChildren } from './hooks/useHasManifestationChildren';
import { ObjectsTab } from './tab/objects/ObjectsTab';

const Wrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		gap: ${theme.spacing(2)};
		overflow-y: auto;
		padding: ${theme.spacing(2)};
		width: 100%;
	`,
);

const BackdropWrapper = styled.div<{ url?: string }>(
	({ url }) => css`
		background-color: #dde0e3;
		${url &&
		css`
			background-image: url('${url}');
		`}
		background-position: center;
		background-size: cover;
		width: 100%;
	`,
);

const BackdropContainer = styled.div(
	() => css`
		backdrop-filter: blur(8px);
		display: flex;
		height: 100%;
	`,
);

const GlobalStyleTitleOverviewShadow = createGlobalStyle(
	() => css`
		.title-overview-shadow {
			border-radius: 8px;
			box-shadow: 0 30px 40px ${alpha('#000000', 0.5)};
		}
	`,
);

const TitleOverviewWrapper = styled.div<{ showBoxShadow?: boolean }>(
	() => css`
		flex: 0 0 auto;
		position: sticky;
		top: 0;
		z-index: 20; // Needed to be above the SimpleTable
	`,
);

export function AdminTitleView() {
	return (
		<TitleEditorStoreProvider>
			<TitleEditorStoreEffects />
			<EditPreventer>
				<TitleAutoSaveQueueContextProvider>
					<TitleAutoSaveContextProvider>
						<UnsavedChangesContextProvider>
							<AdminTitleViewLoader />
						</UnsavedChangesContextProvider>
					</TitleAutoSaveContextProvider>
				</TitleAutoSaveQueueContextProvider>
			</EditPreventer>
		</TitleEditorStoreProvider>
	);
}

function AdminTitleViewLoader() {
	const id = useRequiredParam('id');

	const { setTitleUuid } = useTitleEditorStore(titleEditorStoreSelector.actions);

	useEffect(() => {
		setTitleUuid(id);
	}, [id, setTitleUuid]);

	const isTitlePending = useTitleEditorStore(titleEditorStoreSelector.isPending);

	if (isTitlePending) return <CenteredNxLoader />;

	// This is a workaround to make sure the whole page and its components are re-rendered when the title changes
	return <AdminTitleViewComp key={id} />;
}

export function AdminTitleViewComp() {
	// state
	const { promptUserIfUnsavedChanges } = useContext(UnsavedChangesContext);
	const [fragment, setFragment] = useURLFragment();
	const id = useRequiredParam('id');
	const [settingsOpen, setSettingsOpen] = useStateQuery<boolean>({
		queryName: 'settings',
		type: 'boolean',
	});
	const [selectedTab, setSelectedTab] = useState(-1);
	const titleOverviewWrapperRef = useRef<HTMLDivElement>(null);
	// hooks
	const { episodesUuid, editUuid, manifestationUuid } = useWorkTypes();
	const { data: posterData } = usePoster();
	const title = useTitle({ uuid: id });
	const isTitleHidden = title.data?.readonly.titleStatus === 'hidden';
	const [hasEpisode, loadingHasEpisode] = useHasEpisodeChildren(id, isTitleHidden);
	const [hasEdit, loadingHasEdit] = useHasEditChildren(id, isTitleHidden);
	const [hasManifestation, loadingHasManifestation] = useHasManifestationChildren(id, isTitleHidden);
	const loading = !title.data || loadingHasEpisode || loadingHasEdit || loadingHasManifestation;

	const titleWorkType = title.data?.readonly.workType;
	// This is used to remove work types from the tab list if there are no children to display.
	// For instance, if an Edit doesn't have sub-edits, the Edit tab should not be displayed.
	const workTypesToExclude: string[] = useMemo(
		() => {
			switch (titleWorkType) {
				case WorkType.Edit:
					return !hasEdit ? [editUuid()] : [];
				case WorkType.Series:
					return compact([
						!hasEpisode ? episodesUuid() : undefined,
						!hasManifestation ? manifestationUuid() : undefined,
					]);
				case WorkType.Season:
				case WorkType.Compilation:
					return !hasManifestation ? [manifestationUuid()] : [];
				default:
					return [];
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[hasEdit, hasManifestation, hasEpisode, titleWorkType],
	);
	const childrenTabs = useChildrenTabs(title.data, workTypesToExclude);

	// ref
	const wrapperRef = useRef<HTMLDivElement>(null);

	// effects
	const tabs = useMemo<TabDefinition[]>(
		() =>
			title.data
				? [
						...childrenTabs.map((tab) => ({
							defaultUrlState: tab.pluralLabel.toLowerCase(),
							label: tab.pluralLabel,
							component: <ChildrenTab tabProperties={tab} title={title.data!} />,
						})),
						{
							defaultUrlState: 'product-metadata/localized-info/default',
							label: 'Product Metadata',
							component: <ProductMetadataTab title={title.data} wrapperRef={wrapperRef} />,
						},
						...addTabIfVisible(
							{
								defaultUrlState: 'objects',
								label: 'Objects',
								scrollMode: 'content',
								component: <ObjectsTab wrapperRef={wrapperRef} />,
							},
							'title-objects-tab',
						),
						...addTabIfVisible(
							{
								defaultUrlState: 'tracks',
								label: 'Tracks',
								component: <TracksTab wrapperRef={wrapperRef} />,
							},
							'title-tracks-tab',
						),
						...addTabIfVisible(
							{
								defaultUrlState: 'artwork',
								label: 'Artwork',
								component: <ArtworkTab />,
								disabled: false,
							},
							'title-artworks-tab',
						),
						...addTabIfVisible(
							{
								defaultUrlState: 'orders',
								label: 'Orders',
								component: <OrdersTab />,
							},
							'title-orders-tab',
						),
						{
							defaultUrlState: 'relationships',
							label: 'Relationships',
							component: <Relationships />,
							disabled: false,
						},
						...addTabIfVisible(
							{
								defaultUrlState: 'activity/comments',
								label: 'Activity',
								component: <ActivityTab />,
								disabled: false,
							},
							'title-activity-tabs',
						),
					]
				: [],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[title.data, childrenTabs],
	);

	// Put shadow on title overview when scrolling
	useEffect(() => {
		const handleScroll = () => {
			const isScrolled = (wrapperRef.current && wrapperRef.current.scrollTop > 0) || false;
			if (titleOverviewWrapperRef.current) {
				if (isScrolled) {
					titleOverviewWrapperRef.current.classList.add('title-overview-shadow');
				} else {
					titleOverviewWrapperRef.current.classList.remove('title-overview-shadow');
				}
			}
		};

		wrapperRef.current?.addEventListener('scroll', handleScroll);

		return () => {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			wrapperRef.current?.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const goToDefaultTab = () => {
		if (childrenTabs.length) setFragment(childrenTabs[0].pluralLabel.toLowerCase());
		else if (isFeatureEnabledForCurrentTenant('title-orders-tab')) setFragment('orders');
		else setFragment('product-metadata/localized-info/default');
	};

	useEffect(() => {
		if (loading) return;
		if (!fragment) {
			goToDefaultTab();
			return;
		}
		const requestedTabName = fragment.split('/')[0];
		const tabIndex = tabs.findIndex((tab) => {
			const keyMatching = tab.defaultUrlState.split('/');
			if (!keyMatching.length) return false;
			return requestedTabName === keyMatching[0];
		});
		if (tabIndex >= 0) setSelectedTab(tabIndex);
		else goToDefaultTab();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fragment, loading]);

	if (loading || selectedTab < 0) {
		return <CenteredNxLoader />;
	}

	return (
		<>
			<BackdropWrapper url={posterData?.backdrop || posterData?.poster}>
				<BackdropContainer ref={wrapperRef}>
					<GlobalStyleTitleOverviewShadow />
					<Wrapper>
						<TitleOverviewWrapper ref={titleOverviewWrapperRef}>
							<TitleOverviewLoader onClickSettings={() => setSettingsOpen(true)} titleFull={title.data} />
						</TitleOverviewWrapper>
						<TabsWrapper $scrollMode={tabs[selectedTab]?.scrollMode || 'page'}>
							<TabsHeader
								selectedTab={selectedTab}
								onChange={(newSelectedTab) => {
									promptUserIfUnsavedChanges(() => setFragment(tabs[newSelectedTab]?.defaultUrlState));
								}}
								tabs={tabs}
								prefix="simple"
							/>
							{tabs.map((tab, index) => (
								<TabPanel prefix="simple" scrollMode={tab.scrollMode} key={index} value={selectedTab} index={index}>
									{tab.component}
								</TabPanel>
							))}
						</TabsWrapper>
					</Wrapper>
				</BackdropContainer>
			</BackdropWrapper>
			<TitleSettings
				wrapperRef={wrapperRef}
				open={settingsOpen}
				data={title.data}
				handleClose={() => setSettingsOpen(false)}
			/>
		</>
	);
}

type TabDefinition = {
	defaultUrlState: string;
	label: string;
	component: ReactNode;
	scrollMode?: 'page' | 'content';
	disabled?: boolean;
};

function addTabIfVisible(tab: TabDefinition, elementId: FeatureId): TabDefinition[] {
	return isFeatureEnabledForCurrentTenant(elementId) ? [tab] : [];
}
