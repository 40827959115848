import { Filters, InfraResourceIdentifier, SearchMode } from '@warehouse/object-browser/core';
import { assertUnreachable } from '@warehouse/shared/util';
import compact from 'lodash/compact';
import { WildCardSearchAdapter } from './wild-card-search.adapter';

export class NxObjectHashBuilder {
	static createFiltersHash(filters: Filters): string {
		const addToHashIfDefined = (hash: string, key: string, value: string | undefined): string => {
			if (value === undefined) return hash;
			if (hash === '') return `${key}=${value}`;

			return `${hash}&${key}=${value}`;
		};

		const { page, perPage } = filters.pagination;
		const pageHash = `${(page - 1) * perPage}-${page * perPage}`;
		const sortHash = filters.sort?.map((value) => `${value.field.toLowerCase()}-${value.sort}`).join(',') || undefined;
		const filtersHash = filters.filter
			? `${filters.filter.combinationOperator}${JSON.stringify(filters.filter.filters)}`
			: undefined;

		let hash = addToHashIfDefined('', 'page', pageHash);
		hash = addToHashIfDefined(hash, 'sort', sortHash);
		hash = addToHashIfDefined(hash, 'filters', filtersHash);

		return hash;
	}

	static createWildCardHash(textSearch: Filters['textSearch']): string | false {
		const httpWildCardSearch = WildCardSearchAdapter.adapt(textSearch);
		if (!httpWildCardSearch) return false;
		return `search=${httpWildCardSearch.query}&keyToSearch=${httpWildCardSearch.fields.join(',')}`;
	}

	static createResourceIdentifierHash(resourceIdentifier: InfraResourceIdentifier | undefined): string | false {
		if (!resourceIdentifier) return false;
		switch (resourceIdentifier.type) {
			case 'bucket':
				return resourceIdentifier.bucketId;
			case 'folderPath':
				return resourceIdentifier.folderPath;
			default:
				assertUnreachable(resourceIdentifier, 'Invalid resource identifier');
				return false;
		}
	}

	static createGetQueryHash({ nxObjectUuid }: { nxObjectUuid: string }): string[] {
		return ['nx-object', nxObjectUuid];
	}

	static createSearchQueryHash({
		filters,
		resourceIdentifier,
		searchMode,
		assignedTitleUuid,
	}: {
		filters: Filters;
		resourceIdentifier: InfraResourceIdentifier | undefined;
		searchMode: SearchMode | undefined;
		assignedTitleUuid?: string | undefined;
	}): string[] {
		return compact([
			'nx-objects',
			this.createSearchModeHash(searchMode),
			this.createResourceIdentifierHash(resourceIdentifier),
			this.createWildCardHash(filters?.textSearch),
			this.createFiltersHash(filters),
			assignedTitleUuid,
		]);
	}

	static createSearchModeHash(searchMode: SearchMode | undefined) {
		if (!searchMode) return false;
		return `searchMode=${searchMode}`;
	}
}
