import styled, { css } from 'styled-components';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { NameColumnName, TitleColumnName } from '../../../object-browser/feature-object-browser/specific-column-names';

export interface StyledDataGridProProps {
	$enableDrillableStyleOnFolders?: boolean;
	$variant?: 'inline' | 'full';
}

export const StyledDataGridPro = styled(DataGridPro)<StyledDataGridProProps>(
	({ theme, $enableDrillableStyleOnFolders, $variant = 'full' }) => css`
		.MuiDataGrid-virtualScrollerContent,
		.MuiDataGrid-overlay {
			background-color: ${theme.palette.light.background};
		}

		${$variant === 'inline'
			? css`
					border: solid 1px ${theme.palette.light.backgroundAlt} !important;
					border-radius: ${theme.spacing(1)} !important;
				`
			: css`
					border: none !important;
					border-radius: 0 !important;
				`}

		& .even > .MuiDataGrid-cell {
			background-color: ${theme.palette.white.main};
		}

		& .odd > .MuiDataGrid-cell {
			background-color: ${theme.palette.light.background};
		}

		.MuiDataGrid-filler {
			background-color: ${theme.palette.light.background};
		}

		// Display headers and odd rows gray
		.MuiDataGrid-columnHeader {
			background-color: ${theme.palette.light.background};
		}

		// Set hovering cell styling
		.MuiDataGrid-cell[data-field='${NameColumnName}'],
		.MuiDataGrid-cell[data-field='${TitleColumnName}'] {
			border: 1px solid transparent;
		}

		// Set the border on the title card cell when hovering over a folder, or when the row is selected
		// do not apply the styling if the table is loading
		&:not(:has(.MuiDataGrid-skeletonLoadingOverlay))
			// apply styling only if a row is hovered
			.MuiDataGrid-row:hover
			// apply styling on title card cell, if it has a value and does not contain a no-title-card class element
			.MuiDataGrid-cell[data-field='${TitleColumnName}']:not([data-is-empty='true']):not(:has(.no-title-card)) {
			border: 1px solid ${theme.palette.blue.main};
			border-radius: 8px;
			cursor: pointer;
			outline: none;
		}

		// Remove the padding on the title cell, to match the title report's title card
		.MuiDataGrid-cell[data-field='${TitleColumnName}']:not([data-is-empty='true']):not(:has(.no-title-card)) {
			padding: 0;
		}

		// Set the border on the name cell when hovering over a folder, or when the row is selected
		${$enableDrillableStyleOnFolders &&
		css`
			// do not apply the styling if the table is loading
			&:not(:has(.MuiDataGrid-skeletonLoadingOverlay))
				// apply styling only if a row is hovered
				.MuiDataGrid-row.folder:hover
				// apply styling on name cell
				.MuiDataGrid-cell[data-field='${NameColumnName}'] {
				border: 1px solid ${theme.palette.blue.main};
				border-radius: 8px;
				cursor: pointer;
				outline: none;
			}
		`}

		.MuiDataGrid-row {
			cursor: pointer;
		}

		// Set blue background on row hover or on selected row
		.MuiDataGrid-row:hover,
		.MuiDataGrid-row:hover .MuiDataGrid-cell,
		.MuiDataGrid-row:hover .MuiDataGrid-cell--pinnedLeft,
		.MuiDataGrid-row:hover .MuiDataGrid-cell--pinnedRight,
		.selected,
		.selected .MuiDataGrid-cell,
		.selected .MuiDataGrid-cell--pinnedLeft,
		.selected .MuiDataGrid-cell--pinnedRight {
			background-color: ${theme.palette.blue.background} !important;
		}

		// Set pinned divider color
		.MuiDataGrid-columnHeader--withRightBorder,
		.MuiDataGrid-row .MuiDataGrid-cell--withRightBorder {
			border-right-color: ${theme.palette.action.divider};
		}

		// Hide focused cell styling
		.MuiDataGrid-columnHeader:focus,
		.MuiDataGrid-cell:focus {
			outline: none;
		}
	`,
);
