import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { indexedTitleToCommonReadonly } from '@warehouse/title/infra';
import { TitleCard } from '@warehouse/title/ui';
import { TitleCardService } from '../../../../libs/title/ui/title-card/title-card.service';

const MenuItem = styled.li(
	({ theme }) => css`
		align-items: center;
		box-sizing: border-box;
		cursor: pointer;
		display: flex;
		list-style: none;
		padding: ${theme.spacing(1)};
		pointer-events: auto !important;
		z-index: 10 !important;

		&:hover {
			background-color: ${theme.palette.blue.background};
		}
	`,
);

const Row = memo(({ data, index, style }: any) => {
	const { handleSelect, options, setActive } = data;

	return (
		<MenuItem
			tabIndex={0}
			key={options[index].uuid}
			onKeyDown={(e) => {
				if (e.code === 'Space' || e.code === 'Enter') handleSelect(options[index]);
			}}
			onClick={() => {
				handleSelect(options[index]);
				setActive(false);
			}}
			style={style}
		>
			<TitleCard
				variant="colored"
				isLink={false}
				{...TitleCardService.getTitleCardPropsFromTitleCommonReadonly(indexedTitleToCommonReadonly(options[index]))}
			/>
		</MenuItem>
	);
});

export default Row;
