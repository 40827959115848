import React, { useMemo } from 'react';
import { ReadOnlyTypography } from '@warehouse/shared/ui';
import TitleEditableField from '../../TitleEditableField';
import TextInput from '../../../library/TextInput';

interface DistributionNumberFieldProps {
	distributionNumber: string;
	setDistributionNumber: (distributionNumber: string) => void;
	isInherited: boolean;
	onBlur: () => void;
}

function DistributionNumberField({
	distributionNumber,
	setDistributionNumber,
	isInherited,
	onBlur,
}: DistributionNumberFieldProps) {
	return useMemo(
		() => (
			<TitleEditableField
				label="Distribution Number:"
				path="coreMetadata.basic.sequenceInfo.distributionNumber.distributionNumber"
				jumpToParentButtonDisabled
				tooltipDisabled
				readComponent={
					<ReadOnlyTypography variant={isInherited ? 'prefilled' : 'default'}>{distributionNumber}</ReadOnlyTypography>
				}
				editComponent={
					<TextInput
						fullWidth
						width="50%"
						variant={isInherited ? 'prefilled' : 'default'}
						value={distributionNumber}
						onChange={(e) => setDistributionNumber(e.target.value)}
						onBlur={onBlur}
						height={38}
					/>
				}
			/>
		),
		[distributionNumber, isInherited],
	);
}

export default DistributionNumberField;
