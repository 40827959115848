import React from 'react';
import { WorkType } from '@warehouse/title-shared/core';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';

// MANIFESTATION LAYOUTS
import { assertUnreachable } from '@warehouse/shared/util';
import {
	Compilation,
	Episode,
	ManifestationOverviewByAbstractTypeProps,
	Movie,
	Season,
	Series,
	Supplemental,
	Unhandled,
} from './manifestation-sublayouts';
import { TitleOverviewByTypeProps } from './types';

export function Manifestation({ topBar }: TitleOverviewByTypeProps) {
	const title = useTitleEditorStore(titleEditorStoreSelector.title);

	if (title.readonly.workType !== WorkType.Manifestation)
		throw new Error(`Trying to render manifestation overview with a ${title.readonly.workType}`);

	const parentAbstractWorkType = title.readonly.parentAbstractionWorkType;

	const commonProps: ManifestationOverviewByAbstractTypeProps = {
		topBar,
	};

	switch (parentAbstractWorkType) {
		case WorkType.Movie:
			return <Movie {...commonProps} />;
		case WorkType.Episode:
			return <Episode {...commonProps} />;
		case WorkType.Supplemental:
			return <Supplemental {...commonProps} />;
		case WorkType.Series:
			return <Series {...commonProps} />;
		case WorkType.Season:
			return <Season {...commonProps} />;
		case WorkType.Compilation:
			return <Compilation {...commonProps} />;
		default:
			assertUnreachable(parentAbstractWorkType);
			return <Unhandled {...commonProps} parentAbstractWorkType={parentAbstractWorkType} />;
	}
}
