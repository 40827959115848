import React from 'react';
import MUIButton, { ButtonProps } from '@mui/material/Button';
import styled, { css } from 'styled-components';
import { alpha } from '@mui/material';

export interface IButtonProps extends ButtonProps {
	nxstyle?:
		| 'cta'
		| 'normal'
		| 'primary-blue'
		| 'primary-red'
		| 'secondary-blue'
		| 'secondary-red'
		| 'secondary-black'
		| 'tertiary-light'
		| 'tertiary-dark';
	$forceActive?: boolean;
	$fullHeight?: boolean;
	$disablePadding?: boolean;
	innerRef?: React.Ref<HTMLButtonElement>;
}

const StyledButton = styled(MUIButton)<IButtonProps>`
	${({ nxstyle, $forceActive, theme }) => {
		switch (nxstyle) {
			case 'cta':
				return css`
					${$forceActive
						? css`
								& .MuiButton-startIcon,
								.MuiButton-endIcon {
									svg {
										color: ${theme.palette.text.reverseMain};
									}
								}
								background-color: ${theme.palette.primary.main};
								color: ${theme.palette.text.reverseMain};
								outline: 2px solid ${alpha(theme.palette.primary.main, 0.5)};
							`
						: css`
								background-color: ${alpha(theme.palette.primary.main, 0.8)};
								border: 1px solid transparent;
								color: ${theme.palette.text.reverseMain};

								& .MuiButton-startIcon,
								.MuiButton-endIcon {
									svg {
										color: ${alpha(theme.palette.text.reverseMain, 0.8)};
									}
								}
							`}

					:hover,
					:focus {
						& .MuiButton-startIcon,
						.MuiButton-endIcon {
							svg {
								color: ${theme.palette.text.reverseMain};
							}
						}
						background-color: ${theme.palette.primary.main};
						color: ${theme.palette.text.reverseMain};
					}

					:disabled {
						& .MuiButton-startIcon,
						.MuiButton-endIcon {
							svg {
								color: ${alpha(theme.palette.text.reverseMain, 0.8)};
							}
						}
						background-color: ${alpha(theme.palette.primary.main, 0.3)};
						color: ${alpha(theme.palette.light.main, 0.8)};
					}

					:active {
						outline: 1px solid ${alpha(theme.palette.primary.main, 0.5)};
					}
				`;
			case 'primary-blue':
				return css`
					${$forceActive
						? css`
								& .MuiButton-startIcon,
								.MuiButton-endIcon {
									svg {
										color: ${theme.palette.text.reverseMain};
									}
								}
								background-color: ${theme.palette.blue.main};
								color: ${theme.palette.text.reverseMain};
								outline: 2px solid ${alpha(theme.palette.blue.main, 0.5)};
							`
						: css`
								background-color: ${alpha(theme.palette.blue.main, 0.8)};
								border: 1px solid transparent;
								color: ${theme.palette.text.reverseMain};

								& .MuiButton-startIcon,
								.MuiButton-endIcon {
									svg {
										color: ${alpha(theme.palette.text.reverseMain, 0.8)};
									}
								}
							`}

					:hover,
					:focus {
						& .MuiButton-startIcon,
						.MuiButton-endIcon {
							svg {
								color: ${theme.palette.text.reverseMain};
							}
						}
						background-color: ${theme.palette.blue.main};
						color: ${theme.palette.text.reverseMain};
					}

					:disabled {
						& .MuiButton-startIcon,
						.MuiButton-endIcon {
							svg {
								color: ${alpha(theme.palette.text.reverseMain, 0.8)};
							}
						}
						background-color: ${alpha(theme.palette.blue.main, 0.3)};
						color: ${alpha(theme.palette.light.main, 0.8)};
					}

					:active {
						outline: 2px solid ${alpha(theme.palette.blue.main, 0.5)};
					}
				`;
			case 'primary-red':
				return css`
					${$forceActive
						? css`
								& .MuiButton-startIcon,
								.MuiButton-endIcon {
									svg {
										color: ${theme.palette.text.reverseMain};
									}
								}
								background-color: ${theme.palette.red.main};
								color: ${theme.palette.text.reverseMain};
								outline: 2px solid ${alpha(theme.palette.red.main, 0.5)};
							`
						: css`
								background-color: ${alpha(theme.palette.red.main, 0.8)};
								border: 1px solid transparent;
								color: ${theme.palette.text.reverseMain};

								& .MuiButton-startIcon,
								.MuiButton-endIcon {
									svg {
										color: ${alpha(theme.palette.text.reverseMain, 0.8)};
									}
								}
							`}

					:hover,
					:focus {
						& .MuiButton-startIcon,
						.MuiButton-endIcon {
							svg {
								color: ${theme.palette.text.reverseMain};
							}
						}
						background-color: ${theme.palette.red.main};
						color: ${theme.palette.text.reverseMain};
					}

					:disabled {
						& .MuiButton-startIcon,
						.MuiButton-endIcon {
							svg {
								color: ${alpha(theme.palette.text.reverseMain, 0.8)};
							}
						}
						background-color: ${alpha(theme.palette.red.main, 0.3)};
						color: ${alpha(theme.palette.light.main, 0.8)};
					}

					:active {
						outline: 2px solid ${alpha(theme.palette.red.main, 0.5)};
					}
				`;
			case 'secondary-blue':
				return css`
					${$forceActive
						? css`
								& .MuiButton-startIcon,
								.MuiButton-endIcon {
									svg {
										color: ${theme.palette.blue.main};
									}
								}
								background-color: ${alpha(theme.palette.blue.main, 0.1)};
								border: 1px solid ${theme.palette.blue.main};
								color: ${theme.palette.blue.main};
								outline: 1px solid ${theme.palette.blue.main};
							`
						: css`
								background-color: transparent;
								border: 1px solid ${alpha(theme.palette.blue.main, 0.5)};
								color: ${alpha(theme.palette.blue.main, 0.8)};

								& .MuiButton-startIcon,
								.MuiButton-endIcon {
									svg {
										color: ${alpha(theme.palette.blue.main, 0.8)};
									}
								}
							`}

					:hover,
					:focus {
						& .MuiButton-startIcon,
						.MuiButton-endIcon {
							svg {
								color: ${theme.palette.blue.main};
							}
						}
						background-color: ${alpha(theme.palette.blue.main, 0.1)};
						border: 1px solid ${theme.palette.blue.main};
						color: ${theme.palette.blue.main};
					}

					:disabled {
						& .MuiButton-startIcon,
						.MuiButton-endIcon {
							svg {
								color: ${alpha(theme.palette.blue.main, 0.4)};
							}
						}
						background-color: transparent;
						border: 1px solid ${alpha(theme.palette.blue.main, 0.4)};
						color: ${alpha(theme.palette.blue.main, 0.4)};
					}

					:active {
						& .MuiButton-startIcon,
						.MuiButton-endIcon {
							svg {
								color: ${theme.palette.blue.main};
							}
						}
						outline: 1px solid ${theme.palette.blue.main};
					}
				`;
			case 'secondary-red':
				return css`
					${$forceActive
						? css`
								& .MuiButton-startIcon,
								.MuiButton-endIcon {
									svg {
										color: ${theme.palette.red.main};
									}
								}
								background-color: ${alpha(theme.palette.red.main, 0.1)};
								border: 1px solid ${theme.palette.red.main};
								color: ${theme.palette.red.main};
								outline: 1px solid ${theme.palette.red.main};
							`
						: css`
								background-color: transparent;
								border: 1px solid ${alpha(theme.palette.red.main, 0.5)};
								color: ${alpha(theme.palette.red.main, 0.8)};

								& .MuiButton-startIcon,
								.MuiButton-endIcon {
									svg {
										color: ${alpha(theme.palette.red.main, 0.8)};
									}
								}
							`}

					:hover,
					:focus {
						& .MuiButton-startIcon,
						.MuiButton-endIcon {
							svg {
								color: ${theme.palette.red.main};
							}
						}
						background-color: ${alpha(theme.palette.red.main, 0.1)};
						border: 1px solid ${theme.palette.red.main};
						color: ${theme.palette.red.main};
					}

					:disabled {
						& .MuiButton-startIcon,
						.MuiButton-endIcon {
							svg {
								color: ${alpha(theme.palette.red.main, 0.4)};
							}
						}
						background-color: transparent;
						border: 1px solid ${alpha(theme.palette.red.main, 0.4)};
						color: ${alpha(theme.palette.red.main, 0.4)};
					}

					:active {
						& .MuiButton-startIcon,
						.MuiButton-endIcon {
							svg {
								color: ${theme.palette.red.main};
							}
						}
						outline: 1px solid ${theme.palette.red.main};
					}
				`;
			case 'secondary-black':
				return css`
					${$forceActive
						? css`
								svg {
									color: ${theme.palette.text.primary};
								}

								background-color: ${theme.palette.light.backgroundAlt};
								border: 1px solid ${theme.palette.light.backgroundAlt};
								color: ${theme.palette.text.primary};
							`
						: css`
								background-color: transparent;
								border: 1px solid ${theme.palette.light.backgroundAlt};
								color: ${alpha(theme.palette.dark.main, 0.5)};
								svg {
									color: ${theme.palette.text.secondary};
								}
							`}

					:hover,
					:focus {
						& .MuiButton-startIcon,
						.MuiButton-endIcon {
							svg {
								color: ${theme.palette.dark.black};
							}
						}
						background-color: ${alpha(theme.palette.dark.main, 0.1)};
						border: 1px solid ${theme.palette.dark.black};
						color: ${theme.palette.dark.black};
					}

					:disabled {
						& .MuiButton-startIcon,
						.MuiButton-endIcon {
							svg {
								color: ${alpha(theme.palette.dark.main, 0.2)};
							}
						}
						background-color: transparent;
						border: 1px solid ${alpha(theme.palette.dark.main, 0.2)};
						color: ${alpha(theme.palette.dark.main, 0.2)};
					}

					:active {
						svg {
							color: ${theme.palette.text.primary};
						}

						background-color: ${theme.palette.light.backgroundAlt};
						border: 1px solid ${theme.palette.light.backgroundAlt};
						color: ${theme.palette.text.primary};
					}
				`;
			case 'tertiary-light':
				return css`
					${$forceActive
						? css`
								& .MuiButton-startIcon,
								.MuiButton-endIcon {
									svg {
										color: ${theme.palette.light.main};
									}
								}
								background-color: ${theme.palette.blue.main} !important;
								color: ${theme.palette.light.main};
							`
						: css`
								background-color: transparent;
								color: ${alpha(theme.palette.dark.main, 0.5)};
								& .MuiButton-startIcon,
								.MuiButton-endIcon {
									svg {
										color: ${alpha(theme.palette.dark.main, 0.5)};
									}
								}
							`}

					:hover,
					:focus {
						& .MuiButton-startIcon,
						.MuiButton-endIcon {
							svg {
								color: ${theme.palette.dark.black};
							}
						}
						background-color: ${alpha(theme.palette.dark.main, 0.05)};
						color: ${theme.palette.dark.black};
					}

					:disabled {
						& .MuiButton-startIcon,
						.MuiButton-endIcon {
							svg {
								color: ${alpha(theme.palette.dark.main, 0.3)};
							}
						}
						background-color: transparent;
						color: ${alpha(theme.palette.dark.main, 0.3)};
					}

					:active {
						& .MuiButton-startIcon,
						.MuiButton-endIcon {
							svg {
								color: ${theme.palette.light.main};
							}
						}
						background-color: ${theme.palette.blue.main};
						color: ${theme.palette.light.main};
					}
				`;
			case 'tertiary-dark':
				return css`
					${$forceActive
						? css`
								& .MuiButton-startIcon,
								.MuiButton-endIcon {
									svg {
										color: ${theme.palette.light.main};
									}
								}
								background-color: ${theme.palette.blue.main};
								color: ${theme.palette.light.main};
							`
						: css`
								background-color: transparent;
								color: ${alpha(theme.palette.light.main, 0.8)};
								& .MuiButton-startIcon,
								.MuiButton-endIcon {
									svg {
										color: ${alpha(theme.palette.light.main, 0.8)};
									}
								}
							`}

					:hover,
					:focus {
						& .MuiButton-startIcon,
						.MuiButton-endIcon {
							svg {
								color: ${theme.palette.light.main};
							}
						}
						background-color: ${alpha(theme.palette.light.main, 0.2)};
						color: ${theme.palette.light.main};
					}

					:disabled {
						& .MuiButton-startIcon,
						.MuiButton-endIcon {
							svg {
								color: ${alpha(theme.palette.light.main, 0.3)};
							}
						}
						background-color: transparent;
						color: ${alpha(theme.palette.light.main, 0.3)};
					}

					:active {
						& .MuiButton-startIcon,
						.MuiButton-endIcon {
							svg {
								color: ${theme.palette.light.main};
							}
						}
						background-color: ${theme.palette.blue.main};
						color: ${theme.palette.light.main};
					}
				`;
			case 'normal':
			default:
				return css`
					background-color: ${theme.palette.light.background};
					border: 1px solid ${theme.palette.light.backgroundAlt};
					color: ${theme.palette.text.primary};

					& .MuiButton-startIcon,
					.MuiButton-endIcon {
						svg {
							color: ${alpha(theme.palette.text.primary, 0.5)};
						}
					}
					:hover,
					:focus {
						background-color: ${theme.palette.light.backgroundAlt};
					}

					:disabled {
						background-color: ${alpha(theme.palette.light.background, 0.5)};
						color: ${alpha(theme.palette.dark.main, 0.3)};
					}

					:active {
						background-color: ${theme.palette.dark.background};
						border: 1px solid ${theme.palette.dark.background};
						color: ${theme.palette.text.reverseMain};
					}
				`;
		}
	}}

	${({ size, $fullHeight }) => {
		if (!$fullHeight)
			switch (size) {
				case 'small':
					return css`
						height: 28px;
					`;
				case 'medium':
					return css`
						height: 32px;
					`;
				case 'large':
					return css`
						height: 36px;
					`;
				default:
					return css`
						height: 32px;
					`;
			}
		else
			return css`
				height: 100%;
			`;
	}}
	
	${({ disabled }) =>
		disabled &&
		css`
			cursor: not-allowed !important;
			pointer-events: auto !important;
		`}
	
	border-radius: 6px;
	font-size: 14px;
	min-width: auto;
	padding: ${({ theme, $disablePadding }) => ($disablePadding ? '0px' : `${theme.spacing(1)} ${theme.spacing(1.5)}`)};
	text-transform: none;

	& .MuiButton-startIcon,
	.MuiButton-endIcon {
		svg {
			font-size: 1rem;
		}
	}
`;

export function Button({ innerRef, ...props }: IButtonProps) {
	return <StyledButton ref={innerRef} {...props} disableFocusRipple disableTouchRipple />;
}

export default Button;
