import React, { useCallback, useEffect } from 'react';
import {
	GridColDef,
	gridColumnsStateSelector,
	gridFilterModelSelector,
	GridLogicOperator,
	GridSortModel,
	gridSortModelSelector,
	useGridApiRef,
} from '@mui/x-data-grid-pro';
import { NxObject } from '@warehouse/object-browser/core';
import { objectBrowserStoreSelector, useObjectBrowserStore } from '@warehouse/object-browser/domain';
import { objectBrowserTableColumns } from '../columns';
import { CustomColumnMenu } from './CustomColumnMenu';
import { ObjectBrowserPaginationNavigator } from './ObjectBrowserPaginationNavigator';
import {
	muixGridVisibilityModelToTableVisibility,
	muixPinnedToTablePinned,
} from '../../../shared/ui/table/muix.adapter';
import { CustomGridCell } from './custom-grid-cell/CustomGridCell';
import { FilterModelAdapter } from '../core/filter-model.adapter';
import { SortModelAdapter } from '../core/sort-model.adapter';
import { CustomDataGridPro } from '../../../shared/ui/table/CustomDataGridPro';
import { NoObjectsFoundOverlay } from './NoObjectsFoundOverlay';
import { LicensorColumnName, TitleColumnName } from '../specific-column-names';
import { fromTitleSelector, useFromTitle } from '../domain/from-title.store';

export function ObjectBrowserTable({ disableVirtualization }: TableProps) {
	const filter = useObjectBrowserStore(objectBrowserStoreSelector.filter);
	const sort = useObjectBrowserStore(objectBrowserStoreSelector.sort);
	const visibility = useObjectBrowserStore(objectBrowserStoreSelector.visibility);
	const pinned = useObjectBrowserStore(objectBrowserStoreSelector.pinned);
	const order = useObjectBrowserStore(objectBrowserStoreSelector.order);
	const items = useObjectBrowserStore(objectBrowserStoreSelector.items);
	const isPending = useObjectBrowserStore(objectBrowserStoreSelector.isPending);
	const isInSearchMode = useObjectBrowserStore(objectBrowserStoreSelector.isInSearchMode);
	const selectedNxObjectUuid = useObjectBrowserStore(objectBrowserStoreSelector.selectedNxObjectUuid);
	const { setOrder, setSort, setPinned, setFilter, setVisibility, selectNxObject } = useObjectBrowserStore(
		objectBrowserStoreSelector.actions,
	);
	const status = useObjectBrowserStore(objectBrowserStoreSelector.status);
	const fromTitle = useFromTitle(fromTitleSelector.fromTitle);

	const apiRef = useGridApiRef();

	const pagination = useCallback(() => <ObjectBrowserPaginationNavigator centered />, []);

	const gridColumnsState = apiRef.current.state ? gridColumnsStateSelector(apiRef.current.state) : undefined;

	/**
	 * This use-effect synchronizes the field order in the store and the internal field order maintained by the table.
	 * There is a known issue where there is small lag (~500ms) between the moment the reordering (through dnd or pinning)
	 * takes place and the moment the useEffect fires. We consider this an acceptable tradeoff. Push back against
	 * any request to "bugfix" this.
	 */
	useEffect(() => {
		if (gridColumnsState) {
			setOrder(gridColumnsState.orderedFields);
		}
	}, [gridColumnsState, gridColumnsState?.orderedFields, setOrder]);

	/**
	 * It runs code when entering or exiting search mode
	 */
	useEffect(() => {
		if (!apiRef) return;
		const currentSortModel = gridSortModelSelector(apiRef);

		if (isInSearchMode) {
			// Side effect when entering search mode
			if (status !== 'title-objects-tab-ready') {
				apiRef.current.setSortModel(replaceNameByKey(currentSortModel));
			}
			apiRef.current.setColumnVisibility('bucket', true);
		} else {
			// Side effect when exiting search mode
			apiRef.current.setSortModel(replaceKeyByName(currentSortModel));
			apiRef.current.setColumnVisibility('bucket', false);
		}
	}, [apiRef, isInSearchMode, status]);

	/**
	 * When loading the Object Browser Table by coming from the Titles Object tab, we add a filter to only show
	 * the objects that have the title assigned to them.
	 */
	useEffect(() => {
		if (!apiRef.current) return;

		if (fromTitle) {
			const currentFilterModel = gridFilterModelSelector(apiRef);

			// This prevents resetting the same filters when reloading the page if one of them is already set
			if (
				currentFilterModel.items.find(
					(item) => item.field === TitleColumnName && item.value === fromTitle.titleSearch,
				) ||
				currentFilterModel.items.find(
					(item) => item.field === LicensorColumnName && item.value === fromTitle.licensorLabel,
				)
			) {
				return;
			}

			const newFilterModel = {
				...currentFilterModel,
				items: [
					...(currentFilterModel.items || []),
					{
						field: TitleColumnName,
						operator: 'contains',
						value: fromTitle.titleSearch,
					},
					{
						field: LicensorColumnName,
						operator: 'contains',
						value: fromTitle.licensorLabel,
					},
				],
				logicOperator: GridLogicOperator.And,
			};
			apiRef.current.setFilterModel(newFilterModel);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiRef, setFilter, fromTitle]);

	return (
		<CustomDataGridPro<NxObject>
			$enableDrillableStyleOnFolders={!isInSearchMode}
			$variant="full"
			apiRef={apiRef}
			columns={objectBrowserTableColumns}
			rows={items ?? []}
			initialState={{
				pinnedColumns: {
					left: [...(pinned.left || [])],
				},
				sorting: {
					sortModel: SortModelAdapter.fromApiToMuiXDataGrid(sort),
				},
				columns: {
					columnVisibilityModel: visibility,
					orderedFields: order,
				},
				filter: {
					filterModel: FilterModelAdapter.fromApiToMuiXDataGrid(filter),
				},
			}}
			getRowId={(row) => row.uuid}
			loading={isPending}
			slotProps={{
				// This prevents the itemType column from showing up in the columns visibility menu
				columnsManagement: {
					getTogglableColumns: (columns: GridColDef[]) =>
						columns.filter((column) => column.field !== 'itemType').map((column) => column.field),
				},
			}}
			onRowClick={({ row }) => selectNxObject(row)}
			rowSelection={false}
			sortingMode="server"
			onSortModelChange={(sortModel) => setSort(SortModelAdapter.fromMuiXDataGridToApi(sortModel))}
			filterMode="server"
			onFilterModelChange={(filterModel) => {
				setFilter(FilterModelAdapter.fromMuiXDataGridToApi(filterModel));
			}}
			onColumnVisibilityModelChange={(visibilityModel) => {
				setVisibility(muixGridVisibilityModelToTableVisibility(visibilityModel));
			}}
			onPinnedColumnsChange={(arg) => {
				setPinned(muixPinnedToTablePinned(arg));
			}}
			getRowClassName={({ row }) =>
				`${row.itemType} ${selectedNxObjectUuid && row.uuid === selectedNxObjectUuid ? 'selected' : ''}`
			}
			slots={{
				pagination,
				columnMenu: CustomColumnMenu,
				cell: CustomGridCell,
				noRowsOverlay: NoObjectsFoundOverlay,
			}}
			pagination
			disableVirtualization={disableVirtualization}
		/>
	);
}

export interface TableProps {
	/**
	 * @deprecated The method should only be used for component tests
	 */
	disableVirtualization?: boolean;
}

function replaceNameByKey(sortModel: GridSortModel) {
	return sortModel.map((sort) => {
		if (sort.field === 'name') {
			return { ...sort, field: 'key' };
		}
		return sort;
	});
}

function replaceKeyByName(sortModel: GridSortModel) {
	return sortModel.map((sort) => {
		if (sort.field === 'key') {
			return { ...sort, field: 'name' };
		}
		return sort;
	});
}
