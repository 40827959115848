import React, { useMemo } from 'react';
import { RelationshipType, CommonReadonly } from '@warehouse/title/core';
import { RelationshipsService } from '@warehouse/title/domain';
import { RelationshipTypeSelector } from '@warehouse/title/ui';
import {
	multiTitleSelectorSelector,
	useMultiTitleSelectorStore,
} from '@warehouse/title-shared/multi-titles-selector-feature/core';

export function RelationshipTypeSelectorContainer({ title }: RelationshipTypeSelectorProps) {
	const supportedRelationshipTypes = useMemo(
		() => RelationshipsService.getRelationshipTypesForParentWorkType(title.workType),
		[title.workType],
	);
	const relationshipType = useMultiTitleSelectorStore(multiTitleSelectorSelector.relationshipType);
	const actions = useMultiTitleSelectorStore(multiTitleSelectorSelector.actions);

	const onSelectWrapper = (_selected: RelationshipType) => {
		actions.setRelationshipType(_selected);
		actions.setWorkTypesToFilterOn(null);
	};

	return (
		<RelationshipTypeSelector
			selected={relationshipType}
			onSelect={onSelectWrapper}
			supportedRelationshipTypes={supportedRelationshipTypes}
			ariaLabel="Select Relationship Type"
		/>
	);
}

interface RelationshipTypeSelectorProps {
	title: CommonReadonly;
}
