import React from 'react';
import { Typography } from '@mui/material';
import { CommonReadonly } from '@warehouse/title/core';
import { TitleCard } from '@warehouse/title/ui';
import { FieldWrapper } from '../../ui/FieldWrapper';
import { TitleCardService } from '../../ui/title-card/title-card.service';

export function ContentField({ titleLight }: ContentFieldProps) {
	return (
		<FieldWrapper>
			<Typography variant="buttonMediumMedium" color="text.primary">
				Content
			</Typography>
			<TitleCard variant="colored" {...TitleCardService.getTitleCardPropsFromTitleCommonReadonly(titleLight)} />
		</FieldWrapper>
	);
}

type ContentFieldProps = { titleLight: CommonReadonly };
