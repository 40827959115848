import { GridCell, GridCellProps, useGridApiContext } from '@mui/x-data-grid-pro';
import React from 'react';

export function CustomGridCell({ rowId, column, ...rest }: GridCellProps): JSX.Element {
	const apiRef = useGridApiContext();
	const cellParams = apiRef.current.getCellParams(rowId, column.field);
	const commonProps = {
		rowId,
		column,
		...rest,
		'data-testid': `custom-grid-cell-${column.field}`,
	};

	if (cellParams.value === undefined) {
		return (
			<GridCell
				{...commonProps}
				data-is-empty="true"
				column={{
					...column,
					renderCell: () => '--',
				}}
			/>
		);
	}

	return <GridCell {...commonProps} />;
}
