import { AssignmentStatus, NxObjectFull, NxObjectType } from '@warehouse/object-browser/core';

export class AssignmentConfirmService {
	static needsConfirmation(nxObjectFull: NxObjectFull): boolean {
		return (
			nxObjectFull.nxObject.itemType === NxObjectType.Folder &&
			[AssignmentStatus.Assigned, AssignmentStatus.Unmanaged].includes(nxObjectFull.nxObject.assignmentStatus)
		);
	}
}
