import React, { useMemo } from 'react';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { TextInput } from '@warehouse/shared/legacy-ui';
import { isInherited } from '@warehouse/title/core';
import { ReadOnlyTypography } from '@warehouse/shared/ui';
import TitleEditableField from '../../../../src/components/titles/TitleEditableField';
import useTitleAutoSave from '../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';

interface TitleTextInputFieldProps {
	label: string;
	ariaLabel?: string;
	path: string;
	style?: React.CSSProperties;
	forcedEditMode?: boolean;
}

function getVariant(value: OptionalInherited<string> | undefined): 'default' | 'prefilled' {
	if (!value) return 'default';

	return isInherited<string>({ inheritedObject: value }).isInherited ? 'prefilled' : 'default';
}

export function TitleTextInputField({ label, ariaLabel, path, style, forcedEditMode }: TitleTextInputFieldProps) {
	const { readOnlyValue, setValue, value, commit, error } = useTitleAutoSave<OptionalInherited<string>>({
		path: useMemo(() => path.split('.'), [path]),
		label,
	});

	const isValueInherited = useMemo(
		() => (value ? isInherited<string>({ inheritedObject: value }).isInherited : false),
		[value],
	);

	return (
		<TitleEditableField
			wrapperStyle={style}
			label={label}
			path={path}
			direction="column"
			forcedEditMode={forcedEditMode}
			readComponent={
				<ReadOnlyTypography variant={getVariant(value)} style={{ height: 36 }} aria-label={ariaLabel}>
					{readOnlyValue?.displayValue || ''}
				</ReadOnlyTypography>
			}
			editComponent={
				<TextInput
					height={38}
					value={isValueInherited ? '' : value?.displayValue}
					onBlur={() => commit()}
					placeholder={value?.inherited.value || ''}
					ariaLabel={ariaLabel}
					onChange={(e) => {
						setValue((prev) => ({
							...prev,
							displayValue: e.target.value === '' ? prev.inherited.value || '' : e.target.value,
						}));
					}}
					variant={getVariant(value)}
					error={error}
					disabledMargin
				/>
			}
		/>
	);
}
