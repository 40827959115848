import { components } from '../../infra/inventory.openapi';

export interface GridFilterItem {
	/**
	 * Must be unique.
	 * Only useful when the model contains several items.
	 */
	id?: number | string;
	/**
	 * The column from which we want to filter the rows.
	 */
	field: components['schemas']['NxObjectSearchableFields'];
	/**
	 * The filtering value.
	 * The operator filtering function will decide for each row if the row values is correct compared to this value.
	 */
	value?: any;
	/**
	 * The name of the operator we want to apply.
	 */
	operator: GridFilterOperator;
}

export type GridFilterOperator =
	| StringFilterOperator
	| NumericFilterOperator
	| DateFilterOperator
	| BooleanFilterOperator;

export enum GridLogicOperator {
	AND = 'and',
	OR = 'or',
}

export interface GridFilterModel {
	/**
	 * @default []
	 */
	items: GridFilterItem[];
	/**
	 * - `GridLogicOperator.And`: the row must pass all the filter items.
	 * - `GridLogicOperator.Or`: the row must pass at least on filter item.
	 * @default GridLogicOperator.And
	 */
	logicOperator?: GridLogicOperator;
}

export type StringFilterOperator =
	| 'contains'
	| 'doesNotContain'
	| 'equals'
	| 'doesNotEqual'
	| 'startsWith'
	| 'endsWith'
	| 'isEmpty'
	| 'isNotEmpty'
	| 'isAnyOf'
	| 'isNoneOf';

export type NumericFilterOperator =
	| '='
	| '!='
	| '>'
	| '>='
	| '<'
	| '<='
	| 'isEmpty'
	| 'isNotEmpty'
	| 'isAnyOf'
	| 'isNoneOf';

export type DateFilterOperator =
	| 'is'
	| 'not'
	| 'after'
	| 'onOrAfter'
	| 'before'
	| 'onOrBefore'
	| 'isEmpty'
	| 'isNotEmpty';

export type BooleanFilterOperator = 'is';
