import React from 'react';
import {
	TitleSearchDisplayNameFullHTML,
	isPrimaryAndDerivedFrom as getIsPrimaryAndDerivedFrom,
	TitleLightParent,
} from '@warehouse/title/core';
import { WorkType, WorkTypesService } from '@warehouse/title-shared/core';
import titleSearchDisplayNameBuilder from '../../../../src/utils/titles/titleSearchDisplayNameBuilder';
import { ColoredTitleCard } from './ColoredTitleCard';
import { DefaultTitleCard } from './DefaultTitleCard';
import { TitleCardLink } from './TitleCardLink';
import { TitleCardProps } from './title-card.types';

export function TitleCard({
	titleUuid,
	titleSearchDisplayNameFullHTML,
	minWidth,
	variant = 'default',
	isLink = true,
	hideReleaseYear = false,
	isGrayAndItalicized = false,
	...props
}: TitleCardProps) {
	const commonProps = mapTitleSearchDisplayNameFullHTMLToInternalTitleCardProps(titleSearchDisplayNameFullHTML);

	let showPrimaryTick = false;
	if (props.context === 'common' || props.context === undefined) {
		showPrimaryTick = shouldDisplayPrimaryTickInCommonContext(titleSearchDisplayNameFullHTML);
	} else if (props.context === 'relationship-tab-children') {
		showPrimaryTick = shouldDisplayPrimaryTickInRelationshipTabChildrenContext(props.selectedParent);
	}

	const titleCardCompProps = {
		...commonProps,
		hideReleaseYear,
		minWidth,
		showPrimaryTick,
		isGrayAndItalicized,
	};

	const TitleCardVariant = variant === 'colored' ? ColoredTitleCard : DefaultTitleCard;

	if (isLink) {
		return (
			<TitleCardLink uuid={titleUuid} fullWidth>
				<TitleCardVariant {...titleCardCompProps} />
			</TitleCardLink>
		);
	}
	return <TitleCardVariant {...titleCardCompProps} />;
}

function mapTitleSearchDisplayNameFullHTMLToInternalTitleCardProps(
	titleSearchDisplayNameFullHTML: TitleSearchDisplayNameFullHTML,
) {
	const { title, subTitle1, subTitle2 } = titleSearchDisplayNameBuilder({
		tokens: titleSearchDisplayNameFullHTML,
	});

	const combinedWorkType =
		WorkTypesService.softGetCombinedWorktypeFromTitleSearchDisplayNameFullHTML(titleSearchDisplayNameFullHTML);

	if (!combinedWorkType) {
		console.error('Could not determine combined work type from title search display name full HTML');
	}

	return {
		title,
		subTitle1,
		subTitle2,
		releaseYear: titleSearchDisplayNameFullHTML.releaseYear,
		workType: combinedWorkType || WorkType.Movie, // Fallback to a default value to not break the UI
	};
}

function shouldDisplayPrimaryTickInCommonContext(titleSearchDisplayNameFullHTML: TitleSearchDisplayNameFullHTML) {
	return titleSearchDisplayNameFullHTML.parents
		? getIsPrimaryAndDerivedFrom(titleSearchDisplayNameFullHTML.parents)
		: false;
}

function shouldDisplayPrimaryTickInRelationshipTabChildrenContext(selectedParent: TitleLightParent) {
	return selectedParent.primary;
}
