import { StateCreator } from 'zustand';
import { NxObject } from '@warehouse/object-browser/core';

export interface ObjectBrowserDrawerManagerStore {
	isNxObjectDrawerOpen: boolean;
	internalActions: {
		setNxObjectDrawerOpen: (isOpen: boolean) => void;
	};
	actions: {
		selectNextObject: () => void;
		selectPreviousObject: () => void;
	};
}

export interface FactoryOptions {
	getCurrentSelectedObject: () => NxObject | undefined;
	getCurrentObjects: () => NxObject[];
	selectNxObject: (object: NxObject) => void;
}

export const createObjectBrowserDrawerManagerSliceFactory: (
	options: FactoryOptions,
) => StateCreator<ObjectBrowserDrawerManagerStore> = (options) => (set) => ({
	isNxObjectDrawerOpen: false,
	internalActions: {
		setNxObjectDrawerOpen: (isOpen: boolean) => {
			set({ isNxObjectDrawerOpen: isOpen });
		},
	},
	actions: {
		selectNextObject: () => {
			const currentObject = options.getCurrentSelectedObject();
			if (!currentObject) return;
			const currentObjects = options.getCurrentObjects();
			const index = currentObjects.findIndex((o) => o.uuid === currentObject.uuid);
			if (index === -1) return;
			const nextIndex = index + 1;
			if (nextIndex >= currentObjects.length) return;
			options.selectNxObject(currentObjects[nextIndex]);
		},
		selectPreviousObject: () => {
			const currentObject = options.getCurrentSelectedObject();
			if (!currentObject) return;
			const currentObjects = options.getCurrentObjects();
			const index = currentObjects.findIndex((o) => o.uuid === currentObject.uuid);
			if (index === -1) return;
			const previousIndex = index - 1;
			if (previousIndex < 0) return;
			options.selectNxObject(currentObjects[previousIndex]);
		},
	},
});

export const objectBrowserDrawerManagerSelector = {
	actions: (state: ObjectBrowserDrawerManagerStore) => state.actions,
	isNxObjectDrawerOpen: (state: ObjectBrowserDrawerManagerStore) => state.isNxObjectDrawerOpen,
};
