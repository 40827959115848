import styled, { css } from 'styled-components';

export const TabsWrapper = styled.div<{ $scrollMode?: 'page' | 'content' }>(
	({ theme, $scrollMode = 'page' }) => css`
		background-color: ${theme.palette.light.main};
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		flex-grow: 1;

		${$scrollMode === 'content' &&
		css`
			overflow-y: auto;
		`}
	`,
);
