import React, { useEffect } from 'react';
import { TitleCommon } from '@warehouse/title/core';
import DialogContent from '@mui/material/DialogContent';
import { Button } from '@warehouse/shared/legacy-ui';
import { useTheme } from '@mui/material/styles';
import styled, { css } from 'styled-components';
import { PaginatorStore } from '@warehouse/shared/ui';
import {
	multiTitleSelectorSelector,
	useMultiTitleSelectorStore,
} from '@warehouse/title-shared/multi-titles-selector-feature/core';
import Dialog from '../../../../src/components/library/Dialog';
import DialogTitle, { CloseButton } from '../../../../src/components/library/DialogTitle';
import DialogActions from '../../../../src/components/library/DialogActions';
import Loader from '../../../../src/components/library/Loader';
import { MultiTitlesSelectorCore } from './MultiTitlesSelectorCore';

interface MultiTitlesSelectorProps {
	isOpen: boolean;
	onClose?: () => void;
	selectableTitles: TitleCommon[];
	onSelectedTitlesChange?: (titles: TitleCommon[]) => void;
	selectedTitles?: TitleCommon[];
	onSubmit?: (selectedTitles: TitleCommon[]) => void;
	isSubmitButtonDisabled?: boolean;
	textHeader: string;
	loading?: boolean;
	searchInputsSlot?: React.ReactNode;
	searchAndSelectedPluralLabel?: string;
	paginationStore: Omit<PaginatorStore, 'internalActions'>;
}

export function MultiTitlesSelector({
	isSubmitButtonDisabled,
	isOpen,
	loading,
	onSubmit,
	onClose,
	textHeader,
	searchAndSelectedPluralLabel = 'titles',
	searchInputsSlot,
	selectableTitles,
	selectedTitles,
	onSelectedTitlesChange,
	paginationStore,
}: MultiTitlesSelectorProps) {
	const theme = useTheme();
	const actions = useMultiTitleSelectorStore(multiTitleSelectorSelector.actions);
	const selectedTitlesFromStore = useMultiTitleSelectorStore(multiTitleSelectorSelector.selectedTitles);

	/**
	 * Keep the store and the props always in sync
	 */
	useEffect(() => {
		if (selectedTitles) actions.selectTitles(selectedTitles);
	}, [actions, selectedTitles]);

	/*
	 * Keep the store and the props always in sync
	 */
	useEffect(() => {
		if (onSelectedTitlesChange) onSelectedTitlesChange(selectedTitlesFromStore);
	}, [onSelectedTitlesChange, selectedTitlesFromStore]);

	return (
		<Dialog fullWidth maxWidth="lg" open={isOpen} onClose={onClose}>
			<DialogTitle>
				{textHeader}
				<CloseButton onClose={() => onClose?.()} />
			</DialogTitle>
			<DialogContent>
				<ContentWrapper>
					<MultiTitlesSelectorCore
						searchInputsSlot={searchInputsSlot}
						searchAndSelectedPluralLabel={searchAndSelectedPluralLabel}
						selectableTitles={selectableTitles}
						loading={loading ?? false}
						paginationStore={paginationStore}
					/>
				</ContentWrapper>
			</DialogContent>
			<DialogActions>
				<Button $fullHeight onClick={onClose}>
					Cancel
				</Button>
				<Button
					$fullHeight
					type="button"
					disabled={loading || isSubmitButtonDisabled || selectedTitlesFromStore.length === 0}
					onClick={() => onSubmit?.(selectedTitlesFromStore)}
					nxstyle="primary-blue"
				>
					{loading ? <Loader size={20} color={theme.palette.light.main} /> : <span>Confirm</span>}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

const ContentWrapper = styled.div(
	() => css`
		display: flex;
		flex-direction: column;
	`,
);
