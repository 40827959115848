import { TitleIcon } from '@warehouse/title/ui';
import React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';
import { CombinedWorkType } from '@warehouse/title-shared/core';
import classNames from 'classnames';
import { CheckIcon } from '../CheckIcon';
import Chip from '../../../../src/components/library/Chip';

interface ColoredTitleCardProps {
	workType: CombinedWorkType;
	title: string;
	subTitle1?: string;
	subTitle2?: string;
	releaseYear: string | undefined;
	minWidth?: number;
	showPrimaryTick: boolean;
	hideReleaseYear: boolean;
	isGrayAndItalicized: boolean;
}

export function ColoredTitleCard({
	minWidth,
	workType,
	title,
	subTitle1,
	subTitle2,
	releaseYear,
	showPrimaryTick,
	hideReleaseYear,
	isGrayAndItalicized,
}: ColoredTitleCardProps) {
	return (
		<Wrapper minWidth={minWidth}>
			<TitleIcon isDimmed={isGrayAndItalicized} combinedWorkType={workType} size={50} iconSize={40} variant="colored" />
			<ContentWrapper className={classNames({ inherited: isGrayAndItalicized })}>
				<Title $hasSubtitle2={!!subTitle1 && !!subTitle2} variant="s2Regular">
					{title}
				</Title>
				<SubTitle $hasSubtitle2={!!subTitle1 && !!subTitle2}>{subTitle1}</SubTitle>
				{subTitle2 && <SubTitle $hasSubtitle2={!!subTitle1 && !!subTitle2}>{subTitle2}</SubTitle>}
			</ContentWrapper>
			{showPrimaryTick && (
				<div style={{ padding: '8px' }} data-testid="primary-tick">
					<CheckIcon />
				</div>
			)}
			{releaseYear && !hideReleaseYear && (
				<ChipWrapper>
					<Chip variant={isGrayAndItalicized ? 'prefilled' : 'default'} chipsWidth={91} label={releaseYear} />
				</ChipWrapper>
			)}
		</Wrapper>
	);
}

const Wrapper = styled.div<{ minWidth?: number }>(
	({ theme, minWidth }) => css`
		align-items: center;
		border: solid 1px ${theme.palette.light.backgroundAlt};
		border-radius: 8px;
		${minWidth &&
		css`
			min-width: ${minWidth}px;
		`}
		display: flex;
		height: 50px;
		overflow: hidden;
		width: 100%;
	`,
);

const Title = styled(Typography)<{ $hasSubtitle2: boolean }>(
	({ $hasSubtitle2 }) => css`
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		${$hasSubtitle2 &&
		css`
			line-height: 20px;
		`}
	`,
);

const SubTitle = styled(Typography)<{ $hasSubtitle2: boolean }>(
	({ theme, $hasSubtitle2 }) => css`
		color: ${theme.palette.text.secondary} !important;
		font-size: 14px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		${$hasSubtitle2 &&
		css`
			font-size: 12px;
			line-height: 12px;
		`}
	`,
);

const ContentWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex: 1;
		flex-direction: column;
		margin: ${theme.spacing(0, 1.5)};
		overflow: hidden;
	`,
);

const ChipWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		border-left: solid 1px ${theme.palette.light.backgroundAlt};
		display: flex;
		height: 48px;
		margin-left: auto;
		padding: ${theme.spacing(0, 1)};
	`,
);
