import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { DialogContent, Typography, useTheme } from '@mui/material';
import Icon from '@mdi/react';
import { mdiCheckboxMarkedCircle, mdiCloseCircle } from '@mdi/js';
import Dialog from './Dialog';
import DialogTitle, { CloseButton } from './DialogTitle';
import DialogActions from './DialogActions';
import Button from './Button';
import Loader from './Loader';

interface ConfirmationModalProps {
	open: boolean;
	title?: string;
	message?: string;
	element?: ReactNode;
	topElement?: ReactNode;
	confirmText?: string;
	onConfirm: () => void;
	onClose: () => void;
	variant?: 'default' | 'danger';
	showButtonIcons?: boolean;
	confirmButtonLoading?: boolean;
	confirmButtonDisabled?: boolean;
}

const TextWrapper = styled.div`
	${({ theme }) => css`
		padding: ${theme.spacing(2)} ${theme.spacing(3)};
		padding-bottom: 0;
	`}
`;

export function ConfirmationModal({
	open,
	onClose,
	message,
	element,
	topElement,
	onConfirm,
	confirmText,
	title,
	variant = 'default',
	showButtonIcons = true,
	confirmButtonLoading = false,
	confirmButtonDisabled = false,
}: ConfirmationModalProps) {
	const theme = useTheme();

	return (
		<Dialog open={open} fullWidth onClose={() => onClose()}>
			{title && (
				<DialogTitle aria-label="confirm-modal-title">
					{title}
					<CloseButton onClose={onClose} />
				</DialogTitle>
			)}
			<DialogContent aria-label="confirm-modal-content">
				{topElement || null}
				{message ? (
					<TextWrapper>
						<Typography>{message}</Typography>
					</TextWrapper>
				) : null}
				{element || null}
			</DialogContent>
			<DialogActions>
				<Button
					nxstyle={variant === 'danger' ? 'secondary-red' : 'secondary-black'}
					endIcon={showButtonIcons ? <Icon path={mdiCloseCircle} size="16px" /> : null}
					onClick={() => onClose()}
				>
					Cancel
				</Button>
				<Button
					nxstyle={variant === 'danger' ? 'primary-red' : 'primary-blue'}
					disabled={confirmButtonDisabled}
					endIcon={showButtonIcons ? <Icon path={mdiCheckboxMarkedCircle} size="16px" /> : null}
					onClick={() => onConfirm()}
				>
					{confirmButtonLoading ? <Loader size={20} color={theme.palette.light.main} /> : confirmText || 'Confirm'}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ConfirmationModal;
