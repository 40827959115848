import { StateCreator } from 'zustand/vanilla';
import {
	createMultiTitleSelectorSlice,
	MultiTitleSelectorStore,
	MultiTitleSelectorStoreContext,
} from '@warehouse/title-shared/multi-titles-selector-feature/core';
import { nxObjectRepositorySingleton } from '@warehouse/object-browser/infra';
import { defineStore } from '@warehouse/shared/util';
import React, { useEffect, useMemo } from 'react';
import { create } from 'zustand';
import { NxObjectFull } from '@warehouse/object-browser/core';

export type AssignTitleStore = MultiTitleSelectorStore & {
	isSubmitting: boolean;
	isPromptOpen: boolean;
	selectedTitleUuidsToUnassign: string[];
	actions: MultiTitleSelectorStore['actions'] & {
		submitTitles(nxObjectFull: NxObjectFull): Promise<NxObjectFull | undefined>;
		openPrompt(): void;
		closePrompt(): void;
		unassignSelectedTitles(nxObjectFull: NxObjectFull): Promise<NxObjectFull | undefined>;
		setSelectedTitlesToUnassign(uuids: string[]): void;
		clearSelectedTitlesToUnassign(): void;
	};
};

export const createAssignTitleStore: StateCreator<AssignTitleStore> = (set, get, store) => {
	const multiTitleSelectorSlice = createMultiTitleSelectorSlice(set, get, store);
	const nxObjectRepository = nxObjectRepositorySingleton.get();

	return {
		...multiTitleSelectorSlice,
		isSubmitting: false,
		isPromptOpen: false,
		selectedTitleUuidsToUnassign: [],
		actions: {
			...multiTitleSelectorSlice.actions,
			openPrompt() {
				set({ isPromptOpen: true });
			},
			closePrompt() {
				set({
					isPromptOpen: false,
					licensorLabelToFilterOn: null,
					selectedTitles: [],
					searchValue: '',
					selectableTitles: [],
					page: 1,
				});
			},
			clearSelectedTitlesToUnassign() {
				set({ selectedTitleUuidsToUnassign: [] });
			},
			setSelectedTitlesToUnassign(uuids: string[]) {
				set({ selectedTitleUuidsToUnassign: uuids });
			},
			async unassignSelectedTitles(nxObjectFull) {
				set({ isSubmitting: true });

				let result: NxObjectFull | undefined;
				try {
					const newAssignedTitlesUuid = nxObjectFull.nxObject.assignedTitles
						.map((t) => t.titleUuid)
						.filter((t) => !get().selectedTitleUuidsToUnassign.find((uuid) => uuid === t));
					result = await nxObjectRepository.assignTitles({
						titleUuids: newAssignedTitlesUuid,
						nxObjectUuid: nxObjectFull.nxObject.uuid,
					});
				} catch (error) {
					console.error(error);
				} finally {
					set({ isSubmitting: false });
					get().actions.clearSelectedTitlesToUnassign();
				}
				return result;
			},
			async submitTitles(nxObjectFull) {
				set({ isSubmitting: true });

				let result: NxObjectFull | undefined;
				try {
					const newAssignedTitlesUuid = Array.from(
						new Set([
							...nxObjectFull.nxObject.assignedTitles.map((t) => t.titleUuid),
							...get().selectedTitles.map((t) => t.uuid),
						]),
					);
					result = await nxObjectRepository.assignTitles({
						titleUuids: newAssignedTitlesUuid,
						nxObjectUuid: nxObjectFull.nxObject.uuid,
					});
					get().actions.closePrompt();
				} catch (error) {
					console.error(error);
				} finally {
					set({ isSubmitting: false });
					get().actions.clearSelectedTitles();
				}
				return result;
			},
		},
	};
};

export const assignTitleStoreSelector = {
	isSubmitting: (state: AssignTitleStore) => state.isSubmitting,
	actions: (state: AssignTitleStore) => state.actions,
	isPromptOpen: (state: AssignTitleStore) => state.isPromptOpen,
	selectedTitleUuidsToUnassign: (state: AssignTitleStore) => state.selectedTitleUuidsToUnassign,
};

export const [_, useAssignTitleStore, AssignTitleStoreContext] = defineStore<AssignTitleStore>(createAssignTitleStore);

function AssignTitlesStoreEffects() {
	const { initialize, destroy } = useAssignTitleStore((state) => state.internalActions);

	useEffect(() => {
		initialize();
		return () => destroy();
	}, [initialize, destroy]);

	return null;
}

export function AssignTitleStoreProvider({ children }: { children: React.ReactNode }) {
	const store = useMemo(() => create<AssignTitleStore>(createAssignTitleStore), []);

	return (
		<AssignTitleStoreContext.Provider value={store}>
			<MultiTitleSelectorStoreContext.Provider value={store}>
				<AssignTitlesStoreEffects />
				{children}
			</MultiTitleSelectorStoreContext.Provider>
		</AssignTitleStoreContext.Provider>
	);
}
