import { apolloAdapter, queryClient } from '@warehouse/shared/infra';
import { Observable } from 'rxjs';
import { Order, SEARCH_TITLE_ENTITIES_V2, SearchInput, SearchTitlesV2Output } from '@warehouse/graphql';
import { singletonFactory } from '@warehouse/shared/util';
import { PaginatedDocuments, QueryObserverResult } from '@warehouse/shared/core';
import { TitleCommon } from '@warehouse/title/core';
import { indexedTitleToTitleCommon } from '@warehouse/title/infra';
import { QueryObserver, QueryObserverOptions } from '@tanstack/react-query';
import {
	computeAPISort,
	computeWildcardSearch,
	formatFilters,
} from '../../../src/utils/hooks/titles/useSearchTitlesV2';
import { SearchTitlesRepository, WatchSearchOptions } from './search-titles.repository';
import { SearchTitlesHashBuilder } from './search-titles-hash-builder';

export class GqlSearchTitlesRepository implements SearchTitlesRepository {
	private _apolloAdapter = apolloAdapter;

	watchSearch(options: WatchSearchOptions): Observable<QueryObserverResult<PaginatedDocuments<TitleCommon>>> {
		const { pollInterval, ...rest } = options;
		return this.#watchObservableQuery({
			queryKey: SearchTitlesHashBuilder.buildHash(options),
			queryFn: () => this.#searchTitles(rest),
			refetchInterval: pollInterval,
		});
	}

	#watchObservableQuery<TData>(options: QueryObserverOptions<TData>) {
		return new Observable<QueryObserverResult<TData, Error>>((observer) => {
			const queryObserver = new QueryObserver<TData>(queryClient, {
				...options,
			});

			return queryObserver.subscribe((result) => {
				observer.next(result);
			});
		});
	}

	async #searchTitles(options: WatchSearchOptions): Promise<PaginatedDocuments<TitleCommon>> {
		const apiSort: Order[] | undefined = computeAPISort(options.sorting);
		const apiFilters = formatFilters(options.filtering);
		const wildcardSearch = computeWildcardSearch(options.searchValue, options.searchFields);

		const response = await this._apolloAdapter.client.query<
			{
				searchTitlesV2: SearchTitlesV2Output;
			},
			{
				search: SearchInput;
			}
		>({
			query: SEARCH_TITLE_ENTITIES_V2,
			variables: {
				search: {
					pagination: { page: options.page, perPage: options.perPage },
					orderBy: apiSort,
					filters: JSON.stringify(apiFilters),
					wildcardSearch,
				},
			},
			pollInterval: options.pollInterval,
		});

		return {
			pagination: {
				currentPage: response.data.searchTitlesV2.page.currentPage,
				perPage: response.data.searchTitlesV2.page.perPage,
				totalDocument: response.data.searchTitlesV2.page.totalDocument,
				totalPage: response.data.searchTitlesV2.page.totalPage,
			},
			documents: response.data.searchTitlesV2.documents.map(indexedTitleToTitleCommon),
		};
	}
}

export const searchTitlesRepositorySingleton = singletonFactory<SearchTitlesRepository>({
	factory: () => new GqlSearchTitlesRepository(),
});
