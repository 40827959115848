import React, { useMemo } from 'react';

// LIBRARY
import { ReadOnlyTypography } from '@warehouse/shared/ui';
import TitleEditableField from '../../TitleEditableField';
import SanitizedHTML from '../../../../views/title/SanitizedHTML';

interface TiniestSummaryFieldProps {
	tiniestSummary: string;
	isInherited: boolean;
}

function TiniestSummary({ isInherited, tiniestSummary }: TiniestSummaryFieldProps) {
	return useMemo(
		() => (
			<TitleEditableField
				label="Synopsis:"
				direction="column"
				fullWidth
				forcedEditMode={false}
				tooltipDisabled
				readComponent={
					<div style={{ overflowY: 'auto', height: 130 }}>
						<ReadOnlyTypography
							variant={isInherited ? 'prefilled' : 'default'}
							style={{
								marginLeft: '6px',
								alignItems: 'flex-start',
								whiteSpace: 'pre-line',
								wordBreak: 'break-word',
							}}
						>
							<SanitizedHTML htmlContent={tiniestSummary} />
						</ReadOnlyTypography>
					</div>
				}
				editComponent={undefined}
			/>
		),
		[tiniestSummary, isInherited],
	);
}

export default TiniestSummary;
