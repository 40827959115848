import React from 'react';
import styled, { css } from 'styled-components';
import { TitleCard } from '@warehouse/title/ui';
import { TitleCommon } from '@warehouse/title/core';
import {
	multiTitleSelectorSelector,
	useMultiTitleSelectorStore,
} from '@warehouse/title-shared/multi-titles-selector-feature/core';
import Checkbox from '../../../../../src/components/library/Checkbox';
import { TitleCardService } from '../../../../title/ui/title-card/title-card.service';

export function TitleListItem({ title }: TitleListItemProps) {
	const actions = useMultiTitleSelectorStore(multiTitleSelectorSelector.actions);
	const isSelected = useMultiTitleSelectorStore(multiTitleSelectorSelector.isTitleSelected(title));

	const onClick = () => {
		if (isSelected) actions.unselectTitle(title);
		else actions.selectTitle(title);
	};

	return (
		<Item tabIndex={0} key={title.uuid} onClick={onClick}>
			<Checkbox checked={isSelected} />
			<TitleCard
				variant="colored"
				isLink={false}
				{...TitleCardService.getTitleCardPropsFromTitleCommonReadonly(title.readonly)}
			/>
		</Item>
	);
}

interface TitleListItemProps {
	title: TitleCommon;
}

const Item = styled.li(
	({ theme }) => css`
		align-items: center;
		box-sizing: border-box;
		cursor: pointer;
		display: flex;
		list-style: none;
		padding: ${theme.spacing(1)} ${theme.spacing(2)};
		padding-left: ${theme.spacing(0.5)};
		pointer-events: auto !important;
		width: 100%;
		z-index: 10 !important;

		&:hover {
			background-color: ${theme.palette.blue.background};
		}
	`,
);
