import { TitleCommon } from '@warehouse/title/core';
import { StateCreator } from 'zustand';

export interface TitleSelectionManagerStore {
	selectedTitles: TitleCommon[];
	actions: {
		selectTitle(title: TitleCommon): void;
		selectTitles(titles: TitleCommon[]): void;
		unselectTitle(title: TitleCommon): void;
		unselectTitles(titles: TitleCommon[]): void;
		clearSelectedTitles(): void;
	};
}

export const createTitleSelectionManagerSlice: StateCreator<TitleSelectionManagerStore> = (set, get) => ({
	selectedTitles: [],
	actions: {
		selectTitle(title: TitleCommon) {
			set({ selectedTitles: [...get().selectedTitles, title] });
		},
		selectTitles(titles: TitleCommon[]) {
			const mergedTitles = [...get().selectedTitles, ...titles];
			const uniqueTitles = mergedTitles.filter(
				(title, index, self) => self.findIndex((t) => t.uuid === title.uuid) === index,
			);

			set({ selectedTitles: uniqueTitles });
		},
		unselectTitle(title: TitleCommon) {
			const filteredTitles = get().selectedTitles.filter(({ uuid }) => uuid !== title.uuid);
			set({ selectedTitles: filteredTitles });
		},
		unselectTitles(titles: TitleCommon[]) {
			const filteredTitles = get().selectedTitles.filter(({ uuid }) => !titles.some((title) => title.uuid === uuid));
			set({ selectedTitles: filteredTitles });
		},
		clearSelectedTitles() {
			set({ selectedTitles: [] });
		},
	},
});

export const titleSelectionManagerSelector = {
	actions: (state: TitleSelectionManagerStore) => state.actions,
	selectedTitles: (state: TitleSelectionManagerStore) => state.selectedTitles,
	isTitleSelected(title: TitleCommon) {
		return (state: TitleSelectionManagerStore) => state.selectedTitles.some(({ uuid }) => title.uuid === uuid);
	},
	areTitlesSelected(titles: TitleCommon[]) {
		return (state: TitleSelectionManagerStore) => {
			if (titles.length === 0 || state.selectedTitles.length === 0) return false;
			return titles.every(({ uuid }) => state.selectedTitles.some((title) => title.uuid === uuid));
		};
	},
};
