import isEqual from 'lodash/isEqual';
import { PaginatorStore, TableManagerStore } from '@warehouse/shared/ui';
import { deepClone, PersistQueryParamsConfig } from '@warehouse/shared/util';
import { GridLogicOperator, NxObjectType, SearchMode } from '@warehouse/object-browser/core';
import { objectBrowserTableColumns } from '../feature-object-browser/columns';
import { WildCardSearchStore } from '../feature-wild-card-search/domain';
import {
	LicensorColumnName,
	NameColumnName,
	TitleColumnName,
	TitleIdsColumnName,
} from '../feature-object-browser/specific-column-names';
import { ObjectBrowserStore } from './object-browser.type';
import { ObjectBrowserDrawerManagerStore } from '../feature-nx-object-drawer/domain/object-browser-drawer-manager.store';

export const paginatorStoreConfig: PersistQueryParamsConfig<PaginatorStore> = {
	page: {
		type: 'number',
		defaultValue: 1,
	},
	perPage: {
		type: 'number',
		defaultValue: 50,
		sessionStorageKeyPrefix: 'object-browser',
	},
};

export const wildCardSearchStoreConfig: PersistQueryParamsConfig<WildCardSearchStore> = {
	searchQuery: {
		type: 'string',
		defaultValue: '',
		sessionStorageKeyPrefix: 'object-browser',
	},
	searchMode: {
		type: 'string',
		defaultValue: SearchMode.THIS_FOLDER,
		sessionStorageKeyPrefix: 'object-browser',
	},
};

export const tableManagerStoreConfig: PersistQueryParamsConfig<TableManagerStore> = {
	sort: {
		type: 'array',
		defaultValue: [{ field: 'name', sort: 'asc' }],
		sessionStorageKeyPrefix: 'object-browser',
	},
	visibility: {
		type: 'object',
		keyName: 'column-visibility',
		defaultValue: {
			[NameColumnName]: true,
			[LicensorColumnName]: true,
			[TitleColumnName]: true,
			bucket: false,
			fileSize: true,
			itemType: true,
			storageTier: true,
			lastModifiedAt: true,
			eTag: false,
			online: false,
			key: false,
			s3Uri: false,
			uuid: false,
			uploadedByOrganization: false,
			uploadedByUser: false,
			[TitleIdsColumnName]: false,
		},
		sessionStorageKeyPrefix: 'object-browser',
	},
	pinned: {
		type: 'object',
		keyName: 'pinned-columns',
		defaultValue: {
			left: ['itemType', 'name'],
		},
		sessionStorageKeyPrefix: 'object-browser',
	},
	order: {
		type: 'array',
		keyName: 'column-order',
		defaultValue: objectBrowserTableColumns.map((c) => c.field),
		sessionStorageKeyPrefix: 'object-browser',
	},
	filter: {
		type: 'object',
		keyName: 'filters',
		defaultValue: {
			items: [{ field: 'online', operator: 'is', value: true }],
			logicOperator: GridLogicOperator.AND,
		},
		sessionStorageKeyPrefix: 'object-browser',
		customSerializer: {
			isDefault(value, defaultValue) {
				const cleanedValue = deepClone(value);
				cleanedValue.items.forEach((_, idx) => {
					// this is a non-typed key from muix internal type
					delete (cleanedValue.items[idx] as any).fromInput;
					delete cleanedValue.items[idx].id;
				});
				return isEqual(cleanedValue, defaultValue);
			},
		},
	},
};

export const titlesObjectTabObjectBrowserStoreConfig: PersistQueryParamsConfig<TableManagerStore> = {
	...tableManagerStoreConfig,
	filter: {
		...tableManagerStoreConfig.filter,
		defaultValue: {
			items: [
				{ field: 'online', operator: 'is', value: true },
				{ field: 'itemType', operator: 'is', value: NxObjectType.File },
			],
			logicOperator: GridLogicOperator.AND,
		},
	},
	visibility: {
		...tableManagerStoreConfig.visibility,
		defaultValue: {
			...tableManagerStoreConfig.visibility?.defaultValue,
			[LicensorColumnName]: false,
			[TitleColumnName]: false,
			key: true,
		},
	},
	sort: {
		type: 'array',
		defaultValue: [{ field: 'name', sort: 'asc' }],
		sessionStorageKeyPrefix: 'object-browser',
	},
};

export const objectBrowserStoreConfig: PersistQueryParamsConfig<ObjectBrowserStore> = {
	selectedNxObjectUuid: {
		type: 'string',
		defaultValue: '',
		sessionStorageKeyPrefix: 'object-browser',
	},
};

export const objectBrowserDrawerManagerStoreConfig: PersistQueryParamsConfig<ObjectBrowserDrawerManagerStore> = {
	isNxObjectDrawerOpen: {
		type: 'boolean',
		defaultValue: false,
		sessionStorageKeyPrefix: 'object-browser',
	},
};
