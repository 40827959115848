import { defineStore } from '@warehouse/shared/util';
import { isPrimaryEdit, isUnknownInherited, TitleFull, titlePaths } from '@warehouse/title/core';
import { WorkType } from '@warehouse/title-shared/core';
import { StateCreator } from 'zustand/vanilla';
import isEqual from 'lodash/isEqual';
import { editPreventerRepositorySingleton } from './EditPreventer.repository.singleton';
import { EditPreventerVariant } from './EditPreventerVariant';
import { JsonPointer } from '../../../src/utils/getDeepProperty';

interface EditPreventerPopupStore {
	open: boolean;
	confirm: () => void;
	deny: () => void;
	variant: EditPreventerVariant;
	actions: {
		disablePopup: () => void;
		act: (args: {
			current: unknown;
			save: () => void;
			onDenied: () => void;
			title: TitleFull;
			path: JsonPointer;
		}) => void;
	};
}

const L2_LOCAL_FIELDS_PATHS = [
	titlePaths.altIdentifiers,
	titlePaths.editClasses,
	titlePaths.editUse,
	titlePaths.versionIntent, // for both madeForRegions and madeForRegionsExclude
	titlePaths.versionLanguages,
];

const isLocalFieldPath = (pathToCheck: JsonPointer) =>
	L2_LOCAL_FIELDS_PATHS.some((localFieldPath) => isEqual(pathToCheck, localFieldPath.split('.')));

export const stateCreator: StateCreator<EditPreventerPopupStore> = (set, get) => {
	const repository = editPreventerRepositorySingleton.get();
	const disabledStatus: Record<EditPreventerVariant, boolean> = {
		'primary-edit': false,
		'inherited-l2': false,
	};

	Promise.all([
		repository.getPopupDisableStatus('primary-edit'),
		repository.getPopupDisableStatus('inherited-l2'),
	]).then(([primaryEditStatus, inheritedL2Status]) => {
		disabledStatus['primary-edit'] = primaryEditStatus;
		disabledStatus['inherited-l2'] = inheritedL2Status;
	});

	const shouldSaveDirectly = ({
		current,
		title,
		path,
	}: {
		current: unknown;
		title: TitleFull;
		path: JsonPointer;
	}): boolean => {
		if (get().variant === 'primary-edit') {
			if (isLocalFieldPath(path)) return true;
			return disabledStatus['primary-edit'];
		}

		// else the variant is inherited-l2
		const { workType } = title.readonly;
		return (
			disabledStatus['inherited-l2'] ||
			!isUnknownInherited(current) ||
			![WorkType.Edit, WorkType.Manifestation].includes(workType)
		);
	};

	const handleConfirmationFlow = ({
		save,
		onDenied,
		openPopup,
		closePopup,
	}: {
		save: () => void;
		onDenied: () => void;
		openPopup: () => void;
		closePopup: () => void;
	}) => {
		set({
			confirm: () => {
				save();
				closePopup();
			},
			deny: () => {
				onDenied();
				closePopup();
			},
		});
		openPopup();
	};

	const openPopup = () => set({ open: true });
	const closePopup = () => set({ open: false });

	return {
		open: false,
		confirm: () => {},
		deny: () => {},
		variant: 'primary-edit',
		actions: {
			disablePopup: () => {
				repository.disablePopup(get().variant);
				disabledStatus[get().variant] = true;
			},
			act: ({ current, save, onDenied, title, path }) => {
				const variant: EditPreventerVariant = isPrimaryEdit(title) ? 'primary-edit' : 'inherited-l2';
				set({ variant });
				if (shouldSaveDirectly({ current, title, path })) {
					save();
				} else {
					handleConfirmationFlow({ save, onDenied, openPopup, closePopup });
				}
			},
		},
	};
};

export const [EditPreventerPopupStoreProvider, useEditPreventerPopupStore] =
	defineStore<EditPreventerPopupStore>(stateCreator);

export const editPreventerPopupStoreSelector = {
	actions: (state: EditPreventerPopupStore) => state.actions,
	open: (state: EditPreventerPopupStore) => state.open,
	confirm: (state: EditPreventerPopupStore) => state.confirm,
	deny: (state: EditPreventerPopupStore) => state.deny,
	variant: (state: EditPreventerPopupStore) => state.variant,
};
