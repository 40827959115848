import { NxObjectFilter, NxObjectFilterQuery } from '@warehouse/shared/filters';
import { InfraResourceIdentifier, SearchMode } from '@warehouse/object-browser/core';
import compact from 'lodash/compact';
import { components, operations } from './inventory.openapi';

type HttpFilterQuery = Exclude<
	Exclude<operations['post-search']['requestBody'], undefined>['content']['application/json']['filters'],
	undefined
>;

type OptionalHttpFilterQuery = HttpFilterQuery | undefined;

type HttpFilter = Exclude<HttpFilterQuery['and'], undefined>[number];

type SearchFilters = components['schemas']['SearchFilters'];

export class NxObjectFiltersBuilder {
	static buildFilters({
		filters,
		resourceIdentifier,
		searchMode,
		assignedTitleUuid,
	}: {
		filters: NxObjectFilterQuery | undefined;
		resourceIdentifier: InfraResourceIdentifier | undefined;
		searchMode: SearchMode | undefined;
		assignedTitleUuid?: string | undefined;
	}): OptionalHttpFilterQuery {
		if (searchMode === 'EVERYWHERE')
			return NxObjectFiltersBuilder.#buildEverywhereSearchModeFilters({ filters, assignedTitleUuid });
		if (searchMode === 'THIS_FOLDER')
			return NxObjectFiltersBuilder.#buildThisFolderSearchModeFilters({
				resourceIdentifier,
				filters,
				assignedTitleUuid,
			});
		return NxObjectFiltersBuilder.#buildDefaultFilters({ resourceIdentifier, filters });
	}

	static #buildEverywhereSearchModeFilters({
		filters,
		assignedTitleUuid,
	}: {
		filters: NxObjectFilterQuery | undefined;
		assignedTitleUuid: string | undefined;
	}): OptionalHttpFilterQuery {
		const andArray: HttpFilter[] = [];
		const orArray: HttpFilter[] = [];
		if (assignedTitleUuid)
			andArray.push({ field: 'assignedTitles.titleUuid', operator: 'equals', value: assignedTitleUuid });

		if (filters) {
			if (filters.combinationOperator === 'AND') andArray.push(...filters.filters.map(this.#filterToHttpFilter));
			if (filters.combinationOperator === 'OR') orArray.push(...filters.filters.map(this.#filterToHttpFilter));
		}

		const and = andArray.length ? andArray : undefined;
		const or = orArray.length ? orArray : undefined;

		if (!and && !or) return undefined;

		return { and, or };
	}

	static #buildThisFolderSearchModeFilters({
		resourceIdentifier,
		filters,
		assignedTitleUuid,
	}: {
		resourceIdentifier: InfraResourceIdentifier | undefined;
		filters: NxObjectFilterQuery | undefined;
		assignedTitleUuid: string | undefined;
	}): OptionalHttpFilterQuery {
		const andArray: HttpFilter[] = [];
		const orArray: HttpFilter[] = [];

		andArray.push(...this.#getThisFolderFilters(resourceIdentifier));

		if (filters) {
			if (filters.combinationOperator === 'AND') andArray.push(...filters.filters.map(this.#filterToHttpFilter));
			if (filters.combinationOperator === 'OR') orArray.push(...filters.filters.map(this.#filterToHttpFilter));
		}

		if (assignedTitleUuid)
			andArray.push({ field: 'assignedTitles.titleUuid', operator: 'equals', value: assignedTitleUuid });

		const and = andArray.length ? andArray : undefined;
		const or = orArray.length ? orArray : undefined;

		if (!and && !or) return undefined;

		return { and, or };
	}

	static #buildDefaultFilters({
		resourceIdentifier,
		filters,
	}: {
		resourceIdentifier: InfraResourceIdentifier | undefined;
		filters: NxObjectFilterQuery | undefined;
	}): OptionalHttpFilterQuery {
		const resourceIdentifierFilter = this.#getResourceIdentifierFilters(resourceIdentifier);

		// If we don't have any filters, we just filters on resource identifier
		if (!filters) {
			return {
				and: resourceIdentifierFilter,
			};
		}

		// If the filters are combined with OR, we need to add the resource identifier filters to the AND part
		if (filters.combinationOperator === 'OR') {
			return {
				or: filters.filters.map(this.#filterToHttpFilter),
				and: resourceIdentifierFilter,
			};
		}

		// If the filters are combined with AND, we need to add the resource identifier filters to the AND part
		return {
			and: compact([...(resourceIdentifierFilter || []), ...filters.filters.map(this.#filterToHttpFilter)]),
		};
	}

	static #getThisFolderFilters(resourceIdentifier: InfraResourceIdentifier | undefined): SearchFilters {
		if (!resourceIdentifier) return [];

		return resourceIdentifier.type === 'bucket'
			? [
					{
						field: 'bucket',
						operator: 'equals',
						value: resourceIdentifier.bucketId,
					},
				]
			: [
					{
						field: 'folder',
						operator: 'startsWith',
						value: resourceIdentifier.folderPath,
					},
					{
						field: 'bucket',
						operator: 'equals',
						value: resourceIdentifier.bucketId,
					},
				];
	}

	static #getResourceIdentifierFilters(
		resourceIdentifier: InfraResourceIdentifier | undefined,
	): SearchFilters | undefined {
		if (!resourceIdentifier) return [];

		return resourceIdentifier.type === 'bucket'
			? [
					{
						field: 'bucket',
						operator: 'equals',
						value: resourceIdentifier.bucketId,
					},
					{
						field: 'folder',
						operator: 'equals',
						value: '/',
					},
				]
			: [
					{
						field: 'folder',
						operator: 'equals',
						value: resourceIdentifier.folderPath,
					},
				];
	}

	static #filterToHttpFilter(filter: NxObjectFilter): HttpFilter {
		return {
			...filter,
			value: filter.value instanceof Date ? filter.value.toISOString() : filter.value,
		};
	}
}
