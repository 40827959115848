import React from 'react';
import styled, { css } from 'styled-components';

export function UnmanagedOverlay() {
	return (
		<StyledGridOverlay>
			<p>Unmanaged</p>
		</StyledGridOverlay>
	);
}

const StyledGridOverlay = styled.div(
	() => css`
		align-items: center;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
	`,
);
