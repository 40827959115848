import React, { useState, MouseEventHandler, useCallback, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Icon from '@mdi/react';
import { mdiContentCopy, mdiCheck } from '@mdi/js';
import { Tooltip } from '@mui/material';

export interface CopyButtonProps {
	onClick?: MouseEventHandler<HTMLDivElement>;
	resetTime?: number;
}

const RESET_TIME = 2000;

export function CopyButton({ onClick, resetTime }: CopyButtonProps) {
	const [isActive, setIsActive] = useState(false);
	const timeoutRef = useRef<number | null>(null);

	// clear timeout on unmount
	useEffect(
		() => () => {
			if (timeoutRef.current) window.clearTimeout(timeoutRef.current);
		},
		[],
	);

	const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(
		(e) => {
			if (onClick) onClick(e);
			setIsActive(true);
			if (timeoutRef.current) window.clearTimeout(timeoutRef.current);
			timeoutRef.current = window.setTimeout(() => {
				setIsActive(false);
			}, resetTime ?? RESET_TIME);
		},
		[onClick, resetTime],
	);

	return (
		<Tooltip title="Copied!" open={isActive}>
			<IconWrapper onClick={handleClick} className="copyIcon">
				<StyledIcon path={!isActive ? mdiContentCopy : mdiCheck} size="14px" $isActive={isActive} />
			</IconWrapper>
		</Tooltip>
	);
}

const IconWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		cursor: pointer;
		display: flex;
		left: ${theme.spacing(-2.5)};
		opacity: 0;
		position: absolute;
		visibility: hidden;
	`,
);

const StyledIcon = styled(Icon)<{ $isActive: boolean }>(
	({ $isActive, theme }) => css`
		color: ${!$isActive ? theme.palette.text.secondary : theme.palette.success.main};

		${!$isActive &&
		css`
			&:hover {
				color: ${theme.palette.info.main};
			}
		`}
	`,
);
