import React from 'react';
import { GridCell, PaginationNavigatorCore, PaginatorStore } from '@warehouse/shared/ui';
import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';
import { TitleCommon } from '@warehouse/title/core';
import { SelectedTitles } from './selected-titles/SelectedTitles';
import { TitleListActionsTopBar } from './title-list/TitleListActionsTopBar';
import { TitleList } from './title-list/TitleList';

interface MultiTitlesSelectorCoreProps {
	searchAndSelectedPluralLabel: string;
	searchInputsSlot?: React.ReactNode;
	selectableTitles: TitleCommon[];
	loading: boolean;
	paginationStore: Omit<PaginatorStore, 'internalActions'>;
}

export function MultiTitlesSelectorCore({
	searchInputsSlot,
	searchAndSelectedPluralLabel,
	selectableTitles,
	loading,
	paginationStore,
}: MultiTitlesSelectorCoreProps) {
	return (
		<Wrapper>
			<GridCell gridArea="topbarTitle" spacing={2}>
				<Typography variant="h4Regular">{`Search for ${searchAndSelectedPluralLabel}`}</Typography>
			</GridCell>
			<GridCell gridArea="topbarSelected" spacing={2}>
				<Typography variant="h4Regular">{`Selected ${searchAndSelectedPluralLabel}`}</Typography>
			</GridCell>
			<SearchWrapper>
				<HeaderWrapper>{searchInputsSlot}</HeaderWrapper>
				<TitleListActionsTopBar totalCount={paginationStore.totalCount} titles={selectableTitles} />
			</SearchWrapper>
			<TitleList loading={loading} titles={selectableTitles} />
			<PaginationNavigatorCore centered {...paginationStore} />
			<SelectedTitles workTypePluralLabel={searchAndSelectedPluralLabel} />
		</Wrapper>
	);
}

const Wrapper = styled.div(
	({ theme }) => css`
		background-color: ${theme.palette.light.main};
		display: grid;
		grid-template-areas:
			'topbarTitle topbarSelected'
			'searchBar selectedItems'
			'resultsItems selectedItems'
			'pagination selectedItems';
		grid-template-columns: 65% 35%;
		grid-template-rows: 60px 110px 40vh 50px;

		width: 100%;

		> * {
			border-bottom: 1px solid ${theme.palette.light.backgroundAlt};
			border-right: 1px solid ${theme.palette.light.backgroundAlt};
		}
	`,
);

const SearchWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		margin: 0;
		padding: ${theme.spacing(2)};
	`,
);

const HeaderWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		flex: 1;
		gap: ${theme.spacing(2)};
	`,
);
