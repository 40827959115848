import React from 'react';
import styled, { css } from 'styled-components';
import { AssignedTitles } from '@warehouse/object-browser/feature-show-assigned-titles';
import { GeneralInfos } from './GeneralInfos';

export function GeneralTab() {
	return (
		<Wrapper>
			<GeneralInfos />
			<AssignedTitles />
		</Wrapper>
	);
}

const Wrapper = styled.div(
	() => css`
		display: flex;
		flex-direction: column;
	`,
);
