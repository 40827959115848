import { getRelationshipType } from '@warehouse/title/infra';

export function isDerivedFrom() {
	return 'isderivedfrom';
}

export function isEpisodeOf() {
	return 'isepisodeof';
}

export function isSeasonOf() {
	return 'isseasonof';
}

export function isBasedOn() {
	return 'isbasedon';
}

export function isClipOf() {
	return 'isclipof';
}

export function isCompositeOf() {
	return 'iscompositeof';
}

export function isDescendentOf() {
	return 'isdescendentof';
}

export function isPromotionFor() {
	return 'ispromotionfor';
}

export function isSupplementTo() {
	return 'issupplementto';
}

export function isEntryOf() {
	return 'isentryof';
}

export enum RelationshipType {
	BasedOn = 'isbasedon',
	ClipOf = 'isclipof',
	CompositeOf = 'iscompositeof',
	DerivedFrom = 'isderivedfrom',
	DescendentOf = 'isdescendentof',
	EpisodeOf = 'isepisodeof',
	PromotionFor = 'ispromotionfor',
	SeasonOf = 'isseasonof',
	SupplementTo = 'issupplementto',

	// EntryOf is a special relationship type that is not explicitly part of the relationshipType backend enum.
	// It is used to represent a relationship between a Compilation and its children, stored in the children array.
	// Do not send it to the backend in the parent array of a child title.
	EntryOf = 'isentryof',
}

export interface RelationshipTypeObject {
	relationshipType: RelationshipType;
	relationshipTypeId: string;
	label: string;
	labelTarget: string;
}

export function isPrimaryAndDerivedFrom(parents: { relationshipType: string; primary?: boolean }[]) {
	const derivedFromPrimaryParent = parents.find((parent) => {
		const relationshipType = getRelationshipType(parent.relationshipType);
		return relationshipType === RelationshipType.DerivedFrom && parent.primary;
	});

	return !!derivedFromPrimaryParent;
}

export class RelationshipTypeService {
	static relationshipTypeStringToEnum(rt: string): RelationshipType {
		const item = Object.values(RelationshipType).find((value) => value === rt);
		if (!item) throw new Error(`Unknown relationship type ${rt}`);
		return item;
	}
}
