import { CombinedWorkType, isParentAbstractionWorkTypeAndWorkType, WorkType } from '@warehouse/title-shared/core';
import { assertUnreachable } from '@warehouse/shared/util';

// Movie
import AbstractionMovie from './title-icons/movie/movie_abstraction_black.svg';
import AbstractionMovieColored from './title-icons/movie/movie_abstraction_orange.svg';
import EditMovie from './title-icons/movie/movie_edit_black.svg';
import EditMovieColored from './title-icons/movie/movie_edit_blue.svg';
import ManifestationMovie from './title-icons/movie/movie_manifestation_black.svg';
import ManifestationMovieColored from './title-icons/movie/movie_manifestation_red.svg';

// Episode
import AbstractionEpisode from './title-icons/episode/episode_abstraction_black.svg';
import AbstractionEpisodeColored from './title-icons/episode/episode_abstraction_orange.svg';
import EditEpisode from './title-icons/episode/episode_edit_black.svg';
import EditEpisodeColored from './title-icons/episode/episode_edit_blue.svg';
import ManifestationEpisode from './title-icons/episode/episode_manifestation_black.svg';
import ManifestationEpisodeColored from './title-icons/episode/episode_manifestation_red.svg';

// Season
import AbstractionSeason from './title-icons/season/season_abstraction_black.svg';
import AbstractionSeasonColored from './title-icons/season/season_abstraction_orange.svg';
import ManifestationSeason from './title-icons/season/season_manifestation_black.svg';
import ManifestationSeasonColored from './title-icons/season/season_manifestation_red.svg';

// Series
import AbstractionSeries from './title-icons/series/series_abstraction_black.svg';
import AbstractionSeriesColored from './title-icons/series/series_abstraction_orange.svg';
import ManifestationSeries from './title-icons/series/series_manifestation_black.svg';
import ManifestationSeriesColored from './title-icons/series/series_manifestation_red.svg';

// Supplemental
import AbstractionSupplemental from './title-icons/supplemental/supplemental_abstraction_black.svg';
import AbstractionSupplementalColored from './title-icons/supplemental/supplemental_abstraction_orange.svg';
import EditSupplemental from './title-icons/supplemental/supplemental_edit_black.svg';
import EditSupplementalColored from './title-icons/supplemental/supplemental_edit_blue.svg';
import ManifestationSupplemental from './title-icons/supplemental/supplemental_manifestation_black.svg';
import ManifestationSupplementalColored from './title-icons/supplemental/supplemental_manifestation_red.svg';

// Compilation
import AbstractionCompilation from './title-icons/compilation/compilation_abstraction_black.svg';
import AbstractionCompilationColored from './title-icons/compilation/compilation_abstraction_orange.svg';
import ManifestationCompilation from './title-icons/compilation/compilation_manifestation_black.svg';
import ManifestationCompilationColored from './title-icons/compilation/compilation_manifestation_red.svg';

export enum Color {
	Colored = 'colored',
	Black = 'black',
}

export enum Category {
	Abstraction = 'abstraction',
	Edit = 'edit',
	Manifestation = 'manifestation',
}

export const IconMap = {
	[Color.Black]: {
		[Category.Abstraction]: {
			[WorkType.Movie]: AbstractionMovie,
			[WorkType.Episode]: AbstractionEpisode,
			[WorkType.Season]: AbstractionSeason,
			[WorkType.Compilation]: AbstractionCompilation,
			[WorkType.Supplemental]: AbstractionSupplemental,
			[WorkType.Series]: AbstractionSeries,
		},
		[Category.Edit]: {
			[WorkType.Movie]: EditMovie,
			[WorkType.Supplemental]: EditSupplemental,
			[WorkType.Episode]: EditEpisode,
		},
		[Category.Manifestation]: {
			[WorkType.Movie]: ManifestationMovie,
			[WorkType.Episode]: ManifestationEpisode,
			[WorkType.Supplemental]: ManifestationSupplemental,
			[WorkType.Series]: ManifestationSeries,
			[WorkType.Compilation]: ManifestationCompilation,
			[WorkType.Season]: ManifestationSeason,
		},
	},
	[Color.Colored]: {
		[Category.Abstraction]: {
			[WorkType.Movie]: AbstractionMovieColored,
			[WorkType.Episode]: AbstractionEpisodeColored,
			[WorkType.Season]: AbstractionSeasonColored,
			[WorkType.Compilation]: AbstractionCompilationColored,
			[WorkType.Supplemental]: AbstractionSupplementalColored,
			[WorkType.Series]: AbstractionSeriesColored,
		},
		[Category.Edit]: {
			[WorkType.Movie]: EditMovieColored,
			[WorkType.Supplemental]: EditSupplementalColored,
			[WorkType.Episode]: EditEpisodeColored,
		},
		[Category.Manifestation]: {
			[WorkType.Movie]: ManifestationMovieColored,
			[WorkType.Episode]: ManifestationEpisodeColored,
			[WorkType.Supplemental]: ManifestationSupplementalColored,
			[WorkType.Series]: ManifestationSeriesColored,
			[WorkType.Compilation]: ManifestationCompilationColored,
			[WorkType.Season]: ManifestationSeasonColored,
		},
	},
};

export function getIconByEntityNameAndTitleType(combinedWorkType: CombinedWorkType, color: Color): string {
	if (isParentAbstractionWorkTypeAndWorkType(combinedWorkType)) {
		const { parentAbstractionWorkType, workType } = combinedWorkType;

		switch (workType) {
			case WorkType.Edit:
				return IconMap[color][Category.Edit][parentAbstractionWorkType];
			case WorkType.Manifestation:
				return IconMap[color][Category.Manifestation][parentAbstractionWorkType];
			default:
				assertUnreachable(workType);
		}
	} else {
		return IconMap[color][Category.Abstraction][combinedWorkType];
	}

	assertUnreachable(combinedWorkType);
	return IconMap[Color.Black][Category.Abstraction][WorkType.Movie];
}
