import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import { useLocation, useNavigate } from 'react-router-dom';
import { Divider, useTheme } from '@mui/material';
import { AssociatedOrg } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';

// TYPES
import {
	createTitleInputToInfraInput,
	episodeUuid,
	getRelationshipType,
	getRelationshipTypeObject,
	getRelationshipTypePerWorkTypeName,
	getWorkType,
	getWorkTypeObject,
	indexedTitleToTitleCommon,
	seasonUuid,
	supplementalUuid,
} from '@warehouse/title/infra';
import { isDerivedFrom, isEpisodeOf, isSeasonOf, RelationshipType } from '@warehouse/title/core';
import { replaceUUID } from '@warehouse/shared/util';
import { IndexedTitleV2 } from '@warehouse/graphql';

// HOOKS
import { useTitle } from '@warehouse/title/domain';
import { batchCreateStoreSelector, useBatchCreateStore } from '@warehouse/title/feature-create-titles';
import { hasRequiredParent, WorkType } from '@warehouse/title-shared/core';
import useCreateTitleV2 from '../../../utils/hooks/titles/useCreateTitleV2';
import useWorkTypes from '../../../utils/hooks/titles/useWorkTypes';
import useFieldsByTitleTypeName from './useFieldsByTitleTypeName';
import useLicensors from '../../../utils/hooks/licensor/useLicensors';
import useDryRun from '../useDryRun';
import { useColumnsMetadata, useGeneratedColumns } from './columns';

// UTILS
import { validateTitlesData } from './validation';

// LIBRARY
import { TitleCreationSummary } from './TitleCreationSummary';
import Alert from '../../../components/library/Alert';
import WorkTypeSelector from './WorkTypeSelector';
import WorkTypeDetailsSelector from './WorkTypeDetailsSelector';
import ParentTitleSelector from './ParentTitleSelector';
import LicensorSelector from './LicensorSelector';
import Loader from '../../../components/library/Loader';
import Button from '../../../components/library/Button';
import SimpleTable from '../../../components/library/SimpleTable';
import RelationshipTypeField from './RelationshipType';

// JSON
import availableWorkTypeDetailsByWorkType from '../../../assets/json-administration-profiles/availableWorkTypeDetailsByWorkType.json';
import { ErrorHandlingMode } from '../../../components/library/SimpleTable/ErrorHandlingMode';
import { filterRowsByIndex } from '../../../components/library/SimpleTable/tableRowUtils';
import { fromBasic, getTitleData } from '../../../utils/titleGetProperty';
import { useChildrenTabs } from '../../title/hooks/useChildrenTabs';
import { TopBarLegacy } from '../../title/TopBarLegacy';
import { RelationshipModeHeader } from './RelationshipModeHeader';
import NxLoader from '../../../components/library/NxLoader';
import { titleCreationFormDataToCreateTitleInputData } from '../../../../libs/title/feature-create-titles/title-creation-form-data.service';

const workTypeDetailsIsDisplayed = ['Movie', 'Compilation', 'Episode', 'Supplemental'];

const parentFieldIsDisplayedAndRequired = {
	Movie: { displayed: false, required: false },
	Season: { displayed: true, required: true },
	Series: { displayed: false, required: false },
	Edit: { displayed: true, required: true },
	Compilation: { displayed: false, required: false },
	Episode: { displayed: true, required: true },
	Supplemental: { displayed: true, required: false },
	Manifestation: { displayed: true, required: true },
};

function CreateMultipleTitlesView() {
	const navigate = useNavigate();

	const titlesData = useBatchCreateStore(batchCreateStoreSelector.titlesCreationFormData);
	const isTableReadyToDisplay = useBatchCreateStore(batchCreateStoreSelector.isTableReadyToDisplay);
	const selectedWorkType = useBatchCreateStore(batchCreateStoreSelector.selectedWorkType);
	const isPendingDryRun = useBatchCreateStore(batchCreateStoreSelector.isPendingDryRun);
	const storeDryRanTitle = useBatchCreateStore(batchCreateStoreSelector.dryRanTitle);

	const {
		setDryRanTitle,
		selectWorkType: setSelectedWorkType,
		selectParent,
		clearParent,
		setTitlesData,
		addTitleRows,
	} = useBatchCreateStore(batchCreateStoreSelector.actions);

	const [loading, setLoading] = useState<boolean>(true);
	const [titleCreationSummaryOpen, setTitleCreationSummaryOpen] = useState<boolean>(false);
	const [titlesErrors, setTitlesErrors] = useState<{
		[columnId: string]: number[];
	}>({});
	const [parentTitleErrors, setParentTitleErrors] = useState<string>('');
	const [creationResult, setCreationResult] = useState<PromiseSettledResult<any>[]>([]);
	const [relationshipType, setRelationshipType] = useState<RelationshipType | undefined>();
	const relationshipTypeId = useMemo(
		() => (relationshipType ? getRelationshipTypeObject(relationshipType).relationshipTypeId : undefined),
		[relationshipType],
	);
	const [createTitleV2] = useCreateTitleV2();
	const { getNameByUuid, getWorkTypesByRelationshipSource } = useWorkTypes();
	const { getDefault: getDefaultLicensor, loading: licensorLoading } = useLicensors();
	const selectedWorkTypeId = useMemo(
		() => (selectedWorkType ? getWorkTypeObject(selectedWorkType).uuid : undefined),
		[selectedWorkType],
	);
	const selectedWorkTypeName = useMemo(
		() => (selectedWorkType ? getWorkTypeObject(selectedWorkType).label : 'All'),
		[selectedWorkType],
	);
	const fields = useFieldsByTitleTypeName(selectedWorkTypeName);
	const [workTypeDetails, setWorkTypeDetails] = useState<string | undefined>();
	const [licensor, setLicensor] = useState('');
	const [parentType, setParentType] = useState<string | undefined>('all');
	const [parentTitle, setParentTitle] = useState<IndexedTitleV2 | undefined>();
	const { state } = useLocation();
	const forcedParentUuid = state?.selectedParentUuid;
	const forcedWorkTypeUuid = state?.selectedTitleTypeUuid;
	const createTitleMode = state?.createTitleMode;
	if (createTitleMode && !['relationships'].includes(createTitleMode))
		throw new Error(`Invalid createTitleMode ${createTitleMode}`);
	const theme = useTheme();

	const forcedParentQuery = useTitle({
		skipable: true,
		uuid: forcedParentUuid,
	});
	const forcedParent = forcedParentQuery.data;

	const defaultLicensor = useMemo(() => {
		if (forcedParentUuid) {
			const associatedOrgs = getTitleData<AssociatedOrg[]>(forcedParent, fromBasic(['associatedOrgs', 'displayValue']));
			return associatedOrgs?.find((ao) => ao.role === 'licensor');
		}
		return getDefaultLicensor();
	}, [forcedParentUuid, getDefaultLicensor, forcedParent]);

	const tabs = useChildrenTabs(forcedParent);

	const childrenProperties = useMemo(() => {
		if (!forcedWorkTypeUuid) return undefined;
		return tabs.find(({ childWorkTypeUuid }) => childWorkTypeUuid === forcedWorkTypeUuid);
	}, [forcedWorkTypeUuid, tabs]);

	const workTypeOptions = useMemo(
		() =>
			childrenProperties?.relationshipType
				? getWorkTypesByRelationshipSource(childrenProperties.relationshipType)
				: undefined,
		[childrenProperties?.relationshipType],
	);

	const getLocalizedInfos = () => {
		if (selectedWorkType && hasRequiredParent(selectedWorkType) && (parentTitle || forcedParent)) {
			return [];
		}

		return [
			{
				default: true,
				titleDisplayUnlimited: titlesData?.[0]?.title,
				language: titlesData?.[0]?.titleLanguage || '8fa1f157-131a-4461-93fa-acf6ed5a18f5', // English (US) - en-US
			},
		];
	};

	const memoizedPayload = useMemo(
		() => ({
			metadata: {
				coreMetadata: {
					basic: {
						workTypeDetails,
						releaseYear: undefined,
						localizedInfos: getLocalizedInfos(),
						parents:
							parentTitle || forcedParent
								? [
										{
											primary: true,
											parentContentId: parentTitle?.uuid || forcedParent?.uuid,
											relationshipType: getRelationshipTypePerWorkTypeName(
												getNameByUuid(selectedWorkTypeId || '') || '',
											),
										},
									]
								: undefined,
						workType: selectedWorkTypeId,
						terms: {
							titleStatus: undefined,
						},
					},
				},
			},
		}),
		[
			selectedWorkTypeId,
			parentTitle,
			forcedParent,
			workTypeDetails,
			titlesData?.[0]?.title,
			titlesData?.[0]?.titleLanguage,
		],
	);

	const { dryRanTitle, errors: dryRunErrors } = useDryRun({
		// @ts-ignore
		payload: memoizedPayload?.metadata,
	});

	// Initialize the store with workType when workType is preselected
	useEffect(() => {
		if (!forcedWorkTypeUuid) return;
		const workType = getWorkType(forcedWorkTypeUuid);
		setSelectedWorkType(workType);
	}, [forcedWorkTypeUuid]);

	// Synchronize dryRanTitle with the store
	useEffect(() => {
		setDryRanTitle(dryRanTitle);
	}, [dryRanTitle]);

	// Synchronize selected parent with the store
	useEffect(() => {
		if (!forcedParent) {
			clearParent();
			return;
		}
		selectParent(forcedParent);
	}, [forcedParent]);

	// Synchronize parent title with the store
	useEffect(() => {
		if (!parentTitle) {
			clearParent();
			return;
		}
		selectParent(indexedTitleToTitleCommon(parentTitle));
	}, [parentTitle]);

	// Synchronize preselected parent with the store
	useEffect(() => {
		if (!forcedParentQuery.data) return;
		selectParent(forcedParentQuery.data);
	}, [forcedParentQuery.data]);

	// Set loading state to false once every required information has been fetched
	useEffect(() => {
		if (!forcedParentUuid) {
			setLoading(false);
			return;
		}
		if (!forcedParentQuery?.isPending) setLoading(false);
	}, [forcedParentQuery?.isPending]);

	useEffect(() => {
		if (!selectedWorkTypeId) {
			let wtUuid: string | undefined;
			if (forcedParentUuid && forcedWorkTypeUuid) {
				if (!forcedParentQuery.isPending) wtUuid = forcedWorkTypeUuid;
			} else {
				wtUuid = workTypeOptions?.[0].value;
			}

			if (wtUuid) {
				const workTypeDetailsElement =
					availableWorkTypeDetailsByWorkType[getNameByUuid(wtUuid) as keyof typeof availableWorkTypeDetailsByWorkType];
				setSelectedWorkType(getWorkType(wtUuid));
				if (workTypeDetailsElement?.defaultWorkTypeDetail[0]?.value)
					setWorkTypeDetails(workTypeDetailsElement.defaultWorkTypeDetail[0].value);
			}
		}
	}, [forcedParent, childrenProperties]);

	useEffect(() => {
		setLicensor(defaultLicensor?.displayName ?? '');
	}, [defaultLicensor?.displayName]);

	useEffect(() => {
		if (selectedWorkTypeId && selectedWorkTypeName) {
			const workTypeDetailsElement =
				availableWorkTypeDetailsByWorkType[selectedWorkTypeName as keyof typeof availableWorkTypeDetailsByWorkType];
			const defaultWorkTypeDetails = workTypeDetailsElement?.defaultWorkTypeDetail[0]?.value;

			if (defaultWorkTypeDetails) {
				setWorkTypeDetails(defaultWorkTypeDetails);
			} else {
				setWorkTypeDetails(undefined);
			}
		}

		setParentTitle(undefined); // 16 * 16
		setParentTitleErrors('');
		setTitlesErrors({});
	}, [selectedWorkTypeId]);

	useEffect(() => {
		if (titlesData && titlesData.length > 0 && Object.keys(titlesErrors).some((key) => titlesErrors[key].length)) {
			validateTitlesData(fields, titlesData, setTitlesErrors, dryRanTitle);
		}
	}, [titlesData]);

	useEffect(() => {
		if (parentTitle) {
			setParentTitleErrors('');
		}
	}, [parentTitle]);

	const addNewRowOnClick = (numberOfRow: number, selectedSubheaderValues: Record<string, any>) => {
		addTitleRows({
			numberOfRows: numberOfRow,
			userSetDefaults: selectedSubheaderValues,
		});
	};

	// This function checks if the episode sequence number is sequenced, it returns false if not, otherwise it returns the first sequence number
	const episodeSequenced = (): number | false => {
		if (!titlesData) return false;
		const sequenceNumbers = titlesData.map((title) => {
			if ('sequenceNumber' in title) {
				return parseInt(title.sequenceNumber ?? '0', 10);
			}
			return 0;
		});
		if (sequenceNumbers.length === 0) return false;
		// Check if there is non number value in the sequenceNumbers
		if (sequenceNumbers.some((num) => Number.isNaN(num))) return false;
		// Check if the sequenceNumbers are not in sequence (next element should always equal to previous element + 1)
		if (
			!sequenceNumbers.every((num, idx, arr) => {
				if (idx === arr.length - 1) return true;
				return num + 1 === arr[idx + 1];
			})
		)
			return false;
		return sequenceNumbers[0];
	};

	const onDragEnd = () => {
		if (selectedWorkTypeId === episodeUuid() || selectedWorkTypeId === seasonUuid()) {
			const startSequenceNumber = episodeSequenced();
			if (startSequenceNumber !== false) {
				if (!titlesData) return;

				setTitlesData(
					titlesData.map((item, index) => ({ ...item, sequenceNumber: (index + startSequenceNumber).toString() })),
				);
			}
		}
	};

	const deleteRowOnClick = (indexes: string[]) => {
		setTitlesData(filterRowsByIndex(titlesData, indexes) || []);
	};

	const columnMetadata = useColumnsMetadata(titlesData?.map((title) => title?.title || '') || [], dryRanTitle);

	const generatedColumns = useGeneratedColumns(fields, columnMetadata);

	const onSubmit = async () => {
		if (
			selectedWorkTypeId &&
			parentFieldIsDisplayedAndRequired[
				getNameByUuid(selectedWorkTypeId) as keyof typeof parentFieldIsDisplayedAndRequired
			]?.required &&
			!parentTitle &&
			!forcedParent
		) {
			setParentTitleErrors('Parent title is required');
			return;
		}

		if (!licensor) {
			setParentTitleErrors('A licensor is required');
			return;
		}

		// Can't continue if parent is not selected on a supplemental title with relationshipType
		if (!!relationshipTypeId && !parentTitle && !forcedParent) {
			setParentTitleErrors('Parent title is required');
			return;
		}

		// Can't continue if relationshipType is not selected on a supplemental title with parents
		if (selectedWorkTypeName === 'Supplemental' && (parentTitle || forcedParent) && !relationshipTypeId) {
			return;
		}

		const isValid = validateTitlesData(fields, titlesData, setTitlesErrors, dryRanTitle);

		if (isValid && !parentTitleErrors) {
			setLoading(true);
			const results = await Promise.allSettled(
				titlesData!.map(async (titleData, _index) => {
					if (selectedWorkTypeId) {
						const payload = createTitleInputToInfraInput({
							data: {
								...titleCreationFormDataToCreateTitleInputData({
									titleCreationFormData: titleData,
									dryRanTitle: storeDryRanTitle,
								}),
								// The following fields should be in the store and also passed to titleCreationFormDataToCreateTitleInputData. To be done later.
								licensor,
								relationshipType: relationshipTypeId,
								workTypeDetail: workTypeDetails,
								parentTitle: {
									primary: getParentIsPrimary(_index, selectedWorkTypeId, relationshipTypeId),
									title: parentTitle || forcedParent,
								},
							},
							workType: selectedWorkTypeId,
							forcedRelationshipType:
								createTitleMode === 'relationships' ? relationshipTypeId : childrenProperties?.relationshipType,
						});

						if (!payload) throw new Error('Invalid payload');

						await createTitleV2({
							variables: {
								input: payload,
							},
						});
					}
				}),
			);
			setLoading(false);
			setCreationResult(results);
			setTitleCreationSummaryOpen(true);
			setTitlesData(titlesData!.filter((_, index) => results[index].status === 'rejected'));
		}
	};

	return (
		<Wrapper>
			<TopWrapper>
				<TopBarWrapper>
					<TopBarLegacy
						title="Create Titles"
						onClickBack={() => navigate(-1)}
						hideTitleCard
						actions={
							<Button
								nxstyle="cta"
								disabled={loading || titlesData?.length === 0}
								startIcon={loading ? undefined : <Icon path={mdiPlus} size={0.7} />}
								onClick={() => onSubmit()}
							>
								{loading ? <Loader size={20} color={theme.palette.light.main} /> : 'Create'}
							</Button>
						}
					/>
				</TopBarWrapper>
				<FormWrapper>
					{createTitleMode === 'relationships' && !forcedParentQuery.isPending && (
						<RelationshipModeHeader
							parentTitle={forcedParent?.readonly!}
							childWorkType={selectedWorkType}
							onSelectChildWorkType={setSelectedWorkType}
							relationshipType={relationshipType}
							onSelectRelationshipType={setRelationshipType}
							workTypeDetail={workTypeDetails}
							setWorkTypeDetail={setWorkTypeDetails}
						/>
					)}
					{createTitleMode !== 'relationships' && (
						<>
							<WorkTypeSelector
								workType={selectedWorkTypeId}
								loadingParent={!!forcedParentUuid && forcedParentQuery.isPending}
								setWorkType={(workTypeUuid) => setSelectedWorkType(getWorkType(workTypeUuid))}
								setParentType={setParentType}
								selectedParent={forcedParent}
								forcedWorkTypeUuid={forcedWorkTypeUuid}
								childrenProperties={childrenProperties}
							/>
							{workTypeDetailsIsDisplayed.includes(selectedWorkTypeName || '') && (
								<>
									<Divider orientation="vertical" />
									<WorkTypeDetailsSelector
										workTypeDetails={workTypeDetails}
										setWorkTypeDetails={setWorkTypeDetails}
										workTypeName={selectedWorkTypeName}
										loading={loading}
									/>
								</>
							)}
							<Divider orientation="vertical" />
							<LicensorSelector
								licensor={licensor}
								onLicensorChange={setLicensor}
								parentHasBeenSelected={!!parentTitle}
								setParent={setParentTitle}
								loading={licensorLoading}
								readOnly={!!forcedParentUuid}
							/>
							<Divider orientation="vertical" />
							{parentFieldIsDisplayedAndRequired[selectedWorkTypeName as keyof typeof parentFieldIsDisplayedAndRequired]
								?.displayed && (
								<>
									{selectedWorkTypeName === 'Supplemental' && (
										<RelationshipTypeField
											relationshipType={relationshipTypeId}
											setRelationshipType={(newRelationshipTypeId) => {
												if (newRelationshipTypeId) setRelationshipType(getRelationshipType(newRelationshipTypeId));
												else setRelationshipType(undefined);
											}}
											isRequired={!!parentTitle}
											setParentTitleErrors={setParentTitleErrors}
										/>
									)}
									<ParentTitleSelector
										parentTitle={parentTitle}
										isRequired={
											parentFieldIsDisplayedAndRequired[
												(selectedWorkTypeName || '') as keyof typeof parentFieldIsDisplayedAndRequired
											]?.required || !!relationshipTypeId
										}
										setParentTitle={setParentTitle}
										setParentType={setParentType}
										selectedParent={forcedParentQuery.data?.readonly}
										workType={selectedWorkTypeId}
										parentTitleErrors={parentTitleErrors}
										parentType={parentType}
										licensor={licensor}
									/>
								</>
							)}
						</>
					)}
				</FormWrapper>
			</TopWrapper>
			{isPendingDryRun && (
				<LoaderWrapper>
					<NxLoader />
				</LoaderWrapper>
			)}
			{isTableReadyToDisplay && (
				<TableWrapper>
					{dryRunErrors && dryRunErrors.length !== 0 && (
						<Alert severity="error">
							<ul>
								{dryRunErrors?.map((err: any) => (
									<li>{err?.errorInfo?.titleUuid ? replaceUUID(err.message) : err.message}</li>
								))}
							</ul>
						</Alert>
					)}
					<SimpleTable
						columns={generatedColumns}
						data={titlesData!}
						setData={setTitlesData}
						onRowAdd={addNewRowOnClick}
						onRowDelete={deleteRowOnClick}
						editable
						draggable
						onDragEnd={onDragEnd}
						errors={titlesErrors}
						showBottomActionsBar
						showSubHeader
						tableStyle="subheader"
						hideIndexColumn={!!fields.find(({ field }) => field === 'sequenceNumber')}
						errorHandlingMode={ErrorHandlingMode.MultiTitleCreate}
					/>
				</TableWrapper>
			)}
			<TitleCreationSummary
				open={titleCreationSummaryOpen}
				successNumber={creationResult.filter((res) => res.status === 'fulfilled').length}
				errorNumber={creationResult.filter((res) => res.status === 'rejected').length}
				creationResults={creationResult}
				handleClose={() => setTitleCreationSummaryOpen(false)}
				createdFromTitle={!!forcedParentUuid}
			/>
		</Wrapper>
	);
}

function getParentIsPrimary(index: number, workTypeUuid: string, selectedRelationshipType: string | undefined) {
	const workType = getWorkType(workTypeUuid);

	const relationshipType =
		workTypeUuid === supplementalUuid() ? selectedRelationshipType : getRelationshipTypePerWorkTypeName(workType);

	if (workType === WorkType.Manifestation) {
		return false;
	}

	if (relationshipType === isDerivedFrom()) {
		if (index === 0) return undefined;
		return false;
	}

	if ([isSeasonOf(), isEpisodeOf()].includes(relationshipType || '')) {
		return true;
	}

	return undefined;
}

export default CreateMultipleTitlesView;

const Wrapper = styled.div(
	({ theme }) => css`
		background-color: ${theme.palette.light.backgroundAlt};
		display: flex;
		flex-direction: column;
		gap: ${theme.spacing(2)};
		overflow-y: auto;
		padding: ${theme.spacing(2)};
		width: 100%;
	`,
);

const TopWrapper = styled.div(
	({ theme }) => css`
		background-color: ${theme.palette.light.main};
		border-radius: ${theme.spacing(2)};
		display: flex;
		flex-direction: column;
		width: 100%;
	`,
);
const TopBarWrapper = styled.div(
	({ theme }) => css`
		border-bottom: 1px solid ${theme.palette.light.backgroundAlt};
		box-sizing: border-box;
		padding: 0 ${theme.spacing(2)};
		width: 100%;
	`,
);

const FormWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		background-color: ${theme.palette.light.main};
		border-radius: ${theme.spacing(2)};
		display: flex;
		justify-content: flex-start;
	`,
);

const TableWrapper = styled.div(
	({ theme }) => css`
		background-color: ${theme.palette.light.main};
		border-radius: ${theme.spacing(2)};
		padding: ${theme.spacing(2)};
	`,
);

const LoaderWrapper = styled.div(
	() => css`
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: center;
		width: 100%;
	`,
);
