import { defineStore } from '@warehouse/shared/util';
import type { StateCreator } from 'zustand/vanilla';
import { TitleCommon, TitleFull } from '@warehouse/title/core';
import { UnreachableError } from '@warehouse/shared/errors';
import { hasRequiredParent, WorkType, WorkTypeWithRequiredParent } from '@warehouse/title-shared/core';
import { computeDefaults } from './compute-defaults';
import { TitleCreationFormData, UserSetDefaults } from './title-creation-form-data';
import { computeTitlePlaceholder } from '../../../src/views/titles/createTitle/computeTitlePlaceholder';
import computeSortableName from '../../../src/utils/computeSortableName';
import { reinheritForm } from './reinherit';

type BatchCreateStore = BatchCreateStoreState & {
	actions: BatchCreateStoreActions;
};

type BatchCreateStoreState =
	| WaitingForWorkTypeStoreState
	| WaitingForParentStoreState
	| PendingDryRunStoreState
	| ReadyStoreState;

type WaitingForWorkTypeStoreState = {
	type: 'waitingForWorkType';
	workType: undefined;
	selectedParent?: never;
	titlesCreationFormData?: never;
	dryRanTitle?: never;
};

type WaitingForParentStoreState = {
	type: 'waitingForParent';
	workType: WorkTypeWithRequiredParent;
	selectedParent: undefined;
	titlesCreationFormData?: never;
	dryRanTitle?: never;
};

type PendingDryRunStoreState = {
	type: 'pendingDryRun';
	workType: WorkType;
	selectedParent: TitleCommon | undefined;
	titlesCreationFormData?: never;
	dryRanTitle?: never;
};

type ReadyStoreState = {
	type: 'ready';
	workType: WorkType;
	selectedParent: TitleCommon | undefined;
	dryRanTitle: TitleFull | undefined;
	titlesCreationFormData: TitleCreationFormData[];
};

type BatchCreateStoreActions = {
	setDryRanTitle(title: TitleFull | undefined): void;
	selectWorkType(workType: WorkType | undefined): void;
	selectParent(parent: TitleCommon): void;
	clearParent(): void;
	setTitlesData(titles: TitleCreationFormData[]): void;
	addTitleRows(params: { numberOfRows: number; userSetDefaults?: UserSetDefaults }): void;
};

// Helper functions

const isWaitingForParent = (state: BatchCreateStoreState): state is WaitingForParentStoreState =>
	state.type === 'waitingForParent';

const isPendingDryRun = (state: BatchCreateStoreState): state is PendingDryRunStoreState =>
	state.type === 'pendingDryRun';

const isReady = (state: BatchCreateStoreState): state is ReadyStoreState => state.type === 'ready';

const doesTitleHaveParent = (title: TitleCommon | undefined, parentUuid: string) => {
	if (!title) return false;
	return title.readonly.parents?.some((parent) => parent.contentId === parentUuid);
};

// State creator function
export const createBatchCreateState: StateCreator<BatchCreateStore> = (set, get) => ({
	...waitingForWorkType(),
	actions: {
		selectWorkType: (workType: WorkType | undefined) => {
			const state = get();
			if (workType === state.workType) return;
			if (!workType) {
				set(waitingForWorkType());
			} else if (hasRequiredParent(workType)) {
				set(waitingForParent({ workType }));
			} else {
				set(
					ready({
						workType,
						selectedParent: undefined,
						titlesCreationFormData: computeDefaults({
							workType,
							dryRanTitle: undefined,
							userSetDefaults: undefined,
							numberOfRowsToAdd: 1,
						}),
						dryRanTitle: undefined,
					}),
				);
			}
		},
		selectParent: (parent: TitleCommon) => {
			const state = get();
			if (parent.uuid === state.selectedParent?.uuid) return;
			if (isReady(state)) {
				// Reset titlesCreationFormData if parent has changed
				if (hasRequiredParent(state.workType) && state.selectedParent?.uuid !== parent.uuid) {
					set(
						pendingDryRun({
							...state,
							selectedParent: parent,
						}),
					);
				} else {
					set({
						...state,
						selectedParent: parent,
					});
				}
			}

			if (isWaitingForParent(state) || isPendingDryRun(state)) {
				set({
					...state,
					type: 'pendingDryRun',
					selectedParent: parent,
				});
			}
		},
		clearParent: () => {
			const state = get();
			if (isReady(state)) {
				if (hasRequiredParent(state.workType)) {
					set(waitingForParent({ workType: state.workType }));
				} else {
					set({
						...state,
						selectedParent: undefined,
					});
				}
			}

			if (isPendingDryRun(state)) {
				if (hasRequiredParent(state.workType)) {
					set(waitingForParent({ workType: state.workType }));
				} else {
					set({
						...state,
						selectedParent: undefined,
					});
				}
			}
		},
		setDryRanTitle: (newDryRanTitle: TitleFull | undefined) => {
			const state = get();

			if (!isPendingDryRun(state)) return;

			if (state.selectedParent) {
				if (!doesTitleHaveParent(newDryRanTitle, state.selectedParent.uuid)) return;
			}

			set(
				ready({
					workType: state.workType,
					selectedParent: state.selectedParent,
					titlesCreationFormData: computeDefaults({
						workType: state.workType,
						dryRanTitle: newDryRanTitle,
						userSetDefaults: undefined,
						numberOfRowsToAdd: 1,
					}),
					dryRanTitle: newDryRanTitle,
				}),
			);
		},
		setTitlesData: (titles: TitleCreationFormData[]) => {
			const state = get();
			if (isReady(state)) {
				const reinheritedTitles = reinheritForm(titles, state.dryRanTitle);
				set({
					...state,
					titlesCreationFormData: reinheritedTitles,
				});
			}
		},
		addTitleRows: ({ numberOfRows, userSetDefaults }: { numberOfRows: number; userSetDefaults?: UserSetDefaults }) => {
			const state = get();
			if (isReady(state)) {
				const newRows = computeDefaults({
					workType: state.workType,
					dryRanTitle: state.dryRanTitle,
					userSetDefaults,
					numberOfRowsToAdd: numberOfRows,
					currentTitlesFormData: state.titlesCreationFormData,
				});
				set({
					...state,
					titlesCreationFormData: [...state.titlesCreationFormData, ...newRows],
				});
			}
		},
	},
});

function waitingForWorkType(): WaitingForWorkTypeStoreState {
	return {
		type: 'waitingForWorkType',
		workType: undefined,
		selectedParent: undefined,
		titlesCreationFormData: undefined,
		dryRanTitle: undefined,
	};
}

function waitingForParent({ workType }: Pick<WaitingForParentStoreState, 'workType'>): WaitingForParentStoreState {
	return {
		type: 'waitingForParent',
		workType,
		selectedParent: undefined,
		titlesCreationFormData: undefined,
		dryRanTitle: undefined,
	};
}

function pendingDryRun({
	workType,
	selectedParent,
}: Pick<PendingDryRunStoreState, 'workType' | 'selectedParent'>): PendingDryRunStoreState {
	return {
		type: 'pendingDryRun',
		workType,
		selectedParent,
		titlesCreationFormData: undefined,
		dryRanTitle: undefined,
	};
}

function ready({
	workType,
	selectedParent,
	titlesCreationFormData,
	dryRanTitle,
}: Pick<ReadyStoreState, 'workType' | 'selectedParent' | 'titlesCreationFormData' | 'dryRanTitle'>): ReadyStoreState {
	return {
		type: 'ready',
		workType,
		selectedParent,
		titlesCreationFormData,
		dryRanTitle,
	};
}

export const [BatchCreateStoreProvider, useBatchCreateStore] = defineStore<BatchCreateStore>(createBatchCreateState);

export const batchCreateStoreSelector = {
	actions: (state: BatchCreateStore) => state.actions,
	selectedParent: (state: BatchCreateStore) => ('selectedParent' in state ? state.selectedParent : undefined),
	selectedWorkType: (state: BatchCreateStore) => ('workType' in state ? state.workType : undefined),
	titlesCreationFormData: (state: BatchCreateStore) => (isReady(state) ? state.titlesCreationFormData : undefined),
	isTableReadyToDisplay: (state: BatchCreateStore) => isReady(state),
	dryRanTitle: (state: BatchCreateStore) => (isReady(state) ? state.dryRanTitle : undefined),
	isPendingDryRun: (state: BatchCreateStore) => isPendingDryRun(state),
	computedPlaceholderDisplayTitle(index: number) {
		return (state: BatchCreateStore) => {
			if (state.type !== 'ready')
				throw new UnreachableError('Can not compute display title as store is not in a ready state.');

			const titleRow = state.titlesCreationFormData[index];

			if (!('sequenceNumber' in titleRow)) {
				return undefined;
			}

			return computeTitlePlaceholder({
				workType: state.workType,
				sequenceNumber: titleRow.sequenceNumber ?? '',
				index,
			});
		};
	},
	computedPlaceholderSortTitle(index: number) {
		return (state: BatchCreateStore) => {
			if (state.type !== 'ready')
				throw new UnreachableError('Can not compute sort title as store is not in a ready state.');

			const userDisplayTitle = state.titlesCreationFormData[index].title;
			const placeholderDisplayTitle = batchCreateStoreSelector.computedPlaceholderDisplayTitle(index)(state);

			return computeSortableName(userDisplayTitle || placeholderDisplayTitle);
		};
	},
};
