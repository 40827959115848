import { To } from 'react-router-dom';

export interface ObjectBrowserLinkBuilderOptions {
	fromTitle?: FromTitle;
	forwardSearchParams?: boolean;
}

export interface FromTitle {
	titleSearch: string;
	licensorLabel: string;
}

export class ObjectBrowserLinkBuilder {
	static buildFolderLink({
		folderUuid,
		fromTitle,
		forwardSearchParams,
	}: {
		folderUuid: string;
	} & ObjectBrowserLinkBuilderOptions): To {
		const searchParams = new URLSearchParams(forwardSearchParams ? window.location.search : undefined);
		// We delete the search query as we never want to transfer it through navigation for now.
		// We only have a search query in searchMode, and we don't have drill down in search mode.
		searchParams.delete('searchQuery');

		if (fromTitle) {
			searchParams.append('fromTitle', btoa(JSON.stringify(fromTitle)));
		}

		return {
			pathname: `/object-browser/folder/${encodeURIComponent(folderUuid)}`,
			// This is a test, we are not sure if we can use it like this without the useLocation hook
			search: searchParams.toString() ? `?${searchParams.toString()}` : '',
		};
	}

	static buildBucketLink({
		bucketId,
		fromTitle,
		forwardSearchParams,
	}: {
		bucketId: string;
	} & ObjectBrowserLinkBuilderOptions): To {
		const searchParams = new URLSearchParams(forwardSearchParams ? window.location.search : undefined);
		// We delete the search query as we never want to transfer it through navigation for now.
		// We only have a search query in searchMode, and we don't have drill down in search mode.
		searchParams.delete('searchQuery');

		if (fromTitle) {
			searchParams.append('fromTitle', window.btoa(JSON.stringify(fromTitle)));
		}

		return {
			pathname: `/object-browser/bucket/${encodeURIComponent(bucketId)}`,
			search: searchParams.toString() ? `?${searchParams.toString()}` : '',
		};
	}
}
