import { useCallback, useEffect, useState } from 'react';
import { Licensor } from '@warehouse/title-shared/core';
import { QueryObserverResult } from '@warehouse/shared/core';
import { Observable } from 'rxjs';
import { licensorRepositorySingleton } from '@warehouse/title-shared/infra';

function useLicensors({ pollInterval }: { pollInterval?: number } = {}) {
	const licensorRepository = licensorRepositorySingleton.get();
	const [licensors, setLicensors] = useState<Licensor[] | undefined>(undefined);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<Error | null>(null);
	const [refetch, setRefetch] = useState<(() => void) | undefined>(undefined);

	useEffect(() => {
		const licensors$: Observable<QueryObserverResult<Licensor[]>> = licensorRepository.watchLicensors({ pollInterval });

		const subscription = licensors$.subscribe({
			next: (result) => {
				setLoading(result.isPending);
				setError(result.error || null);
				if (result.data) {
					setLicensors(result.data);
				}
				if (result.refetch) {
					setRefetch(() => result.refetch);
				}
			},
			error: (err) => {
				setError(err);
				setLoading(false);
			},
		});

		return () => {
			subscription.unsubscribe();
		};
	}, [pollInterval, licensorRepository]);

	const getDefault = useCallback(() => licensors?.find((licensor: Licensor) => licensor.default), [licensors]);

	return { loading, getDefault, refetch: refetch || (() => {}), error, data: { getLicensors: licensors } };
}

export default useLicensors;
