import { paginatorStoreSelector } from '@warehouse/shared/ui';
import { getOptionalProperty, isInAndDefined } from '@warehouse/shared/util';
import { AssignmentStatus, NxObjectType, SearchMode } from '@warehouse/object-browser/core';
import { objectBrowserTableManagerSelector } from '../feature-object-browser/domain/object-browser-table-manager.store';
import { objectBrowserTreeManagerSelector } from '../feature-object-browser/domain/object-browser-tree-manager.store';
import { objectBrowserBreadcrumbsManagerSelector } from '../feature-object-browser/domain/object-browser-breadcrumbs-manager.store';
import { wildCardSearchSelector } from '../feature-wild-card-search/domain';
import { objectBrowserDrawerManagerSelector } from '../feature-nx-object-drawer/domain/object-browser-drawer-manager.store';
import { ObjectBrowserStore } from './object-browser.type';

export const objectBrowserStoreSelector = {
	...objectBrowserTableManagerSelector,
	...objectBrowserTreeManagerSelector,
	...paginatorStoreSelector,
	...objectBrowserBreadcrumbsManagerSelector,
	...wildCardSearchSelector,
	...objectBrowserDrawerManagerSelector,
	selectedNxObjectUuid: (state: ObjectBrowserStore) => state.selectedNxObjectUuid,
	resourceIdentifier: (state: ObjectBrowserStore) => {
		if (isInAndDefined(state, 'resourceIdentifier')) return state.resourceIdentifier;
		return undefined;
	},
	actions: (state: ObjectBrowserStore) => ({
		...objectBrowserTableManagerSelector.actions(state),
		...objectBrowserTreeManagerSelector.actions(state),
		...paginatorStoreSelector.actions(state),
		...objectBrowserBreadcrumbsManagerSelector.actions(state),
		...wildCardSearchSelector.actions(state),
		...objectBrowserDrawerManagerSelector.actions(state),
		setResourceIdentifier: state.actions.setResourceIdentifier,
		setNxObjectFull: state.actions.setNxObjectFull,
		selectNxObject: state.actions.selectNxObject,
		closeNxObjectDrawer: state.actions.closeNxObjectDrawer,
	}),
	selectedNxObject: (state: ObjectBrowserStore) => {
		if (!isInAndDefined(state, 'selectedNxObject')) return undefined;
		return state.selectedNxObject;
	},
	selectedNxObjectFull: (state: ObjectBrowserStore) => {
		if (!isInAndDefined(state, 'selectedNxObjectFull')) return undefined;
		return state.selectedNxObjectFull;
	},
	isSelectedNxObjectFirst: (state: ObjectBrowserStore) => {
		if (!isInAndDefined(state, 'selectedNxObject')) return false;
		const items = objectBrowserStoreSelector.items(state);
		return items.findIndex((item) => item.uuid === state.selectedNxObject.uuid) === 0;
	},
	isSelectedNxObjectLast: (state: ObjectBrowserStore) => {
		if (!isInAndDefined(state, 'selectedNxObject')) return false;
		const items = objectBrowserStoreSelector.items(state);
		const foundIndex = items.findIndex((item) => item.uuid === state.selectedNxObject.uuid);
		if (foundIndex === -1) return false;
		return foundIndex === items.length - 1;
	},
	isSelectedItemPresent: (state: ObjectBrowserStore) => {
		if (!isInAndDefined(state, 'selectedNxObject')) return false;
		return (
			objectBrowserStoreSelector.items(state).findIndex((item) => item.uuid === state.selectedNxObject.uuid) !== -1
		);
	},
	isInSearchMode: (state: ObjectBrowserStore) =>
		state.searchQuery.length > 0 || state.status === 'title-objects-tab-ready',
	items: (state: ObjectBrowserStore) => {
		if (objectBrowserStoreSelector.isInSearchMode(state)) {
			return state.wildCardSearchItems;
		}
		if (!isInAndDefined(state, 'items')) return [];
		return state.items;
	},
	isPending: (state: ObjectBrowserStore) => {
		if (isInAndDefined(state, 'isCurrentFolderPending') && state.isCurrentFolderPending) return true;
		if (!isInAndDefined(state, 'isItemsPending')) return true;
		if (objectBrowserStoreSelector.isInSearchMode(state)) {
			return state.isWildCardSearchPending;
		}
		return state.isItemsPending;
	},
	isNxObjectFullUnmanaged: (state: ObjectBrowserStore) => {
		if (!isInAndDefined(state, 'selectedNxObjectFull')) return false;
		if (state.selectedNxObjectFull.nxObject.itemType !== NxObjectType.Folder) return false;
		return state.selectedNxObjectFull.nxObject.assignmentStatus === AssignmentStatus.Unmanaged;
	},
	showBreadcrumb: (state: ObjectBrowserStore) => {
		if (state.status !== 'ready' && state.status !== 'title-objects-tab-ready') return false;

		// When the user is in drill down mode, we always display the breadcrumbs
		if (!objectBrowserStoreSelector.isInSearchMode(state)) return true;

		// When the user is in folder search mode, we always display the breadcrumbs
		// (Either the "this folder" path, or the path of the selected object)
		if (wildCardSearchSelector.searchMode(state) === SearchMode.THIS_FOLDER) return true;

		// In everywhere more, we only display the breadcrumbs if the selectedNxObjectUuid is in the items. (And defined ofc)
		const isSelectedObjectInItems = objectBrowserStoreSelector
			.items(state)
			.find((item) => item.uuid === state.selectedNxObjectUuid);

		return !!state.selectedNxObject && !!state.selectedNxObjectUuid && isSelectedObjectInItems;
	},
	status: (state: ObjectBrowserStore) => state.status,
	title: (state: ObjectBrowserStore) => getOptionalProperty(state, 'title'),
};
