import React, { ReactNode, useMemo } from 'react';
import { definePersistentQueryParamStore, PersistQueryParamsConfig } from '@warehouse/shared/util';
import { FromTitle } from './object-browser-link-builder';

export interface FromTitleStore {
	fromTitle: FromTitle | undefined;
}

const [FromTitleProviderInternal, useFromTitle] = definePersistentQueryParamStore<FromTitleStore>(() => ({
	fromTitle: undefined,
}));

export { useFromTitle };

export const fromTitleSelector = {
	fromTitle: (state: FromTitleStore) => state.fromTitle,
};

export function FromTitleProvider({ children }: { children: ReactNode }) {
	return (
		<FromTitleProviderInternal
			config={useMemo(
				(): PersistQueryParamsConfig<FromTitleStore> => ({
					fromTitle: {
						type: 'object',
					},
				}),
				[],
			)}
		>
			{children}
		</FromTitleProviderInternal>
	);
}
