import React, { useEffect, useMemo } from 'react';
import { Subscription } from 'rxjs';
import { StateCreator } from 'zustand';
import isEqual from 'lodash/isEqual';
import {
	definePersistentQueryParamStore,
	getOptionalProperty,
	isInAndDefined,
	PersistQueryParamsConfig,
} from '@warehouse/shared/util';
import { nxObjectRepositorySingleton } from '@warehouse/object-browser/infra';
import {
	FolderPathUtils,
	FolderUuidObject,
	InfraResourceIdentifier,
	NxObject,
	SearchMode,
	StoreResourceIdentifier,
	WatchGetNxObjectResult,
	WatchSearchResult,
} from '@warehouse/object-browser/core';
import { createPaginatorSlice, PaginatorStore, TableManagerStore } from '@warehouse/shared/ui';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { TitleCommon } from '@warehouse/title/core';
import {
	objectBrowserDrawerManagerStoreConfig,
	objectBrowserStoreConfig,
	paginatorStoreConfig,
	tableManagerStoreConfig,
	titlesObjectTabObjectBrowserStoreConfig,
	wildCardSearchStoreConfig,
} from './object-browser.store-config';
import { createObjectBrowserTableManagerSliceFactory } from '../feature-object-browser/domain/object-browser-table-manager.store';
import { createObjectBrowserTreeManagerSlice } from '../feature-object-browser/domain/object-browser-tree-manager.store';
import { objectBrowserBreadcrumbsManagerSlice } from '../feature-object-browser/domain/object-browser-breadcrumbs-manager.store';
import { createWildCardSearchSliceFactory } from '../feature-wild-card-search/domain';
import { gridFilterModelToFilterQuery } from '../../shared/ui/table/muix.adapter';
import { createObjectBrowserDrawerManagerSliceFactory } from '../feature-nx-object-drawer/domain/object-browser-drawer-manager.store';
import {
	ObjectBrowserStore,
	ReadyObjectBrowserStore,
	TitleObjectsTabReadyObjectBrowserStore,
	WaitingForCurrentFolderBrowserStore,
} from './object-browser.type';
import { objectBrowserStoreSelector } from './object-browser.store.selectors';
import { remapSessionStorageKeyPrefix } from '../../shared/util/persist-query-param-store/remap-prefix';

export const createObjectBrowserStore: StateCreator<ObjectBrowserStore> = (set, get, store) => {
	const repo = nxObjectRepositorySingleton.get();
	let watchDrillDownSearchSubscription: Subscription | undefined;
	let watchCurrentFolderSubscription: Subscription | undefined;
	let watchSelectedNxObjectFullSubscription: Subscription | undefined;

	const unsubscribeFromDrillDownSearch = () => {
		if (watchDrillDownSearchSubscription) watchDrillDownSearchSubscription.unsubscribe();
	};
	const unsubscribeFromCurrentFolder = () => {
		if (watchCurrentFolderSubscription) watchCurrentFolderSubscription.unsubscribe();
	};
	const unsubscribeFromNxObjectFull = () => {
		if (watchSelectedNxObjectFullSubscription) watchSelectedNxObjectFullSubscription.unsubscribe();
	};

	const updateBreadcrumbAccordingToCurrentFolderOrResourceIdentifier = () => {
		const state = get();

		if (isInAndDefined(state, 'currentFolder')) {
			// We were on a folder before entering the search mode
			objectBrowserBreadcrumbsManagerStore.actions.updateBreadcrumbFromNxObjectFull(state.currentFolder);
		} else if (isInAndDefined(state, 'resourceIdentifier') && state.resourceIdentifier.type === 'bucket') {
			// We were on a bucket before entering the search mode
			objectBrowserBreadcrumbsManagerStore.actions.updateBreadcrumbFromBucketId(state.resourceIdentifier.bucketId);
		}
	};

	const unselectNxObject = () => {
		updateBreadcrumbAccordingToCurrentFolderOrResourceIdentifier();
		set({
			selectedNxObject: undefined,
			selectedNxObjectUuid: undefined,
			selectedNxObjectFull: undefined,
		});
	};

	// This function is called when on a watchNxObjects result (either in the drilldown search or in the wildcard search) to set the selectedNxObject if it's present in the results
	// This is necessary because if there was a selectedNxObjectUuid in the store, it means it came from a deeplink, and we need to fill the selectedNxObject with the result of the search
	const setSelectedNxObjectIfPresentInItems = () => {
		const state = get();
		if (state.status !== 'ready' && state.status !== 'title-objects-tab-ready') return;
		if (!state.selectedNxObjectUuid) return;
		if (state.selectedNxObject && state.selectedNxObject.uuid === state.selectedNxObjectUuid) return;

		const selectedNxObject = objectBrowserStoreSelector
			.items(state)
			.find((item) => item.uuid === state.selectedNxObjectUuid);
		if (selectedNxObject) setSelectedNxObjectAndSubscribeToFull(selectedNxObject);
	};

	const setSelectedNxObjectAndSubscribeToFull = (selectedNxObject: NxObject) => {
		set({ selectedNxObject });
		subscribeToSelectedItemFull(selectedNxObject.uuid);
	};

	const subscribeToDrillDownSearch = () => {
		unsubscribeFromDrillDownSearch();
		const state = get();
		if (state.status !== 'ready') return;
		watchDrillDownSearchSubscription = repo
			.watchNxObjects({
				resourceIdentifier: getInfraResourceIdentifier(),
				filters: {
					sort: [{ field: 'itemType', sort: 'desc' }, ...state.sort],
					pagination: {
						page: state.page,
						perPage: state.perPage,
					},
					filter: gridFilterModelToFilterQuery(state.filter),
				},
				options: {
					pollInterval: 3000,
				},
			})
			.subscribe((res: WatchSearchResult) => {
				if (res.isPending) {
					set({ isItemsPending: true });
				} else if (res.error) {
					// TODO: decide upon a better error-handling strategy
					console.error(`An error has occurred: ${res.error}`);
				} else {
					set({
						isItemsPending: false,
						items: res.data.documents,
						totalPages: res.data.pagination.totalPage,
						totalCount: res.data.pagination.totalDocument,
					});
					setSelectedNxObjectIfPresentInItems();
				}
			});
	};

	const subscribeToCurrentFolder = (resourceIdentifier: FolderUuidObject) => {
		unsubscribeFromCurrentFolder();

		watchCurrentFolderSubscription = repo
			.watchNxObject({
				nxObjectUuid: resourceIdentifier.folderUuid,
			})
			.subscribe((res: WatchGetNxObjectResult) => {
				const state = get();
				if (res.isPending) {
					set({
						isCurrentFolderPending: true,
					});
				} else if (res.error) {
					// TODO: decide upon a better error-handling strategy
					console.error(`An error has occurred: ${res.error}`);
				} else {
					if (state.status !== 'waitingForCurrentFolder') {
						set({ isCurrentFolderPending: false });
						return;
					}
					set({
						status: 'ready',
						resourceIdentifier: state.resourceIdentifier,
						currentFolder: res.data,
						isCurrentFolderPending: false,
						// If it was already set (e.g. from a deeplink), we don't want to overwrite it
						selectedNxObject: objectBrowserStoreSelector.selectedNxObject(get()),
						selectedNxObjectFull: objectBrowserStoreSelector.selectedNxObjectFull(get()),
						isItemsPending: true,
						items: [],
					} satisfies ReadyObjectBrowserStore);
					objectBrowserBreadcrumbsManagerStore.actions.updateBreadcrumbFromNxObjectFull(res.data);
				}
			});
	};

	const subscribeToSelectedItemFull = (nxObjectUuid: string) => {
		unsubscribeFromNxObjectFull();
		const state = get();
		watchSelectedNxObjectFullSubscription = repo
			.watchNxObject({
				nxObjectUuid,
			})
			.subscribe((res) => {
				if (res.isPending) return;
				if (res.error) {
					// TODO: decide upon a better error-handling strategy
					console.error(`An error has occurred: ${res.error}`);
					set({ selectedNxObjectFull: undefined });
				} else {
					set({ selectedNxObjectFull: res.data });
					if (objectBrowserStoreSelector.isInSearchMode(state)) {
						objectBrowserBreadcrumbsManagerStore.actions.updateBreadcrumbFromNxObjectFull(res.data);
					}
				}
			});
	};

	const getInfraResourceIdentifier = (): InfraResourceIdentifier => {
		const state = get();
		if (state.status !== 'ready') throw Error('Store is not yet ready');
		if (state.resourceIdentifier.type === 'bucket') {
			return { type: 'bucket', bucketId: state.resourceIdentifier.bucketId };
		}
		if (!state.currentFolder) throw new Error('Current folder is missing in a store ready');
		return {
			type: 'folderPath',
			// The cast is safe because for folders, they key is always a folder path
			folderPath: FolderPathUtils.cast(state.currentFolder.nxObject.key),
			bucketId: state.currentFolder.nxObject.bucketId,
		};
	};

	const objectBrowserTableManagerStore = createObjectBrowserTableManagerSliceFactory({ getPaginatorStore: get })(
		set,
		get,
		store,
	);
	const objectBrowserTreeManagerStore = createObjectBrowserTreeManagerSlice(set, get, store);
	const paginatorStore = createPaginatorSlice(set, get, store);
	const objectBrowserBreadcrumbsManagerStore = objectBrowserBreadcrumbsManagerSlice(set, get, store);
	const objectBrowserDrawerManagerStore = createObjectBrowserDrawerManagerSliceFactory({
		selectNxObject: (object) => {
			get().actions.selectNxObject(object);
		},
		getCurrentObjects: () => objectBrowserStoreSelector.items(get()),
		getCurrentSelectedObject: () => objectBrowserStoreSelector.selectedNxObject(get()),
	})(set, get, store);
	const wildCardSearchStore = createWildCardSearchSliceFactory({
		getPaginatorStore: get,
		getTableManagerStore: get,
		getResourceIdentifier: () => {
			try {
				return getInfraResourceIdentifier();
			} catch (e) {
				return undefined;
			}
		},
		getAssignedTitleUuid: () => getOptionalProperty(get(), 'title')?.uuid,
		onWatchNxObjectsResult: () => {
			setSelectedNxObjectIfPresentInItems();
		},
		onSearchQueryChange: () => {
			unselectNxObject();
		},
		onSearchModeChange: () => {
			unselectNxObject();
		},
		onEnterSearchMode: () => {
			unsubscribeFromDrillDownSearch();
			unselectNxObject();
		},
		onExitSearchMode: () => {
			unselectNxObject();
			subscribeToDrillDownSearch();
			updateBreadcrumbAccordingToCurrentFolderOrResourceIdentifier();
		},
	})(set, get, store);

	// Subscribe to the store's state changes to update the items subscription when necessary.
	store.subscribe((state, prevState) => {
		const isInSearchMode = objectBrowserStoreSelector.isInSearchMode(state);

		if (isInSearchMode && shouldUpdateWildcardSearchSubscription(state, prevState)) {
			wildCardSearchStore.actions.subscribeToSearch();
			unsubscribeFromDrillDownSearch();
		} else if (!isInSearchMode && shouldUpdateDrilldownSearchSubscription(state, prevState)) {
			subscribeToDrillDownSearch();
			wildCardSearchStore.actions.unsubscribeToSearch();
		}
	});

	return {
		status: 'waitingForResourceIdentifier',
		...objectBrowserTableManagerStore,
		...objectBrowserTreeManagerStore,
		...paginatorStore,
		...objectBrowserBreadcrumbsManagerStore,
		...wildCardSearchStore,
		...objectBrowserDrawerManagerStore,
		selectedNxObjectUuid: undefined,
		actions: {
			...objectBrowserTableManagerStore.actions,
			...objectBrowserTreeManagerStore.actions,
			...paginatorStore.actions,
			...objectBrowserBreadcrumbsManagerStore.actions,
			...wildCardSearchStore.actions,
			...objectBrowserDrawerManagerStore.actions,
			setResourceIdentifier(resourceIdentifier: StoreResourceIdentifier) {
				objectBrowserBreadcrumbsManagerStore.actions.updateResourceIdentifier();
				paginatorStore.internalActions.resetPagination();

				if (resourceIdentifier.type === 'bucket') {
					set({
						status: 'ready',
						resourceIdentifier,
						currentFolder: undefined,
						isCurrentFolderPending: false,
						// If it was already set (e.g. from a deeplink), we don't want to overwrite it
						selectedNxObject: objectBrowserStoreSelector.selectedNxObject(get()),
						selectedNxObjectFull: objectBrowserStoreSelector.selectedNxObjectFull(get()),
						isItemsPending: true,
						items: [],
					} satisfies ReadyObjectBrowserStore);
					objectBrowserBreadcrumbsManagerStore.actions.updateBreadcrumbFromBucketId(resourceIdentifier.bucketId);
				} else {
					set({
						status: 'waitingForCurrentFolder',
						resourceIdentifier,
						isCurrentFolderPending: true,
						currentFolder: undefined,
					} satisfies WaitingForCurrentFolderBrowserStore);
					subscribeToCurrentFolder(resourceIdentifier);
				}
			},
			selectNxObject(item) {
				if (get().status !== 'ready' && get().status !== 'title-objects-tab-ready') return;
				set({ selectedNxObject: item, selectedNxObjectUuid: item.uuid });
				if (objectBrowserStoreSelector.isInSearchMode(get())) {
					objectBrowserBreadcrumbsManagerStore.actions.updateFakeBreadcrumbFromNxObject(item);
				}
				objectBrowserDrawerManagerStore.internalActions.setNxObjectDrawerOpen(true);
				set({ selectedNxObjectFull: undefined });
				setSelectedNxObjectAndSubscribeToFull(item);
			},
			setNxObjectFull(nxObjectFull) {
				set({ selectedNxObjectFull: nxObjectFull });
			},
			closeNxObjectDrawer() {
				objectBrowserDrawerManagerStore.internalActions.setNxObjectDrawerOpen(false);
			},
		},
		internalActions: {
			...paginatorStore.internalActions,
			...objectBrowserDrawerManagerStore.internalActions,
			initialize() {
				if (!objectBrowserStoreSelector.isInSearchMode(get())) subscribeToDrillDownSearch();
				const { selectedNxObjectUuid } = get();
				// If we already have a selectedNxObjectUuid on initialization, it means it come from the deeplink, so we need to fetch the full object
				if (selectedNxObjectUuid) {
					subscribeToSelectedItemFull(selectedNxObjectUuid);
				}
				objectBrowserTreeManagerStore.internalActions.initialize();
				objectBrowserBreadcrumbsManagerStore.internalActions.initialize();
			},
			initializeTitleTabObjectsStore(title: TitleCommon) {
				objectBrowserBreadcrumbsManagerStore.internalActions.initialize();
				const { selectedNxObjectUuid } = get();
				// If we already have a selectedNxObjectUuid on initialization, it means it come from the deeplink, so we need to fetch the full object
				if (selectedNxObjectUuid) {
					subscribeToSelectedItemFull(selectedNxObjectUuid);
				}

				wildCardSearchStore.actions.setSearchMode(SearchMode.EVERYWHERE);

				set({
					status: 'title-objects-tab-ready',
					title,
					isItemsPending: true,
					items: [],
					selectedNxObject: undefined,
					selectedNxObjectFull: undefined,
					selectedNxObjectUuid,
				} satisfies TitleObjectsTabReadyObjectBrowserStore & { selectedNxObjectUuid: string | undefined });

				wildCardSearchStore.actions.subscribeToSearch();
			},
			destroy() {
				unsubscribeFromDrillDownSearch();
				objectBrowserTreeManagerStore.internalActions.destroy();
				objectBrowserBreadcrumbsManagerStore.internalActions.destroy();
			},
		},
	} satisfies ObjectBrowserStore;
};

const [ObjectBrowserStoreProviderInternal, useObjectBrowserStore] =
	definePersistentQueryParamStore<ObjectBrowserStore>(createObjectBrowserStore);

export { useObjectBrowserStore };

function ObjectBrowserStoreEffects() {
	const { initialize, destroy } = useObjectBrowserStore((state) => state.internalActions);

	useEffect(() => {
		initialize();
		return () => destroy();
	}, [initialize, destroy]);

	return null;
}

function TitleObjectsTabObjectBrowserStoreEffects() {
	const { initializeTitleTabObjectsStore, destroy } = useObjectBrowserStore((state) => state.internalActions);
	const title = useTitleEditorStore(titleEditorStoreSelector.title);

	useEffect(() => {
		if (!title) return;

		initializeTitleTabObjectsStore(title);
	}, [initializeTitleTabObjectsStore, title]);

	useEffect(
		() => () => destroy(),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	return null;
}

export function ObjectBrowserStoreProvider({
	children,
	configOverride,
}: {
	configOverride?: PersistQueryParamsConfig<ObjectBrowserStore>;
	children: React.ReactNode;
}) {
	const config = useMemo(
		() => ({
			...tableManagerStoreConfig,
			...paginatorStoreConfig,
			...wildCardSearchStoreConfig,
			...objectBrowserStoreConfig,
			...objectBrowserDrawerManagerStoreConfig,
			...(configOverride || {}),
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	return (
		<ObjectBrowserStoreProviderInternal config={config}>
			<ObjectBrowserStoreEffects />
			{children}
		</ObjectBrowserStoreProviderInternal>
	);
}

export function TitleObjectsTabObjectBrowserStoreProvider({
	children,
	configOverride,
}: {
	configOverride?: PersistQueryParamsConfig<ObjectBrowserStore>;
	children: React.ReactNode;
}) {
	const config = useMemo(
		() =>
			remapSessionStorageKeyPrefix(
				{
					...titlesObjectTabObjectBrowserStoreConfig,
					...paginatorStoreConfig,
					...wildCardSearchStoreConfig,
					...objectBrowserStoreConfig,
					...objectBrowserDrawerManagerStoreConfig,
					...(configOverride || {}),
				},
				'title-objects-tab-object-browser',
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	return (
		<ObjectBrowserStoreProviderInternal config={config}>
			<TitleObjectsTabObjectBrowserStoreEffects />
			{children}
		</ObjectBrowserStoreProviderInternal>
	);
}

function shouldUpdateDrilldownSearchSubscription(state: ObjectBrowserStore, prevState: ObjectBrowserStore) {
	return shouldUpdateSubscriptionCommon(state, prevState) || !isCurrentFolderEqual(state, prevState);
}

function shouldUpdateWildcardSearchSubscription(state: ObjectBrowserStore, prevState: ObjectBrowserStore) {
	return shouldUpdateSubscriptionCommon(state, prevState) || !isColumnVisibilityEqual(state, prevState);
}

function shouldUpdateSubscriptionCommon(state: ObjectBrowserStore, prevState: ObjectBrowserStore) {
	return (
		!isSortEqual(state, prevState) ||
		!isPaginationEqual(state, prevState) ||
		!isFilterEqual(state, prevState) ||
		!isResourceIdentifierEqual(state, prevState)
	);
}

function isCurrentFolderEqual(state: ObjectBrowserStore, prevState: ObjectBrowserStore) {
	const previousCurrentFolder = getOptionalProperty(prevState, 'currentFolder');
	const newCurrentFolder = getOptionalProperty(state, 'currentFolder');
	return isEqual(previousCurrentFolder, newCurrentFolder);
}

function isColumnVisibilityEqual(state: ObjectBrowserStore, prevState: ObjectBrowserStore) {
	return isEqual(state.visibility, prevState.visibility);
}

function isSortEqual(state: TableManagerStore, prevState: TableManagerStore) {
	return isEqual(state.sort, prevState.sort);
}

function isPaginationEqual(state: PaginatorStore, prevState: PaginatorStore) {
	return state.page === prevState.page && state.perPage === prevState.perPage;
}

function isFilterEqual(state: TableManagerStore, prevState: TableManagerStore) {
	return isEqual(state.filter, prevState.filter);
}

function isResourceIdentifierEqual(state: ObjectBrowserStore, prevState: ObjectBrowserStore) {
	const newResourceIdentifier = getOptionalProperty(state, 'resourceIdentifier');
	const previousResourceIdentifier = getOptionalProperty(prevState, 'resourceIdentifier');

	return isEqual(newResourceIdentifier, previousResourceIdentifier);
}
