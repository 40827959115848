import { assertUnreachable } from '@warehouse/shared/util';
import { ColumnName } from '../../feature-object-browser/columns';
import { components } from '../../infra/inventory.openapi';
import {
	LicensorColumnName,
	NameColumnName,
	TitleColumnName,
	TitleIdsColumnName,
} from '../../feature-object-browser/specific-column-names';

export type TableVisibility = {
	[key in ColumnName]?: boolean;
};

export type NxObjectSearchableFields = components['schemas']['NxObjectSearchableFields'];

export class TableVisibilityUtils {
	static toKeysToSearch(tableVisibility: TableVisibility): NxObjectSearchableFields[] {
		return (Object.keys(tableVisibility) as (keyof TableVisibility)[])
			.filter((key) => tableVisibility[key])
			.map((visibleColumn) => {
				switch (visibleColumn) {
					case 'bucket':
					case 'itemType':
					case 'lastModifiedAt':
					case 'storageTier':
					case 'eTag':
					case 'online':
					case 'fileSize':
					case 'key':
					case 'uuid':
					case 'uploadedByOrganization':
					case 'uploadedByUser':
					case 's3Uri':
						return visibleColumn;
					case NameColumnName:
						return 'name';
					case LicensorColumnName:
						return 'assignedTitles.licensor.label';
					case TitleColumnName:
						return 'assignedTitles.titleSearchDisplayNameFull';
					case TitleIdsColumnName:
						return 'assignedTitles.titleUuid';
					default:
						assertUnreachable(visibleColumn, `Invalid column: ${visibleColumn}`);
						return visibleColumn;
				}
			})
			.filter((key) => key !== undefined);
	}
}
