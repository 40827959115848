import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, ConfirmationModal } from '@warehouse/shared/legacy-ui';
import Icon from '@mdi/react';
import { mdiDelete, mdiPlus } from '@mdi/js';
import { assignTitleStoreSelector, useAssignTitleStore } from '@warehouse/object-browser/feature-assign-titles';
import { NxObjectFull } from '@warehouse/object-browser/core';
import { useTheme } from '@mui/material';
import Loader from '../../../../src/components/library/Loader';
import { AssignmentConfirmService } from '../domain/assignment-confirm.service';

interface FooterProps {
	nxObjectFull: NxObjectFull | undefined;
	onTitlesUnassigned: (nxObjectFull: NxObjectFull) => void;
}

export function Footer({ nxObjectFull, onTitlesUnassigned }: FooterProps) {
	const actions = useAssignTitleStore(assignTitleStoreSelector.actions);
	const isSubmitting = useAssignTitleStore(assignTitleStoreSelector.isSubmitting);
	const selectedTitleUuidsToUnassign = useAssignTitleStore(assignTitleStoreSelector.selectedTitleUuidsToUnassign);
	const [confirmAssignTitle, setConfirmAssignTitle] = useState(false);
	const theme = useTheme();

	const onClickAssign = () => {
		if (!nxObjectFull) return;
		if (AssignmentConfirmService.needsConfirmation(nxObjectFull)) {
			setConfirmAssignTitle(true);
			return;
		}
		onAssignTitle();
	};

	const onAssignTitle = () => {
		actions.openPrompt();
	};

	const onClickUnassign = () => {
		if (!nxObjectFull) return;

		actions.unassignSelectedTitles(nxObjectFull).then((res) => {
			if (res) onTitlesUnassigned(res);
		});
	};

	return (
		<Wrapper>
			<Button
				disabled={isSubmitting}
				nxstyle="tertiary-light"
				startIcon={<Icon path={mdiPlus} size="25px" />}
				onClick={onClickAssign}
			>
				Add Row
			</Button>
			<Button
				aria-label="Unassign selected titles"
				nxstyle="secondary-red"
				disabled={isSubmitting || selectedTitleUuidsToUnassign.length === 0}
				onClick={onClickUnassign}
			>
				{isSubmitting ? <Loader color={theme.palette.red.main} size={20} /> : <Icon path={mdiDelete} size="25px" />}
			</Button>
			<ConfirmationModal
				open={confirmAssignTitle}
				onConfirm={() => {
					setConfirmAssignTitle(false);
					onAssignTitle();
				}}
				onClose={() => setConfirmAssignTitle(false)}
				title="Are you sure?"
				message="You are about to override the assignment on all objects contained in this folder."
			/>
		</Wrapper>
	);
}

const Wrapper = styled.div(
	({ theme }) => css`
		background-color: ${theme.palette.light.background};
		border-top: 1px solid ${theme.palette.light.backgroundAlt};
		display: flex;
		justify-content: space-between;
		padding: ${theme.spacing(1)};
	`,
);
