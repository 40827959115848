import { definePersistentQueryParamStore, PersistQueryParamsConfig } from '@warehouse/shared/util';
import { StateCreator } from 'zustand/vanilla';
import { createPaginatorSlice, PaginatorStore, paginatorStoreSelector } from '@warehouse/shared/ui';
import { searchTitlesRepositorySingleton } from '@warehouse/title-shared/infra';
import { Subscription } from 'rxjs';
import { RelationshipType, TitleCommon } from '@warehouse/title/core';
import React, { useEffect, useMemo } from 'react';
import { WorkType, WorkTypesService } from '@warehouse/title-shared/core';
import { CommonTitleFilters } from '@warehouse/title/domain';
import { FilterStep, FilterWithDate } from '@warehouse/shared/filters';
import compact from 'lodash/compact';
import { getWorkTypeUuid } from '@warehouse/title/infra';
import isEqual from 'lodash/isEqual';
import {
	createTitleSelectionManagerSlice,
	titleSelectionManagerSelector,
	TitleSelectionManagerStore,
} from '../ui/title-selection-manager.store';

const SEARCH_FIELDS: string[] = ['licensor.label', 'titleSearchDisplayNameFull', 'releaseDate', 'workType.label'];

export type MultiTitleSelectorStore = PaginatorStore &
	TitleSelectionManagerStore & {
		selectableTitles: TitleCommon[];
		isPending: boolean;
		searchValue: string;
		workTypesToFilterOn: WorkType[] | null;
		relationshipType?: RelationshipType | undefined;
		showHiddenTitles: boolean;
		licensorLabelToFilterOn: string | null;
		actions: PaginatorStore['actions'] &
			TitleSelectionManagerStore['actions'] & {
				setSearchValue(value: string): void;
				setWorkTypesToFilterOn(workTypes: WorkType[] | null): void;
				setRelationshipType(relationshipType: RelationshipType | undefined): void;
				setShowHiddenTitles(showHiddenTitles: boolean): void;
				setLicensorLabelToFilterOn(licensorLabel: string | null): void;
			};
		internalActions: PaginatorStore['internalActions'] & {
			destroy(): void;
			initialize(): void;
		};
	};

export const createMultiTitleSelectorSlice: StateCreator<MultiTitleSelectorStore> = (set, get, store) => {
	const repo = searchTitlesRepositorySingleton.get();
	let subscription: undefined | Subscription;

	const subscribeToSearch = () => {
		unsubscribeFromSearch();
		const state = get();
		if (!state.licensorLabelToFilterOn) return;
		subscription = repo
			.watchSearch({
				page: state.page,
				sorting: [{ id: 'titleSearchSortFull', desc: false }],
				filtering: {
					step: {
						combinationOperator: 'AND',
						filters: compact([
							CommonTitleFilters.searchByLicensorDisplayName(state.licensorLabelToFilterOn),
							!state.showHiddenTitles && CommonTitleFilters.excludeHiddenTitles(),
							state.workTypesToFilterOn != null ? getWorkTypeFilterStep(state.workTypesToFilterOn) : false,
						]),
					},
				},
				perPage: state.perPage,
				searchFields: SEARCH_FIELDS,
				searchValue: state.searchValue,
			})
			.subscribe((result) => {
				if (result.isPending) {
					set({ isPending: true });
					return;
				}

				if (result.error) {
					// TODO: ??
					console.error(result.error);
					return;
				}

				set({
					selectableTitles: result.data.documents,
					isPending: false,
					totalPages: result.data.pagination.totalPage,
					totalCount: result.data.pagination.totalDocument,
				});
			});
	};

	const unsubscribeFromSearch = () => {
		if (subscription) {
			subscription.unsubscribe();
			subscription = undefined;
		}
	};

	const paginatorSlice = createPaginatorSlice(set, get, store);
	const selectionManagerSlice = createTitleSelectionManagerSlice(set, get, store);

	store.subscribe((newState, prevState) => {
		if (
			!prevState ||
			!isPaginationEqual(newState, prevState) ||
			!isSearchValueEqual(newState, prevState) ||
			!isWorkTypesToFilterOnEqual(newState, prevState) ||
			!isShowHiddenTitlesEqual(newState, prevState) ||
			!isLicensorLabelToFilterOnEqual(newState, prevState)
		) {
			subscribeToSearch();
		}
	});

	return {
		selectableTitles: [],
		isPending: false,
		searchValue: '',
		relationshipType: undefined,
		workTypesToFilterOn: null,
		licensorLabelToFilterOn: null,
		showHiddenTitles: false,
		...paginatorSlice,
		...selectionManagerSlice,
		actions: {
			...paginatorSlice.actions,
			...selectionManagerSlice.actions,
			setSearchValue(value: string) {
				set({ searchValue: value });
				paginatorSlice.internalActions.resetPagination();
			},
			setWorkTypesToFilterOn(workType: WorkType[] | null) {
				set({ workTypesToFilterOn: workType });
				paginatorSlice.internalActions.resetPagination();
			},
			setShowHiddenTitles(showHiddenTitles: boolean) {
				set({ showHiddenTitles });
				paginatorSlice.internalActions.resetPagination();
			},
			setRelationshipType(relationshipType: RelationshipType | undefined) {
				set({ relationshipType });
				/**
				 * Manually set the workTypesToFilterOn on all allowed work types of the new relationship type if it's not set
				 */
				if (!get().workTypesToFilterOn)
					set({ workTypesToFilterOn: multiTitleSelectorSelector.allowedWorkTypes(get()) });
			},
			setLicensorLabelToFilterOn(licensorLabel: string | null) {
				set({ licensorLabelToFilterOn: licensorLabel });
				paginatorSlice.internalActions.resetPagination();
			},
		},
		internalActions: {
			...paginatorSlice.internalActions,
			destroy() {
				unsubscribeFromSearch();
			},
			initialize() {
				subscribeToSearch();
			},
		},
	};
};

const [MultiTitleSelectorStoreProviderInternal, useMultiTitleSelectorStore, MultiTitleSelectorStoreContext] =
	definePersistentQueryParamStore<MultiTitleSelectorStore>(createMultiTitleSelectorSlice);

export { useMultiTitleSelectorStore, MultiTitleSelectorStoreContext };

function MultiTitleSelectorStoreEffects() {
	const { initialize, destroy } = useMultiTitleSelectorStore((state) => state.internalActions);

	useEffect(() => {
		initialize();
		return () => destroy();
	}, [initialize, destroy]);

	return null;
}

export function MultiTitleSelectorStoreProvider({ children }: { children: React.ReactNode }) {
	const paginationStoreConfig = useMemo(
		(): PersistQueryParamsConfig<PaginatorStore> => ({
			page: {
				type: 'number',
				defaultValue: 1,
			},
			perPage: {
				type: 'number',
				defaultValue: 10,
			},
		}),
		[],
	);

	return (
		<MultiTitleSelectorStoreProviderInternal config={paginationStoreConfig}>
			<MultiTitleSelectorStoreEffects />
			{children}
		</MultiTitleSelectorStoreProviderInternal>
	);
}

export const multiTitleSelectorSelector = {
	...paginatorStoreSelector,
	...titleSelectionManagerSelector,
	selectableTitles: (state: MultiTitleSelectorStore) => state.selectableTitles,
	isPending: (state: MultiTitleSelectorStore) => state.isPending,
	workTypesToFilterOn: (state: MultiTitleSelectorStore) => state.workTypesToFilterOn,
	licensorLabelToFilterOn: (state: MultiTitleSelectorStore) => state.licensorLabelToFilterOn,
	relationshipType: (state: MultiTitleSelectorStore) => state.relationshipType,
	showHiddenTitles: (state: MultiTitleSelectorStore) => state.showHiddenTitles,
	allowedWorkTypes: (state: MultiTitleSelectorStore) => {
		if (state.relationshipType) {
			return WorkTypesService.getAllowedWorkTypesByRelationshipSource(state.relationshipType);
		}
		return WorkTypesService.getAllWorkTypes();
	},
	actions: (state: MultiTitleSelectorStore) => ({
		...paginatorStoreSelector.actions(state),
		...titleSelectionManagerSelector.actions(state),
		...state.actions,
	}),
};

function isPaginationEqual(state: MultiTitleSelectorStore, prevState: MultiTitleSelectorStore) {
	return state.page === prevState.page && state.perPage === prevState.perPage;
}

function isSearchValueEqual(state: MultiTitleSelectorStore, prevState: MultiTitleSelectorStore) {
	return state.searchValue === prevState.searchValue;
}

function isWorkTypesToFilterOnEqual(state: MultiTitleSelectorStore, prevState: MultiTitleSelectorStore) {
	return isEqual(state.workTypesToFilterOn, prevState.workTypesToFilterOn);
}

function isShowHiddenTitlesEqual(state: MultiTitleSelectorStore, prevState: MultiTitleSelectorStore) {
	return state.showHiddenTitles === prevState.showHiddenTitles;
}

function isLicensorLabelToFilterOnEqual(state: MultiTitleSelectorStore, prevState: MultiTitleSelectorStore) {
	return state.licensorLabelToFilterOn === prevState.licensorLabelToFilterOn;
}

function getWorkTypeFilterStep(workTypes: WorkType[]): FilterWithDate | FilterStep {
	if (workTypes.length > 1) {
		return {
			step: {
				combinationOperator: 'OR',
				filters: workTypes.map((workType) => ({
					value: getWorkTypeUuid(workType),
					field: 'workType.uuid',
					operator: 'equals',
				})),
			},
		};
	}
	return {
		value: getWorkTypeUuid(workTypes[0]),
		field: 'workType.uuid',
		operator: 'equals',
	};
}
