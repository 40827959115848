import { IndexedTitleV2 } from '@warehouse/graphql';
import { TitleSearchDisplayNameFullHTML } from '@warehouse/title/core';
import { getWorkType } from './worktype';
import { TitleLightParentAdapter } from './title-light-parent.adapter';

export class TitleSearchDisplayFullHTMLAdapter {
	static adapt(
		titleSearchDisplayNameFullHtml: IndexedTitleV2['titleSearchDisplayNameFullHTML'],
	): TitleSearchDisplayNameFullHTML {
		return {
			...titleSearchDisplayNameFullHtml,
			editClasses: titleSearchDisplayNameFullHtml.editClasses ?? undefined,
			editUse: titleSearchDisplayNameFullHtml.editUse ?? undefined,
			identification: titleSearchDisplayNameFullHtml.identification ?? undefined,
			madeForRegions: titleSearchDisplayNameFullHtml.madeForRegions ?? undefined,
			madeForRegionsExclude: titleSearchDisplayNameFullHtml.madeForRegionsExclude ?? undefined,
			parentTitleSearchDisplayNameFullForChild:
				titleSearchDisplayNameFullHtml.parentTitleSearchDisplayNameFullForChild ?? undefined,
			parentTitleSearchSortFullForChild: titleSearchDisplayNameFullHtml.parentTitleSearchSortFullForChild ?? undefined,
			sequenceNumber: titleSearchDisplayNameFullHtml.sequenceNumber ?? undefined,
			titleFrontEnd: titleSearchDisplayNameFullHtml.titleFrontEnd ?? undefined,
			titleSortFrontEnd: titleSearchDisplayNameFullHtml.titleSortFrontEnd ?? undefined,
			useTitleSecurity: titleSearchDisplayNameFullHtml.useTitleSecurity ?? undefined,
			versionLanguages: titleSearchDisplayNameFullHtml.versionLanguages ?? undefined,
			workTypeDetails: titleSearchDisplayNameFullHtml.workTypeDetails ?? undefined,
			workType: getWorkType(titleSearchDisplayNameFullHtml.workType.uuid),
			parentAbstractionWorkType: getWorkType(titleSearchDisplayNameFullHtml.parentAbstractionWorkType.uuid),
			parentWorkType: titleSearchDisplayNameFullHtml.parentWorkType
				? getWorkType(titleSearchDisplayNameFullHtml.parentWorkType.uuid)
				: undefined,
			parents: TitleLightParentAdapter.adaptArray(titleSearchDisplayNameFullHtml.parents ?? undefined),
		};
	}
}
