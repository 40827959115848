import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';
import React, { CSSProperties } from 'react';
import { TypographyTypeMap } from '@mui/material/Typography/Typography';

type ReadOnlyTypographyProps = {
	variant?: 'prefilled' | 'default' | undefined;
	style?: CSSProperties;
	title?: string;
} & Omit<TypographyTypeMap['props'], 'variant'>;

export const ReadOnlyTypography = styled((props: ReadOnlyTypographyProps) => (
	<Typography
		{...props}
		variant="buttonMediumRegular"
		color="text.secondary"
		className={`read-only-value ${props.variant === 'prefilled' ? 'inherited' : ''}`}
	/>
))(() => css``);
