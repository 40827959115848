import React from 'react';
import styled from 'styled-components';
import { TitleObjectsTabObjectBrowserStoreProvider } from '@warehouse/object-browser/domain';
import { SelectedItemDrawer } from '@warehouse/object-browser/feature-nx-object-drawer';
import {
	FromTitleProvider,
	ObjectBrowserBreadcrumbs,
	ObjectBrowserTable,
} from '@warehouse/object-browser/feature-object-browser';

interface ObjectsTabTabProps {
	wrapperRef: React.RefObject<HTMLDivElement>;
}

export function ObjectsTab(props: ObjectsTabTabProps) {
	return (
		<TitleObjectsTabObjectBrowserStoreProvider>
			<FromTitleProvider>
				<ObjectsTabInner {...props} />
			</FromTitleProvider>
		</TitleObjectsTabObjectBrowserStoreProvider>
	);
}

function ObjectsTabInner({ wrapperRef }: ObjectsTabTabProps) {
	return (
		<>
			<TableWrapper>
				<ObjectBrowserBreadcrumbs />
				<ObjectBrowserTable />
			</TableWrapper>
			<SelectedItemDrawer wrapperRef={wrapperRef} />
		</>
	);
}

const TableWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;
