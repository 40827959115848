import { supplementalUuid, titleGQLResponseToTitleFull } from '@warehouse/title/infra';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { TitleFull } from '@warehouse/title/core';
import { Title } from '@nexspec/warehouse-shared-types';
import { debounce } from '@mui/material';
import { CreateTitleV2Metadata } from '@warehouse/graphql';
import useCreateTitleV2 from '../../utils/hooks/titles/useCreateTitleV2';

interface UseDryRunProps {
	payload: CreateTitleV2Metadata | undefined;
	skip?: boolean;
}

interface UseDryRunOutput {
	dryRanTitle?: TitleFull;
	isLoading: boolean;
	// A state boolean indicating whether the dryRanTitle is up to date with the latest payload passed in props
	isDirty: boolean;
	errors: Array<any>;
	setErrors: (e: Array<any>) => void;
}

function emptyOrUndefinedArray(value: Array<any> | undefined | null) {
	return value === undefined || value === null || !Array.isArray(value) || value.length === 0;
}

function canRunDryRun(payload?: CreateTitleV2Metadata): boolean {
	// We cannot run the dry run without a payload
	if (!payload) return false;
	// We cannot run the dry run if we don't have a work type set
	if (!payload?.coreMetadata.basic.workType) return false;
	// If we are working on something different from a supplemental
	if (payload.coreMetadata.basic.workType !== supplementalUuid()) {
		// Either the localized info or one parents must be set
		if (
			emptyOrUndefinedArray(payload.coreMetadata.basic.localizedInfos) &&
			emptyOrUndefinedArray(payload.coreMetadata.basic.parents)
		)
			return false;
	}
	// If we are working on a supplemental
	if (payload.coreMetadata.basic.workType === supplementalUuid()) {
		// We need at least one localized info
		if (emptyOrUndefinedArray(payload.coreMetadata.basic.localizedInfos)) return false;
	}
	return true;
}

function useDryRun({ payload, skip = false }: UseDryRunProps): UseDryRunOutput {
	const skipRef = useRef<boolean>(skip);
	skipRef.current = skip;
	const controllerRef = useRef(new AbortController());
	const [errors, setErrors] = useState<any>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isDirty, setIsDirty] = useState<boolean>(false);
	const [createTitleV2] = useCreateTitleV2();

	const [dryRanTitle, setDryRanTitle] = useState<TitleFull>();

	async function runDryRun(_payload: CreateTitleV2Metadata) {
		if (skipRef.current) {
			setIsDirty(false);
			return;
		}
		setIsLoading(true);

		if (!canRunDryRun(_payload)) {
			setIsDirty(false);
			return;
		}

		try {
			const inheritedValuesTmp = await createTitleV2({
				variables: {
					input: {
						dryRun: true,
						metadata: addFakeReleaseYearToByPassValidationOnDryRun(_payload),
					},
				},
				context: {
					fetchOptions: {
						signal: controllerRef.current.signal,
					},
					dryRun: true,
				},
			});
			const title = JSON.parse(inheritedValuesTmp?.data?.createTitleV2?.state || '') as unknown as Title;
			const newDryRanTitle = titleGQLResponseToTitleFull({
				indexed: inheritedValuesTmp?.data?.createTitleV2?.indexed!,
				ultraMezzUuid: inheritedValuesTmp?.data?.createTitleV2?.ultraMezzUuid,
				state: title,
				uuid: title.uuid,
				soft: true,
			});

			setDryRanTitle(newDryRanTitle);
			setErrors([]);
			setIsDirty(false);
		} catch (e: any) {
			// If the error does not come from a signal abort, we reset the dirty state
			if (!(e instanceof ApolloError && e.cause?.name === 'AbortError')) {
				setIsDirty(false);
			}
			setErrors(
				e?.graphQLErrors?.filter(
					(gqlError: any) => !gqlError.message.startsWith("Variable 'input' has coerced Null value"),
				),
			);
			// We want to log the error in the console to avoid silent failures
			// eslint-disable-next-line no-console
			console.error(e);
		} finally {
			setIsLoading(false);
		}
	}

	const debouncedRunDryRun = useMemo(() => debounce(runDryRun, 1000), []);

	useEffect(() => {
		if (!payload || !canRunDryRun(payload)) return;
		setIsDirty(true);
		controllerRef.current.abort();
		controllerRef.current = new AbortController();
		debouncedRunDryRun(payload);
	}, [payload, skipRef.current, debouncedRunDryRun]);

	return {
		dryRanTitle,
		isLoading,
		errors,
		isDirty,
		setErrors,
	};
}

/**
 * After a sync with the BE team, we realized that we need to send a fake releaseYear to the dry run to avoid validation errors.
 * The releaseYear is used by the BE to build the IndexedTitleV2. If the releaseYear is not set, the IndexedTitleV2 will not be built.
 */
function addFakeReleaseYearToByPassValidationOnDryRun(payload: CreateTitleV2Metadata) {
	const clonedPayload = structuredClone(payload);
	if (!clonedPayload.coreMetadata.basic.releaseYear) {
		clonedPayload.coreMetadata.basic.releaseYear = 2000;
	}
	return clonedPayload;
}

export default useDryRun;
