import { Filters } from '@warehouse/object-browser/core';
import { components } from './inventory.openapi';

export class WildCardSearchAdapter {
	static adapt(
		wildCardSearch: Filters['textSearch'],
	): { query: string; fields: components['schemas']['NxObjectSearchableFields'][] } | undefined {
		if (!wildCardSearch) return undefined;
		if (!wildCardSearch.value || !wildCardSearch.keysToSearch) return undefined;
		if (wildCardSearch.keysToSearch.length === 0) return undefined;
		return {
			query: wildCardSearch.value,
			fields: wildCardSearch.keysToSearch,
		};
	}
}
