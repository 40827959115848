import { assertUnreachable } from '@warehouse/shared/util';
import { FilterOperator, filterOutInvalidFilters, NxObjectFilterQuery } from '@warehouse/shared/filters';
import {
	getGridSingleSelectOperators,
	GridColumnVisibilityModel,
	GridFilterInputMultipleSingleSelect,
	GridFilterOperator,
	GridPinnedColumnFields,
} from '@mui/x-data-grid-pro';
import {
	DateFilterOperator,
	GridFilterModel,
	NumericFilterOperator,
	StringFilterOperator,
	TablePinnedColumns,
	TableVisibility,
} from '@warehouse/object-browser/core';
import { CombinationOperator } from '../../../title/domain/SearchFilters';

export function muixGridVisibilityModelToTableVisibility(model: GridColumnVisibilityModel): TableVisibility {
	return model as TableVisibility; // Safe conversion as any true key has been removed
}

export function gridFilterModelToFilterQuery(model: GridFilterModel): NxObjectFilterQuery {
	return {
		combinationOperator: model.logicOperator ? (model.logicOperator.toUpperCase() as CombinationOperator) : 'AND',
		filters: filterOutInvalidFilters(
			model.items.map((item) => ({
				field: item.field,
				operator: supportedOperatorToFilterOperator(item.operator),
				value: item.value,
			})),
		),
	};
}

export function supportedOperatorToFilterOperator(operator: string): FilterOperator {
	if (isOperatorSupportedNumeric(operator)) return numericFilterOperatorToFilterOperator(operator);
	if (isOperatorSupportedString(operator)) return stringFilterOperatorToFilterOperator(operator);
	if (isOperatorSupportedDate(operator)) return dateFilterOperatorToFilterOperator(operator);
	console.error(`Unsupported operator: ${operator}`);
	return 'equals';
}

export function isOperatorSupportedNumeric(operator: string): operator is NumericFilterOperator {
	return (
		['=', '!=', '>', '>=', '<', '<=', 'isEmpty', 'isAnyOf', 'isNoneOf', 'isNotEmpty'] satisfies NumericFilterOperator[]
	).includes(operator as NumericFilterOperator);
}

export function isOperatorSupportedString(operator: string): operator is StringFilterOperator {
	return (
		[
			'contains',
			'doesNotContain',
			'equals',
			'doesNotEqual',
			'startsWith',
			'endsWith',
			'isEmpty',
			'isNotEmpty',
			'isAnyOf',
			'isNoneOf',
		] satisfies StringFilterOperator[]
	).includes(operator as StringFilterOperator);
}

export function isOperatorSupportedDate(operator: string): operator is DateFilterOperator {
	return (
		['is', 'not', 'after', 'onOrAfter', 'before', 'onOrBefore', 'isEmpty', 'isNotEmpty'] satisfies DateFilterOperator[]
	).includes(operator as DateFilterOperator);
}

export function numericFilterOperatorToFilterOperator(filterOperator: NumericFilterOperator): FilterOperator {
	switch (filterOperator) {
		case '=':
			return 'equals';
		case '!=':
			return 'doesNotEqual';
		case '>':
			return 'isMore';
		case '>=':
			return 'isEqualOrMore';
		case '<':
			return 'isLess';
		case '<=':
			return 'isEqualOrLess';
		case 'isEmpty':
			return 'isEmpty';
		case 'isAnyOf':
			return 'isAnyOf';
		case 'isNoneOf':
			return 'isNoneOf';
		case 'isNotEmpty':
			return 'isDefined';
		default:
			assertUnreachable(filterOperator);
			return 'equals';
	}
}

export function stringFilterOperatorToFilterOperator(filterOperator: StringFilterOperator): FilterOperator {
	switch (filterOperator) {
		case 'contains':
			return 'contains';
		case 'doesNotContain':
			return 'doNotContain';
		case 'equals':
			return 'equals';
		case 'doesNotEqual':
			return 'doesNotEqual';
		case 'startsWith':
			return 'startsWith';
		case 'endsWith':
			return 'endsWith';
		case 'isEmpty':
			return 'isEmpty';
		case 'isAnyOf':
			return 'isAnyOf';
		case 'isNoneOf':
			return 'isNoneOf';
		case 'isNotEmpty':
			return 'isDefined';
		default:
			assertUnreachable(filterOperator);
			return 'equals';
	}
}

export function dateFilterOperatorToFilterOperator(filterOperator: DateFilterOperator): FilterOperator {
	switch (filterOperator) {
		case 'is':
			return 'equals';
		case 'not':
			return 'doesNotEqual';
		case 'after':
			return 'isAfter';
		case 'onOrAfter':
			return 'isOnOrAfter';
		case 'before':
			return 'isBefore';
		case 'onOrBefore':
			return 'isOnOrBefore';
		case 'isEmpty':
			return 'isEmpty';
		case 'isNotEmpty':
			return 'isDefined';
		default:
			assertUnreachable(filterOperator);
			return 'equals';
	}
}

export function muixPinnedToTablePinned(pinned: GridPinnedColumnFields): TablePinnedColumns {
	return {
		left: pinned.left,
	};
}

export function getCustomGridSingleSelectOperators(): GridFilterOperator[] {
	return [
		...getGridSingleSelectOperators(),
		{
			value: 'isNoneOf',
			label: 'is none of',
			getApplyFilterFn: (filterItem) => {
				if (filterItem.value == null || filterItem.value === '') {
					return null;
				}
				return ({ value }) =>
					// if none of the cell values corresponds to the filter item
					!value.some((cellValue: any) => cellValue === filterItem.value);
			},
			InputComponent: GridFilterInputMultipleSingleSelect,
		},
	];
}
